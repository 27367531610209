import { axios, apiUrl } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export async function tagsReferenceList({ page = 1, pageSize = PAGE_SIZE, sort = '', filter = {} }) {
  try {
    const response = await axios.get(`${apiUrl}/organization-tags`, {
        params: {
            page,
            'page-size': pageSize,
            sort,
            filter
        }
    });

    return response.data.data;
  } catch (error) {
    generateErrors(error);
  }
}

export async function directoryTagList(year, page = 1, pageSize = PAGE_SIZE) {
  try {
    const response = await axios.get(`${apiUrl}/organization-tags/${year}`, {
        params: {
            page,
            'page-size': pageSize
        }
    });

    return response.data.data.attributes.tags
  } catch (error) {
    generateErrors(error);
  }
}

export const getAllTags = async (page = 1, pageSize = PAGE_SIZE) => {
    try {
        const { data } = await axios.get(`${ apiUrl }/organization-tags`, {
            params: {
                'page-size': pageSize,
                page
            }
        });

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export async function patchDirectoryTagList(year, data) {
  try {
    const response = await axios.patch(`${apiUrl}/organization-tags/${year}`, data);

    return response

  } catch (error) {
    throw new Error(error.response.data.errors[0].meta.trace[0].message);

  }
}

export async function saveOrganizationTags(organizationId, data) {
    try {
        const response = await axios.post(`${ apiUrl }/organization/${ organizationId }/relationships/tags`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
}

export async function unbindOrganizationTags(orgId, tagsArray) {
  try {
    const { data } = await axios.delete(`${apiUrl}/organization/${orgId}/relationships/tags`, { data: tagsArray })
    return data

  } catch (error) {
      generateErrors(error);
  }
}

export async function getOrgTagsByOrgId(orgId) {
  try {
    const { data } = await axios.get(`${apiUrl}/organization/${orgId}/relationships/tags`, {
        page: 1,
        'page-size': PAGE_SIZE
    });

    return data;
  } catch (error) {
      generateErrors(error);
  }
}
