import { nanoid } from 'nanoid';

export default function(data = {}, group = null) {
    this.id = data ? data.id : null;
    this.name = data ? data.name : '';
    this.shortName = data ? data.shortName : '';
    this.code = data ? data.code : nanoid();
    this.sort = data ? data.sort : 0;
    this.groupId = group;
}
