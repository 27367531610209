<template>
    <div class='p-d-flex'>
        <Button
            v-for='(variant, idx) in delimiterVariants'
            :key='idx'
            :label='variant'
            class='p-button p-mr-1 delimiter-variant'
            :class='{"p-button-outlined": variant !== value, "multi": variant === "*"}'
            @click='delimiterSelect(variant)'
        />
    </div>
</template>

<script>
export default {
    name: 'delimiterSelect',
    props: {
        delimiterVariants: {
            type: Array,
            default: () => ['+', '-', '*', '/', ',', ';']
        },
        value: {
            type: String,
            default: '+'
        }
    },
    methods: {
        delimiterSelect(variant) {
            this.$emit('input', variant)
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .delimiter-variant {
        font-weight: 600;
        &.multi {
            .p-button-label {
                transform: translateY(4px);
            }
        }
    }
}
</style>
