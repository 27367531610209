<template>
    <div class='p-d-flex p-flex-column p-jc-start minusMargin' style='min-height: 83.3vh; max-height: 83.3vh'>
        <div class='p-d-flex p-jc-between minusMargin2' style='min-height: 100%'>
            <div
                :class="[showRightMenu ? 'p-col-custom' : 'p-col-12']"
                class='p-d-flex p-col-customPad p-flex-column p-px-0 p-py-0'
            >
                <div class="p-d-flex p-jc-between">
                    <h4 class="p-mt-0 constTitle elementMargin">
                        Конструктор <span class="form-label">{{ formTitle }}</span>
                        <span class="form-label">Лист {{ currentWorksheetTitle }}</span>
                        <span class="form-label">({{ templateTitle }})</span>
                    </h4>
                    <p class='p-pt-0 p-d-flex p-ai-center' :class='{ hideElem: !showTable }'></p>
                </div>
                <Menubar
                    class='constructor-bar elementMargin'
                    :model='topBarMenuItemsComputed'
                >
                    <template #start>
                        <Button
                            type='button'
                            class='saveButtonStyles p-mr-2'
                            @click='saveTemplate'
                            :disabled='!showTable || blockSave'
                        >
                            <img alt='logo' src='@/assets/img/customIcons/saveButtonIcon.svg'
                                 style='width: 14px; height: 14px' />
                        </Button>
                    </template>
                    <template #end>
                        <div class='p-d-flex'>
                            <template v-if="showDelimiterVariants">
                                <delimiter-select v-model="insertCellDelimiter" />
                            </template>
                            <SelectButton
                                v-model='selectedMode'
                                :options='allModes'
                                optionLabel='brand'
                                class='right-menu-activators'
                            >
                                <template #option='slotProps'>
                                    <div class='menu-button'>
                                        <i :class='`icon ${slotProps.option.icon}`'></i>
                                        <span class='p-button-label'>
                                        {{ slotProps.option.label }}
                                    </span>
                                    </div>
                                </template>
                            </SelectButton>
                        </div>
                    </template>
                </Menubar>
                <div
                    class='customHeight p-py-0 layerContainer elementMargin'
                    v-show='showTable'
                >
                    <div class="p-col-12 p-px-0 p-py-0 customLayerHover">
                        <div class="p-inputgroup" v-if="!!currentLayer">
                            <div v-click-outside="onClickOutside" class="p-inputgroup-addon">
                                <div
                                    class="p-d-flex p-align-center p-mx-1"
                                    @click.stop="openLayers = !openLayers"
                                >
                                    <img
                                        :src='require(`@/assets/img/layersIcons/icon-${ getLayerByType(currentLayer).layerType }.svg`)'
                                        :style="getLayerByType(currentLayer).layerType === 'hint' ? 'width: 16px; height: 16px' : 'width: 21px; height: 21px'"
                                        :alt='getLayerByType(currentLayer).layerType'
                                    />
                                    <span
                                        class='pi pi-chevron-down p-ml-1'
                                        :class='{"pi-chevron-down": !openLayers, "pi-chevron-up": openLayers}'
                                    ></span>
                                </div>
                                <div class="layer-selector" v-if="openLayers" ref="layerSelector">
                                    <div
                                        v-for='(layer) in filteredLayers'
                                        :key='layer.id'
                                        class='layer-selector-item'
                                    >
                                        <img
                                            :src='require(`@/assets/img/layersIcons/icon-${getLayerByType(layer).layerType}.svg`)'
                                            :style="getLayerByType(layer).layerType === 'hint' ? 'width: 16px; height: 16px' : 'width: 21px; height: 21px'"
                                            :alt='getLayerByType(layer).layerType'
                                            @click='onLayerSelectorHandler(layer, true, true)'
                                        />
                                        <div class='layer-selector-item-label' @click='onLayerSelectorHandler(layer, true, true)'>{{ getLayerByType(layer).layerTitle }}
                                        </div>
                                        <div
                                            v-if='layer.layerType !== "text"'
                                            class='layer-selector-item-actions pi pi-trash'
                                            @click='showDeleteLayerPopup(layer.id)'
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                contenteditable='true'
                                v-if="['check', 'calc'].includes(currentLayer.layerType)"
                                ref='contentEditableDiv'
                                :class="{ buildFormula: (formulaBuilding
                                    && ['check', 'calc'].includes(currentLayer.layerType) && currentLayerId === currentLayer.id) }"
                                class='p-inputdiv p-col displayBlock formulaEditor'
                                @keydown='formulaKeydownHandler($event, currentLayer)'
                                @keyup='formulaKeyupHandler'
                                @keydown.enter='endFormulaWriting'
                                @blur='onBlurHandler'
                                @click='lightOnHoverCell'
                                @focus="startFormulaWriting"
                                @paste="clearInsertData"
                                :disabled='formulaBuilding && currentLayerId !== currentLayer.id'
                                @contextmenu.prevent='formulaLayerContextmenuHandler'
                                v-html='getTrimHtml(currentLayer.layersDescr)'
                                v-click-outside='lightOnHoverCell'
                            ></div>
                            <InputText
                                v-else
                                :placeholder="layerPlaceholder(currentLayer.layerType)"
                                v-model="currentLayer.layersDescr"
                                :ref='`layer${0}`'
                                :class="{ buildFormula: (formulaBuilding
                                    && ['check', 'calc', 'aggregate'].includes(currentLayer.layerType)
                                    && currentLayerId === currentLayer.id) }"
                                @change="endEditLayer(0); updateData();"
                                :disabled="(formulaBuilding  && currentLayerId !== currentLayer.id)
                                    || ['aggregate', 'type', 'hint'].includes(currentLayer.layerType)"
                            />
                            <span class="p-inputgroup-addon showLayerButton"
                                  v-show="formulaBuilding"
                                  @click="cancelChanges"
                            >
                                <i class='pi pi-times-circle customFontSize'></i>
                            </span>
                            <span
                                class='p-inputgroup-addon showLayerButton'
                                v-show='formulaBuilding'
                                @click='validateLayer'
                                style="padding-right: 0;"
                            >
                                <i class='pi pi-check-circle customFontSize' style="color: #66BB6A;"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card elementMargin">
                    <div :class="{ hideElem: !showTable }" v-click-outside="onClickTableOutside">
                        <hot-table :settings="hotSettings" :language="language" ref="myTable" />
                    </div>
                    <div class="card p-pt-3 p-pl-3" :class="{ hideElem: showTable }">
                        <p>Загрузка...</p>
                    </div>
                </div>
            </div>
            <keep-alive>
                <rightMenu
                    v-if='selectedMode'
                    @hideRightMenu='hideRightMenu'
                    @changeComponent='changeComponent'
                    :selectedMode='selectedMode'
                    :selectedForm='selectedForm'
                    :groups='groups'
                    :worksheetHistory='worksheetHistory'
                    :currentLayerType='currentLayerType'
                    :templateYear='templateYear'
                    :formDistributioninterval='formDistributionInterval'
                    :currentWorcksheetId='currentWorcksheetId'
                    :canInsert='formulaBuilding'
                    :currentVersion="currentWorksheetVersion"
                    @createWorksheet='createWorksheet'
                    @editWorksheet='editWorksheet'
                    @dragWorksheet='dragWorksheet'
                    @deleteWorkSheet='deleteCurWorkSheet'
                    @listSelect='listSelect'
                    @formulaVarintSelect='formulaVarintSelect'
                    @unlock-cursor='unlockHandler'
                    @insert-cells-from-form='insertCellsFromForm'
                    @insert-get-by-tag='insertGetByTag'
                    @restore="restore"
                    @validateLayer="validateLayer"
                    :class="[showRightMenu ? 'menuShow' : '']"
                />
            </keep-alive>
        </div>

        <Dialog
            :visible.sync="showLayerDialog"
            position="center"
            :contentStyle="{ overflow: 'visible' }"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            @update:visible="closeLayerDialog"
            style='width: 500px'
        >
            <template #header>
                <h3 class='p-mb-0'>{{ titleLayerDialog }}</h3>
            </template>
            <!-- <hr class="p-mt-0" /> -->
            <div class='p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0 p-py-0'>
                <label class='p-text-normal'>Тип слоя</label>
                <Dropdown
                    v-model="selectedLayerType"
                    :options="allAvialableLayerTypes"
                    optionLabel="layerTitle"
                    placeholder="Выберите тип слоя"
                    :showClear="true"
                    class='p-col-12 p-py-0 p-px-0'
                    disabled="disabled"
                >
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{ slotProps.option.layerTitle }}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class='p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0 p-py-0'
                 v-if="!['aggregate', 'type'].includes(curentSelectedLayerType)">
                <label class='p-text-normal'>Текст:</label>
                <div contenteditable='true' class='p-inputdiv descrArea' ref='descrArea'
                     v-html='layerDescription'></div>
            </div>
            <div class='p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0 p-py-0'
                 v-if="curentSelectedLayerType === 'aggregate'">
                <label class='p-text-normal'>Тип вычисления</label>
                <Dropdown
                    v-model="layerDescription"
                    :options="aggregateTypes"
                    optionLabel="label"
                    placeholder="Выберите тип вычисления"
                    :showClear="true"
                    class="p-col-12 p-py-0 p-px-0"
                >
                    <template #option='slotProps'>
                        <div class='country-item'>
                            <div>{{ slotProps.option.label }}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
            <div class='p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0 p-py-0' v-if="curentSelectedLayerType === 'type'">
                <label class='p-text-normal'>Тип маски</label>
                <Dropdown
                    v-model="layerDescription"
                    :options="valueTypes"
                    optionLabel="label"
                    placeholder="Выберите тип маски"
                    :showClear="true"
                    class="p-col-12 p-py-0 p-px-0"
                >
                    <template #option='slotProps'>
                        <div class='country-item'>
                            <div>{{ slotProps.option.label }}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
            <template #footer>
                <div class='p-d-flex p-jc-start'>
                    <Button type='button' @click='layerChange' :disabled='disabledConditions'><span
                        class='p-button-label'>Сохранить </span> <span class='p-ink'></span></Button>
                    <Button class='p-button p-button-outlined' @click="closeLayerDialog">
                        <span class='p-button-label black'>Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
        <Dialog
            :visible.sync='layersCopyOrDeleteShow'
            position='center'
            :modal='true'
            :closeOnEscape='true'
            :closable='true'
            class='layersDialog'
        >
            <template #header>
                <h3 v-if="layersCopy === 'copy' || layersCopy === 'pas'" class='p-mb-0'>Выберите слои для копирования</h3>
                <h3 v-if="layersCopy === 'delete'" class='p-mb-0'>Выберите слои для удаления</h3>
                <h3 v-if="layersCopy === 'cut'" class='p-mb-0'>Выберите слои, которые хотите вырезать</h3>
            </template>
            <!-- <hr class="p-mt-0" /> -->
            <div class='p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0'>
                <DataTable
                    :value='layers'
                    :selection.sync='selectedLayers'
                    dataKey='id'
                >
                    <template #header>
                        <div class="table-header">Выбрать все слои</div>
                    </template>
                    <Column selectionMode="multiple"
                            headerStyle="width: 3rem;  padding: 0.5rem 1rem"
                            bodyStyle="padding: 0.5rem 1rem"
                    />
                    <Column field='code' headerStyle='width: 3rem;  padding: 0.5rem 1rem'
                            bodyStyle='padding: 0.5rem 1rem'>
                        <template #body='slotProps'>
                            <div class='imgFrame'>
                                <img :src='require(`@/assets/img/layersIcons/icon-${slotProps.data.layerType}.svg`)'
                                     style='width: 21px; height: 21px' />
                            </div>
                        </template>
                    </Column>
                    <Column field='name' header='' headerStyle='max-width: 100%;  padding: 0.5rem 1rem'
                            bodyStyle='padding: 0.5rem 1rem'>
                        <template #body='slotProps'>
                            <div contenteditable='false' class='p-inputdiv descrAreaTable'
                                 v-html='slotProps.data.layersDescr'></div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <!-- <hr /> -->
            <template #footer>
                <div class='p-d-flex p-jc-start'>
                    <Button type='button' :disabled='!selectedLayers.length' @click='agregateLayers(layersCopy)'>
                        <span v-if="layersCopy === 'copy' || layersCopy === 'pas'" class='p-button-label'>Копировать</span> <span
                        class='p-ink'></span>
                        <span v-if="layersCopy === 'delete'" class='p-button-label'>Удалить</span> <span
                        class='p-ink'></span>
                        <span v-if="layersCopy === 'cut'" class='p-button-label'>Вырезать</span> <span
                        class='p-ink'></span>
                    </Button>
                    <Button class='p-button p-button-outlined' @click='closeLayersCopyOrDeletePoup'>
                        <span class='p-button-label black'>Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>

        <Dialog :visible.sync='deleteCurentLayerShow' position='center' :modal='true' :closeOnEscape='true'
                :closable='true' style='width: 500px'>
            <template #header>
                <h3 class='p-mb-0'>Удалить слой</h3>
            </template>
            <div class='p-dialog-content p-ai-center p-d-flex p-px-0'>
                <span
                    class='message'>Подтвердите, пожалуйста, что вы удаляете слой. Восстановить его не получится. </span>
            </div>

            <template #footer>
                <div class='p-d-flex p-jc-start'>
                    <Button class='p-button p-button-danger' @click='deleteLayer'>
                        <span class='p-button-label'>Удалить</span>
                    </Button>
                    <Button class='p-button p-button-outlined p-button-secondary' type='button'
                            @click='closeDeleteLayer'>
                        <span class='p-button-label'>Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
        <columnRowBehavior
            :showBehaviorDialog="showBehaviorDialog"
            :allTemplatePeriods="allTemplatePeriods"
            :selectedPeriod="selectedPeriod"
            :editableByVerifier="editableByVerifier"
            :column="currentColl"
            :row="currentRow"
            :visibilityLimitedRules="visibilityLimitedRules"
            @behaviorChange="behaviorChange"
            @closeBehaviorDialog="closeBehaviorDialog"
        />
        <Dialog :visible.sync='leaveDialog' header='' position='center' :modal='true' :closeOnEscape='true'
                :closable='false' class='escapeDialog'>
            <div class='p-dialog-content p-ai-center p-d-flex'>
                <i class='pi pi-exclamation-triangle p-mr-3' style='font-size: 2rem'></i>
                <span>Все несохраненные данные будут утеряны. Вы уверены, что хотите продолжить?</span>
            </div>

            <template #footer>
                <Button class='p-button p-button-text p-button-danger' v-if='listData'
                        @click='listSelect(listData, true)'>
                    <span class='pi pi-times p-button-icon p-button-icon-left'></span>
                    <span class='p-button-label'>Удалить данные и покинуть</span>
                </Button>
                <Button class='p-button p-button-text p-button-danger' v-else-if='newListData'
                        @click='createWorksheet(newListData, true)'>
                    <span class='pi pi-times p-button-icon p-button-icon-left'></span>
                    <span class='p-button-label'>Удалить данные и покинуть</span>
                </Button>
                <Button class='p-button p-button-text p-button-danger' v-else @click='clearAndLeave'>
                    <span class='pi pi-times p-button-icon p-button-icon-left'></span>
                    <span class='p-button-label'>Удалить данные и покинуть</span>
                </Button>
                <Button class='p-button p-component p-button-text' type='button' autofocus
                        @click='(leaveDialog = false), (listData = null), (newListData = null)'>
                    <span class='pi pi-check p-button-icon-left'></span>
                    <span class='p-button-label'>Вернуться к заполнению</span>
                    <span class='p-ink'></span>
                </Button>
            </template>
        </Dialog>
        <Dialog :visible.sync='uploadFileDialog' header='' position='center' :modal='true' :closeOnEscape='true'
                :closable='true'>
            <template #header>
                <h3 class='p-mb-0'>Выберите файл для загрузки</h3>
            </template>
            <FileUpload class='p-mb-4' name='demo[]' :customUpload='true' :auto='true' @uploader='onUpload'
                        :maxFileSize='1000000' chooseLabel='Выберите файл' cancelLabel='Очистить' accept='*.xlsx'>
                <template #empty>
                    <p>Перетащите сюда файл для загрузки</p>
                </template>
            </FileUpload>

            <template #footer>
                <div class='p-d-flex p-jc-start'>
                    <Button class='p-button' @click='uploadXLSWorksheet' :disabled='!uploadFile'>
                        <span class='p-button-label'>Загрузить</span>
                    </Button>
                    <Button class='p-button p-button-outlined p-button-secondary' type='button' @click='clearUpload'>
                        <span class='p-button-label'>Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
        <Dialog
            class="customDialogSettings"
            :visible.sync="validationErrorShow"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
        >
            <template #header>
                <h3 class="p-mb-0">{{ validationTitle }}</h3>
            </template>
            <div class="p-dialog-content p-d-flex p-px-0 layerContainer">
                <div class="p-inputgroup" v-html="validationError" style="flex-wrap: wrap; line-height: 18px; margin-bottom: 24px;"></div>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-button-outlined" @click="validationErrorShow = false">
                        <span class="p-button-label">Продолжить</span>
                    </Button>
                    <Button class="p-button p-button-danger p-button-secondary" type="button" @click="cancelChanges">
                        <span class="p-button-label">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import rightMenu from '@/components/constructor/rightMenu.vue';
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import mockDataTableDataArray from '@/utils/mockDataTableDataArray';
import {
    haveDifference,
    deepArrayCopy,
    isRightMouseClick,
    getSelectionRange,
    getFirstLevelNodeIdx,
    getStartCursorPositionIntoParent
} from '@/utils/constructor';
import {
    getTemplateData,
    createWorkSheet,
    editWorksheet,
    deleteWorkSheet,
    getWorkSheetCells,
    getWorkSheetVersions,
    getVisibilityRules,
    saveWorkSheetCells,
    downloadConstructorTemplateXLS,
    uploadConstructorXLS,
    validateCellLayer,
    downloadCheckLayers
} from '@/api/form/formsTemplates';
import { getAllIntervalPeriods } from '@/api/form/formsPeriods';

import columnRowBehavior from '@/components/constructor/columnRowBehavior.vue';
import delimiterSelect from '@/components/constructor/delimiterSelect';
import { requestToastHandler } from '@/main/mixins';
import { v4 as uuidv4 } from 'uuid';
import { nanoid } from 'nanoid';
import * as XLSX from 'xlsx/xlsx';
import {createCellLayout, convertLayer, getRowOrColValue} from '@/utils/common';
import { mapGetters } from 'vuex';
import { VALUE_TYPES, AGGREGATE_TYPES } from '@/constants/forms';
import { SINGLE_LAYERS } from '@/constants/common';
import { COLOR_PALETTE } from '@/constants/forms';

export default {
    name: 'templateConstructor',

    mixins: [requestToastHandler],
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    components: {
        rightMenu,
        HotTable,
        columnRowBehavior,
        delimiterSelect,
    },
    data() {
        this.cellArray = [];
        this.cellIncluded = { forms: [], formTemplates: [], formTemplateWorksheets: [], formTemplateCells: [] };
        this.valueTypes  = VALUE_TYPES;
        this.aggregateTypes = AGGREGATE_TYPES;
        this.colorPalette = COLOR_PALETTE;

        return {
            showDelimiterVariants: false,
            currentDelimiter: '+',
            unlockCursor: false,
            insertCellDelimiter: '+',
            currentLayer: null,
            previousValue: null,
            currentRange: null,
            moreThenOneSelectedCell: false,
            cellForAutoFillingCoordinates: null,
            acceptedCopy: false,
            templateTitle: null,
            formTitle: null,
            selectedForm: null,
            templateYear: null,
            formDistributionInterval: null,
            currentWorksheetTitle: '',
            currentWorcksheetId: null,
            coordinates: {
                columnLabels: [],
                rowLabels: []
            },
            currentWorksheetVersion: null,
            blockSave: false,
            showTable: false,
            openLayers: false,
            canDeleteLayer: true,
            dataFromTable: [],
            transformData: [],
            otherFormCells: [],
            startDataFromTable: null,
            tableHaveChange: false,
            leaveDialog: false,
            listData: null,
            newListData: null,
            leaveRoute: null,
            uploadFileDialog: false,
            validationErrorShow: false,
            validationTitle: 'Ошибка валидации формулы',
            validationError: null,
            uploadFile: null,
            currentColl: 0,
            currentRow: 0,
            textRow: null,
            textColumn: null,
            endRowCurrentDiapazon: 0,
            endCollCurrentDiapazon: 0,
            selectedMode: null,
            showLayerDialog: false,
            layersCopyOrDeleteShow: false,
            layersCopy: null,
            selectedLayers: [],
            reserveTextLayers: [],
            currentLayerId: null,
            currentLayerType: null,
            selectedLayerType: null,
            layerDescription: null,
            deleteCurentLayerShow: false,
            titleLayerDialog: '',
            editLayerStart: false,
            copyLayersArray: [],
            mergeCellsArray: [],
            allLayerTypes: [
                {
                    layerTitle: 'Слой проверки',
                    layerType: 'check',
                    icon: 'icon custom-icon-layer-check',
                },
                {
                    layerTitle: 'Слой вычисления',
                    layerType: 'calc',
                },
                {
                    layerTitle: 'Слой вычисления для свода',
                    layerType: 'aggregate',
                },
                {
                    layerTitle: 'Тип данных',
                    layerType: 'type',
                },
                {
                    layerTitle: 'Примечание',
                    layerType: 'hint'
                },
                {
                    layerTitle: 'Слой текста',
                    layerType: 'text',
                    icon: 'icon custom-icon-layer-text',
                }
            ],
            allModes: [
                { label: 'История', icon: 'custom-history-icon', code: 'history' },
                { label: 'Листы', icon: 'custom-list-icon', code: 'lists' },
                { label: 'Формулы', icon: 'custom-formula-icon', code: 'formulaBuilder' },
            ],
            showBehaviorDialog: false,
            visibilityLimitedRules: [],
            selectedPeriod: null,
            editableByVerifier: false,
            allTemplatePeriods: [],
            showRightMenu: false,
            groups: null,
            worksheetHistory: [],
            formulaBuilding: false,
            headerFixed: false,
            fixedRow: 0,
            fixedColumn: 0,
            colWidths: [],
            rowHeights: [],
            choiseCellsArray: [],
            shiftBuildCellsArrayIndicator: 0,
            hoverCellCoords: { row: null, col: null },
            contextMenuMode: 'cell',
            hotSettings: {
                data: [],
                colHeaders: true,
                rowHeaders: true,
                fillHandle: {
                    autoInsertRow: false,
                },
                autoColumnSizeObject: false,
                autoColumnSize: false,
                readOnlyCellClassName: 'header',
                contextMenu: {
                    items: {
                        cutLayers: {
                            name: function() {
                                return '<i class=\'icon custom-crop\'></i> Вырезать';
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.showLayersCopyOrDeletePopup('cut');
                            },
                        },
                        copyLayers: {
                            name: function() {
                                return ' <i class= \'pi pi-fw pi-clone\'></i> Копировать';
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.showLayersCopyOrDeletePopup('copy');
                            },
                        },
                        pasteLayers: {
                            name: '<i class=\'icon custom-paste\'></i> Вставить',
                            disabled: () => {
                                return this.copyLayersArray.length === 0;
                            },
                            hidden: () => {
                                const hasTextLayer = this.layers.find((layer) => layer.layerType === 'text').layersDescr?.length > 0;

                                if (hasTextLayer) {
                                    return !this.copyLayersArray.find((layer) => layer.layerType === 'text');
                                }

                                return false;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.pasteLayers();
                            },
                        },
                        sp1: '---------',
                        addCheck: {
                            name: function() {
                                return ' <i class= \'icon custom-icon-layer-check\'></i><span>Добавить проверку</span>';
                            },
                            hidden: () => {
                                return this.layers.find((layer) => layer.layerType === 'text').layersDescr?.length > 0;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.selectedLayerType = { layerTitle: 'Слой проверки', layerType: 'check' };
                                this.insertLayerStart(true);
                            },
                        },
                        addCalc: {
                            name: function() {
                                return ' <i class= \'icon custom-icon-layer-func\'></i> Слой вычисления';
                            },
                            disabled: () => {
                                return this.layers.find((layer) => layer.layerType === 'calc')?.length > 0;
                            },
                            hidden: () => {
                                 return this.layers.find((layer) => layer.layerType === 'text').layersDescr?.length > 0;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.selectedLayerType = { layerTitle: 'Слой вычисления', layerType: 'calc' };
                                this.insertLayerStart(true);
                            },
                        },
                        addCalcFew: {
                            name: function() {
                                return ' <i class= \'icon custom-icon-layer-summ\'></i> Слой вычисления для свода';
                            },
                            disabled: () => {
                                return this.layers.find((layer) => layer.layerType === 'aggregate')?.length > 0;
                            },
                            hidden: () => {
                                return this.layers.find((layer) => layer.layerType === 'text').layersDescr?.length > 0;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.selectedLayerType = {
                                    layerTitle: 'Слой вычисления для свода',
                                    layerType: 'aggregate',
                                };
                                this.insertLayerStart();
                            },
                        },
                        addMask: {
                            name: function() {
                                return ' <i class= \'icon custom-icon-number\'></i> Тип данных';
                            },
                            disabled: () => {
                                return this.layers.find((layer) => layer.layerType === 'type')?.length > 0;
                            },
                            hidden: () => {
                                return this.layers.find((layer) => layer.layerType === 'text').layersDescr?.length > 0;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.selectedLayerType = { layerTitle: 'Тип данных', layerType: 'type' };
                                this.insertLayerStart();
                            },
                        },

                        addHint: {
                            name: function() {
                                return ' <i class= \'icon custom-hint\'></i> Примечание';
                            },
                            disabled: () => {
                                return this.layers.find((layer) => layer.layerType === 'type')?.length > 0;
                            },
                            hidden: () => {
                                return this.layers.find((layer) => layer.layerType === 'text').layersDescr?.length > 0;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.selectedLayerType = { layerTitle: 'Примечание', layerType: 'hint' };
                                this.insertLayerStart();
                            },
                        },

                        deleteLayers: {
                            name: '<i class=\'pi pi-fw pi-trash\'></i> Удалить слои',
                            disabled: () => {
                                return this.currentRow !== this.endRowCurrentDiapazon || this.currentColl !== this.endCollCurrentDiapazon;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.showLayersCopyOrDeletePopup('delete');
                            },
                        },
                        sp2: '---------',
                        customMergeCells: {
                            name: '<i class=\'icon custom-union\'></i> Объединить ячейки',
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.customMergeCells();
                            },
                        },
                        cancelLastMergeCells: {
                            name: '<i class=\'icon custom-disconnect\'></i> Разъединить ячейки',
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false;
                                this.cancelLastMergeCells();
                            },
                        },
                        sp3: '---------',
                        lockHeader: {
                            name: function () {
                                return "<i class='icon custom-lock'></i>Зафиксировать шапку";
                            },
                            disabled: () => {
                                return !!this.headerFixed;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false
                                this.fixHeaders(true, true);
                            },
                        },
                        unlockHeader: {
                            name: function () {
                                return "<i class='icon custom-unlock'></i>Распустить шапку";
                            },
                            disabled: () => {
                                return !this.headerFixed;
                            },
                            callback: (key, selection, clickEvent) => {
                                if (isRightMouseClick(clickEvent)) return false
                                this.fixHeaders(false, true);
                            },
                        },
                    },
                },
                dropdownMenu: {
                    items: {
                        colBehavior: {
                            name: 'Поведение колонки',
                            callback: () => {
                                this.archBehaviorShow();
                            },
                        }
                    },
                },
                width: '100%',
                manualColumnResize: true,
                manualRowResize: true,
                autoRowSize: { syncLimit: '40%', allowSampleDuplicates: true },
                mergeCells: true,
                selectionMode: 'range',
                outsideClickDeselects: false,
                viewportRowRenderingOffset: 10,
                viewportColumnRenderingOffset: 10,
                rowHeights(index) {
                    return index === 0 ? 30 : 30;
                },
                colWidths(index) {
                    return index === 0 ? 200 : 150;
                },
                licenseKey: 'non-commercial-and-evaluation',
                beforeColumnResize: () => {
                    this.$refs.myTable.hotInstance.updateSettings({
                        language: 'en-US',
                    });
                },
                afterRowResize: (newSize, row) => {
                    this.rowHeights[Number(row)] = newSize;
                },
                afterColumnResize: (newSize, col) => {
                    this.colWidths[Number(col)] = newSize;
                },
                afterBeginEditing: async () => {
                    // блокировка перехода в редактирование ячейки при
                    // режиме построения формул
                    if (this.formulaBuilding || this.currentLayer.layerType !== 'text') {
                        await this.$nextTick();
                        const hot = this.$refs.myTable.hotInstance;
                        hot.getActiveEditor().close();
                    }
                },

                afterDocumentKeyDown: (event) => {
                    //старт удаления по кнопке DEL
                    if (event.keyCode === 46 && !this.formulaBuilding && this.showTable && this.canDeleteLayer) {
                        event.stopImmediatePropagation();
                        this.showLayersCopyOrDeletePopup('delete');
                        event.preventDefault();
                        return;
                    }
                },
                afterGetColHeader: (col, TH) => {
                    if (this.visibilityLimitedRules.length && col >= 0)
                        this.visibilityLimitedRules.map((visibilityRule) => {
                            if (visibilityRule.attributes.column === col) TH.classList.add('visibilityLimited');
                        });
                },
                afterGetRowHeader: (row, TH) => {
                    if (this.visibilityLimitedRules.length && row >= 0)
                        this.visibilityLimitedRules.map((visibilityRule) => {
                            if (visibilityRule.attributes.row === row) TH.classList.add('rowVisibilityLimited');
                        });
                },


                afterSelection: (row, column, row2, column2) => {
                    this.currentRow = row;
                    this.currentColl = column;
                    this.endRowCurrentDiapazon = row2;
                    this.endCollCurrentDiapazon = column2;

                    if (row === -1 && !this.formulaBuilding) {
                        this.$refs.myTable.hotInstance.updateSettings({
                            contextMenu: this.dropdownMenuReserveSettings,
                        });
                        this.contextMenuMode = 'column';
                    }
                    if (column === -1 && !this.formulaBuilding) {
                        this.$refs.myTable.hotInstance.updateSettings({
                            contextMenu: this.rowContextMenu,
                        });
                        this.contextMenuMode = 'row';
                    }
                    if (column >= 0 && row >=0 && this.contextMenuMode !== 'cell' && !this.formulaBuilding) {
                        this.$refs.myTable.hotInstance.updateSettings({
                            contextMenu: this.contextMenuReserveSettings,
                        });
                        this.contextMenuMode = 'cell';
                    }
                },

                afterSelectionEnd: () => {
                    // Блокировка выделения нескольких ячеек в режиме построения формулы
                    if (this.formulaBuilding) {
                        const hot = this.$refs.myTable.hotInstance,
                            selected = hot.getSelectedRange();
                        if (selected[0].to.col !== this.currentColl || selected[0].to.row !== this.currentRow) {
                            selected[0].to.col = this.currentColl;
                            selected[0].to.row = this.currentRow;
                            hot.selectCells(selected);
                        }
                    }
                },

                beforeOnCellMouseDown: (event, coords) => {
                    // Если таблица в режиме построения формулы
                    if (this.formulaBuilding) {
                        event.preventDefault();
                        event.stopImmediatePropagation();

                        if (coords.col === -1 || coords.row === -1) return;
                        //не добавляем координаты ячейки в формулу если нажимаем любую кнопку мыши кроме первой
                        if (event.which !== 1) return;

                        // если нажат контрол плюс мышь собираем в массив координаты, по одной не отправляем
                        if (event.ctrlKey || event.metaKey) {
                            // let haveDuplicate = this.choiseCellsArray.find((cell) => cell.col === coords.col && cell.row === coords.row);
                            // if (!haveDuplicate) this.choiseCellsArray.push(coords);

                            this.$refs.myTable.hotInstance.updateSettings({
                                contextMenu: null,
                                dropdownMenu: null,
                            });

                            this.refreshFormulaValue({ coords, formulaVariant: null, delimiter: true });
                            return;
                        } else {
                            this.choiseCellsArray = [];
                        }
                        if (event.shiftKey) {
                            if (this.shiftBuildCellsArrayIndicator === 0 && this.choiseCellsArray.length) this.choiseCellsArray = [];

                            let haveDuplicate = this.choiseCellsArray.find((cell) => cell.col === coords.col && cell.row === coords.row);
                            if (!haveDuplicate) {
                                this.choiseCellsArray.push(coords);
                                this.shiftBuildCellsArrayIndicator++;
                            }

                            if (this.shiftBuildCellsArrayIndicator === 2) {
                                let startRow = this.choiseCellsArray[0].row;
                                let startCol = this.choiseCellsArray[0].col;
                                let endRow = this.choiseCellsArray[1].row;
                                let endCol = this.choiseCellsArray[1].col;
                                this.choiseCellsArray = [];

                                if (startRow !== endRow || startCol !== endCol) {
                                    //Сбор координат ячеек диапазона
                                    if (startRow > endRow || startCol > endCol) {
                                        this.shiftBuildCellsArrayIndicator = 0;
                                        this.$refs.myTable.hotInstance.updateSettings({
                                            contextMenu: null,
                                            dropdownMenu: null,
                                        });
                                        return;
                                    }

                                    this.dataFromTable.map((row, i) => {
                                        if (i >= startRow && i <= endRow) {
                                            row.map((cell, cellIndex) => {
                                                if (cellIndex >= startCol && cellIndex <= endCol) {
                                                    this.choiseCellsArray.push({ row: i, col: cellIndex });
                                                }
                                            });
                                        }
                                    });
                                    this.$refs.myTable.hotInstance.updateSettings({
                                        contextMenu: null,
                                        dropdownMenu: null,
                                    });
                                    this.shiftBuildCellsArrayIndicator = 0;
                                    return;
                                }
                            }
                            if (this.shiftBuildCellsArrayIndicator >= 3) {
                                this.shiftBuildCellsArrayIndicator = 0;
                                this.choiseCellsArray = [];
                                return;
                            }
                            this.$refs.myTable.hotInstance.updateSettings({
                                contextMenu: null,
                                dropdownMenu: null,
                            });

                            return;
                        }
                        this.refreshFormulaValue({ coords });
                    }
                },
                beforeOnCellMouseUp: () => {
                    if (!this.formulaBuilding) {
                        this.canDeleteLayer = true;
                        this.onLayerSelectorHandler(this.startLayerOnCell, false);
                    }
                },

                afterChange: (changes) => {
                    if (!changes) return;
                    const hot = this.$refs.myTable.hotInstance;
                    changes.forEach(async (editedCell) => {
                        const [row, col, oldValue, newValue] = editedCell;
                        if (this.dataFromTable[row][col].layers.length <= 1) {
                            this.dataFromTable[row][col].layers[0].layersDescr = newValue;
                            if (newValue?.length > 0) {
                                this.dataFromTable[row][col].className = ['header', 'blocked-header'];
                            } else {
                                this.dataFromTable[row][col].className = [''];
                            }
                            if (oldValue !== newValue && newValue !== null) {
                                this.tableHaveChange = true;
                                await this.$nextTick();
                                hot.updateSettings({});
                            }
                        }
                    });
                },

                beforeAutofill: (selectionData, sourceRange, targetRange, direction) => {
                    this.moreThenOneSelectedCell = false;
                    this.cellForAutoFillingCoordinates = { row: sourceRange.from.row, col: sourceRange.from.col };
                    this.currentRow = sourceRange.from.row;
                    this.currentColl = sourceRange.from.col;
                    this.endRowCurrentDiapazon = targetRange.to.row;
                    this.endCollCurrentDiapazon = targetRange.to.col;
                    // прописать инверсию с учетом направления смещения влево и вверх
                    if (direction === 'up') {
                        this.currentRow = targetRange.from.row;
                        this.currentColl = sourceRange.from.col;
                        this.endRowCurrentDiapazon = sourceRange.to.row;
                        this.endCollCurrentDiapazon = targetRange.to.col;
                    }
                    if (direction === 'left') {
                        this.currentRow = sourceRange.from.row;
                        this.currentColl = targetRange.to.col;
                        this.endRowCurrentDiapazon = targetRange.to.row;
                        this.endCollCurrentDiapazon = sourceRange.from.col;
                    }
                    this.collectTextLayers();
                    if (selectionData.length > 1 || selectionData[0].length > 1) {
                        this.moreThenOneSelectedCell = true;
                    }
                },
                afterAutofill: async (selectionData, sourceRange, targetRange) => {
                    if (this.moreThenOneSelectedCell) {
                        return;
                    }

                    try {
                        await new Promise((resolve, reject) => {
                            this.$on('resolveCopyDialog', resolve);
                            this.$on('rejectCopyDialog', reject);
                            this.showLayersCopyOrDeletePopup('pas');
                        });
                    } catch (e) {
                        this.acceptedCopy = false;
                    }
                    if (!this.acceptedCopy) return;
                    this.insertTextLayers();
                    this.pasteLayers();
                    //сохранять выделение ячейки после копирования слоев
                    this.$refs.myTable.hotInstance.selectCell(targetRange.to.row, targetRange.to.col);
                },
                afterRenderer: (td, row, col, prop, value, cellProperties) => {
                    cellProperties.id = this.dataFromTable[row][col].id;
                    cellProperties.head = this.dataFromTable[row][col].head;

                    cellProperties.className = this.dataFromTable[row][col].className.join(' ');

                    cellProperties.readOnly = this.dataFromTable[row][col].readOnly;
                    if (this.currentRow === row && this.currentColl === col) {
                        cellProperties.className = `${this.dataFromTable[row][col].className.join(' ')} choiseCell`;
                    }

                    if (this.formulaBuilding && this.choiseCellsArray.length > 0) {
                        this.choiseCellsArray.map((markCell) => {
                            if (markCell.col === col && markCell.row === row) cellProperties.className = `${this.dataFromTable[row][col].className.join(' ')} yellowsubmarine`;
                        });
                    }
                    if (this.currentRow === row && this.currentColl === col && this.formulaBuilding) {
                        cellProperties.className = `${this.dataFromTable[row][col].className.join(' ')} buildFormula`;
                    }

                    if (this.dataFromTable[row][col] && this.dataFromTable[row][col].layers.length > 1) {
                        const elem = this.createLayersButtonGroup(this.dataFromTable[row][col].layers, row, col);

                        Handsontable.dom.addEvent(elem, 'mousedown', (event) => {
                            if (event.target.classList.contains('threeDots') && !this.formulaBuilding) {
                                this.openLayers = !this.openLayers;
                                return;
                            }
                            if (event.target.classList.contains('p-inputgroup-addon') && !this.formulaBuilding) {
                                this.$refs.myTable.hotInstance.selectCell(+event.target.dataset.row, +event.target.dataset.col);
                                const layerIndex = event.target.dataset.layerindex;
                                const layer = this.filteredLayers[Number(layerIndex)];
                                if (layer && !this.formulaBuilding && event.target.classList.contains('p-inputgroup-addon')) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    this.onLayerSelectorHandler(layer, false);
                                    if (!['type', 'aggregate', 'hint'].includes(layer.layerType)) {
                                        this.startFormulaWriting();
                                    } else {
                                        this.currentLayer = layer;
                                        this.editLayer(this.currentLayer);
                                    }
                                }
                            }
                        });

                        td.appendChild(elem);
                    }
                },
            },
            language: 'en-US',
            contextMenuReserveSettings: {
                items: {
                    cutLayers: {
                        name: '<i class=\'icon custom-crop\'></i> Вырезать',
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.showLayersCopyOrDeletePopup('cut');
                        },
                    },
                    copyLayers: {
                        name: function() {
                            return ' <i class= \'pi pi-fw pi-clone\'></i> Копировать';
                        },
                        // name: 'Копировать',
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.showLayersCopyOrDeletePopup('copy');
                        },
                    },
                    pasteLayers: {
                        name: '<i class=\'icon custom-paste\'></i> Вставить',
                        disabled: () => {
                            return this.copyLayersArray.length === 0;
                        },
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.pasteLayers();
                        },
                    },
                    sp1: '---------',

                    addCheck: {
                        name: function() {
                            return ' <i class= \'icon custom-icon-layer-check\'></i> Добавить проверку';
                        },
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.selectedLayerType = { layerTitle: 'Слой проверки', layerType: 'check' };
                            this.insertLayerStart(true);
                        },
                    },
                    addCalc: {
                        name: function() {
                            return ' <i class= \'icon custom-icon-layer-func\'></i> Слой вычисления';
                        },
                        disabled: () => {
                            return this.layers.filter((layer) => layer.layerType === 'calc').length > 0;
                        },
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.selectedLayerType = { layerTitle: 'Слой вычисления', layerType: 'calc' };
                            this.insertLayerStart(true);
                        },
                    },
                    addCalcFew: {
                        name: function() {
                            return ' <i class= \'icon custom-icon-layer-summ\'></i> Слой вычисления для свода';
                        },
                        disabled: () => {
                            return this.layers.filter((layer) => layer.layerType === 'aggregate').length > 0;
                        },
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.selectedLayerType = {
                                layerTitle: 'Слой вычисления для свода',
                                layerType: 'aggregate',
                            };
                            this.insertLayerStart();
                        },
                    },
                    addMask: {
                        name: '<i class= \'icon custom-icon-number\'></i> Тип данных',
                        disabled: () => {
                            return this.layers.filter((layer) => layer.layerType === 'type').length > 0;
                        },
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.selectedLayerType = { layerTitle: 'Тип данных', layerType: 'type' };
                            this.insertLayerStart();
                        },
                    },
                    addHint: {
                        name: function() {
                            return ' <i class= \'icon custom-hint\'></i> Примечание';
                        },
                        disabled: () => {
                            return this.layers.filter((layer) => layer.layerType === 'type').length > 0;
                        },
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.selectedLayerType = { layerTitle: 'Примечание', layerType: 'hint' };
                            this.insertLayerStart();
                        },
                    },
                    deleteLayers: {
                        name: '<i class=\'pi pi-fw pi-trash\'></i> Удалить слои',
                        disabled: () => {
                            return this.currentRow !== this.endRowCurrentDiapazon || this.currentColl !== this.endCollCurrentDiapazon;
                        },
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.showLayersCopyOrDeletePopup('delete');
                        },
                    },
                    sp2: '---------',
                    customMergeCells: {
                        name: '<i class=\'icon custom-union\'></i> Объединить ячейки',
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.customMergeCells();
                        },
                    },
                    cancelLastMergeCells: {
                        name: '<i class=\'icon custom-disconnect\'></i> Разъединить ячейки',
                        callback: (key, selection, clickEvent) => {
                            if (isRightMouseClick(clickEvent)) return false;
                            this.cancelLastMergeCells();
                        },
                    }
                },
            },
            dropdownMenuReserveSettings: {
                items: {
                    colBehavior: {
                        name: 'Поведение колонки',
                        callback: () => {
                            this.archBehaviorShow();
                        },
                    }
                },
            },
            rowContextMenu: {
                items: {
                    colBehavior: {
                        name: 'Поведение строки',
                        callback: () => {
                            this.archBehaviorShow();
                        }
                    }
                }
            }
        };
    },

    methods: {
        delimiterSelect(variant) {
            this.currentDelimiter = variant;
            this.insertCellDelimiter = variant;
        },

        unlockHandler(payload) {
            this.unlockCursor = payload;
        },

        async onBlurHandler() {
            if (this.unlockCursor) return;
            const parentNode = this.$refs.contentEditableDiv;
            const childNodes = parentNode?.childNodes;

            if (this.formulaBuilding) {
                const cursorPosition = getStartCursorPositionIntoParent(parentNode);
                parentNode.focus();
                if (cursorPosition < 0) return;
                await this.$nextTick();
                const range = new Range();
                try {
                    if (childNodes?.length) {
                        range.setStartAfter(childNodes[cursorPosition]);
                        range.setEndAfter(childNodes[cursorPosition]);
                    } else {
                        range.setStart(parentNode, cursorPosition);
                        range.setEnd(parentNode, cursorPosition);
                    }
                    this.currentRange = range;
                } catch (e) {
                    console.log(e);
                }
            }
        },

        nextIsCell() {
            const [cursorPosition, parentNode] = this.getCursorPosition();
            const nextEl = parentNode.childNodes[cursorPosition + 1];
            return !!nextEl?.classList?.contains('cellMarker');
        },

        prevIsCell(saveCursor = false) {
            const parentNode = this.$refs.contentEditableDiv;
            const parentChildNodes = parentNode.childNodes;
            if (!parentChildNodes.length) {
                return false;
            }
            let cursorPosition = getStartCursorPositionIntoParent(parentNode);
            if (saveCursor) {
                cursorPosition = this.currentRange?.endOffset;
            }
            for (let cursor = cursorPosition; cursor >= 0; cursor--) {
                if (cursor < 0) {
                    cursor = 0;
                }
                if (parentChildNodes[cursor]?.classList?.contains('cellMarker')) {
                    return cursor;
                }
                if (cursor === 1 && parentChildNodes[cursor]?.nextSibling?.classList?.contains('cellMarker')) {
                    return cursor - 1;
                }
                if (parentChildNodes[cursor]?.data?.replace(/&nbsp;/g,' ').trim() !== '') {
                    return false;
                }
            }
        },

        selectCellForChange(saveCursor = false) {
            const isCellPosition = this.prevIsCell(saveCursor);

            if (isCellPosition !== false) {
                const parentNode = this.$refs.contentEditableDiv;
                const parentChildNodes = parentNode.childNodes;
                if (!this.currentRange) {
                    const {range} = getSelectionRange();
                    this.currentRange = range;
                }
                const node = parentChildNodes[isCellPosition];
                if (!node) {
                    return;
                }
                this.currentRange.selectNode(node);
            }
        },

        getCursorPosition() {
            const parentNode = this.$refs.contentEditableDiv;
            const { range } = getSelectionRange();
            const { commonAncestorContainer, endOffset } = range;
            // поиск позиции элемента в contentEditableDiv
            const cursorPosition = getFirstLevelNodeIdx(commonAncestorContainer, parentNode) ?? endOffset;
            const notElementPosition = endOffset === cursorPosition;
            return [cursorPosition, range, parentNode, notElementPosition];
        },

        placeCursorAfterCell() {
            const [cursorPosition, range, parentNode] = this.getCursorPosition();
            const childNodes = parentNode.childNodes;
            if (childNodes?.length) {
                range.setStartAfter(childNodes[cursorPosition]);
                range.setEndAfter(childNodes[cursorPosition]);
                return;
            }
            range.setStart(parentNode, cursorPosition);
            range.setEnd(parentNode, cursorPosition);
            this.currentRange = range;
        },

        isValidNode(node) {
            const validNode = this.$refs.contentEditableDiv;
            return validNode.isEqualNode(node)
                || (validNode.contains(node) && node.nodeName === '#text' && validNode.isEqualNode(node.parentNode));
        },

        cursorToEndOfRow() {
            const node = this.$refs.contentEditableDiv;
            const { range } = getSelectionRange();
            range.setStart(node, node.childNodes.length);
            range.setEnd(node, node.childNodes.length);
            this.currentRange = range;
        },

        async unselectRange() {
            await this.$nextTick();
            const { range } = getSelectionRange();
            let { startOffset, endOffset } = range;
            if (startOffset === endOffset) return;
            const node = this.$refs.contentEditableDiv;
            range.setStart(node, endOffset);
            range.setEnd(node, endOffset);
            this.currentRange = range;
        },

        async onLayerSelectorHandler(layer, needValid = true, needEdit = false) {
            if (layer?.id) {
                if (needValid && ['check', 'calc'].includes(this.currentLayer.layerType)) {
                    const isValid = await this.validateLayer();
                    if (!isValid) {
                        return;
                    }
                }

                if (!layer.needConvert && layer.layersDescr?.length && ['check', 'calc'].includes(layer.layerType)) {
                    layer.layersDescr = this.unconvertLayer(layer.layersDescr);
                    layer.needConvert = true;
                }
                this.currentLayer = layer;
                this.currentLayerId = layer.id;
                this.currentLayerType = layer.layerType;
                if (layer.layerType === 'text') {
                    this.textRow = this.currentRow;
                    this.textColumn = this.currentColl;
                }
                if (needEdit && ['aggregate', 'type', 'hint'].includes(layer.layerType)) {
                    this.editLayer(this.currentLayer);
                }
            }
        },

        async validateLayer() {
            if (!this.currentLayer) {
                return;
            }
            if (this.currentLayer) {
                this.currentLayer.layersDescr = this.$refs.contentEditableDiv
                    ? this.$refs.contentEditableDiv?.innerHTML.replace(/&nbsp;/g,'') : this.currentLayer.layersDescr;
                this.currentLayer.needConvert = true;
            }

            try {
                let value = this.convertLayerDescr(this.currentLayer.layersDescr);
                if (!value) {
                    this.endFormulaWriting();
                    this.tableHaveChange = true;
                    return true;
                }

                const data = {
                    data: [{
                        type: 'form-template-cell-layer',
                        id: this.currentLayer.id,
                        attributes: {
                            type: this.currentLayer.layerType,
                            value
                        },
                        relationships: {
                            cell: {
                                data: {
                                    type: 'form-template-cell',
                                    id: this.dataFromTable[this.currentRow][this.currentColl].id
                                }
                            }
                        }
                    }]
                };

                const { data: result } = await validateCellLayer(this.currentWorcksheetId, data);
                if (result.length) {
                    this.validationTitle = 'Ошибка валидации формулы';
                    this.validationErrorShow = true;
                    this.validationError = '';
                    result.forEach((error) => {
                        this.validationError += `${ error.attributes.title.split('for expression')[0] }<br><span>`;
                        this.validationError += `${ this.currentLayer.layersDescr }</span>`;
                    });
                    return false;
                } else {
                    let layerIndex = this.dataFromTable[this.currentRow][this.currentColl].layers.findIndex(
                        (layer) => layer.id === this.currentLayer.id
                    );
                    if (layerIndex >= 0) {
                        this.dataFromTable[this.currentRow][this.currentColl].layers[layerIndex].layersDescr = this.currentLayer.layersDescr;
                        this.dataFromTable[this.currentRow][this.currentColl].layers[layerIndex].needConvert = true;
                    }
                    this.updateData();
                    this.endFormulaWriting();
                    this.tableHaveChange = true;
                    return true;
                }
            } catch(error) {
                this.$requestError('Ошибка распознавания формулы: Пожалуйста, проверьте вставленную формулу.');
            }
        },

        onClickOutside() {
            this.openLayers = false;
        },

        onClickTableOutside() {
            this.canDeleteLayer = false;
        },

        getLayerByType(l) {
            if (!l || l.layerType === 'text') {
                return {
                    layerType: 'text',
                    layerTitle: 'Слой текста',
                    icon: 'custom-icon-layer-text icon',
                };
            }
            return { ...this.allLayerTypes.find(i => i.layerType === l.layerType) };
        },

        async formulaKeydownHandler(e) {
            const { key } = e;
            this.unselectRange();
            if (key === 'Meta' || key === 'Control') {
                this.showDelimiterVariants = true;
            }
            const metaKeys = ['Meta', 'Control', 'Alt', 'Shift'];
            const keyIsMetaButton = metaKeys.some(k => k === key);
            if (this.choiseCellsArray.length && !keyIsMetaButton) {
                e.stopImmediatePropagation();
                e.preventDefault();
                this.insertCellDelimiter = key === '=' ? '==' : key;
                this.refreshFormulaValue({ coords: this.choiseCellsArray });
                this.choiseCellsArray = [];
                this.insertCellDelimiter = null;
                this.$refs.myTable.hotInstance.updateSettings({});
                return false;
            }
            if (key === '=') {
                const selection = document.getSelection();
                const range = selection.getRangeAt(0);
                const text = document.createTextNode('==');
                range.insertNode(text);
                e.stopImmediatePropagation();
                e.preventDefault();
                return false;
            }
            if (['+', '-', '/', '*', '?'].includes(key)) {
                const selection = document.getSelection();
                const range = selection.getRangeAt(0);
                const text = document.createTextNode(`${ key } `);
                range.insertNode(text);
                e.stopImmediatePropagation();
                e.preventDefault();
                return false;
            }
            if (key === 'Enter') {
                await this.validateLayer();
            }
            if (key === 'Escape') {
                await this.cancelChanges();
            }
        },

        formulaKeyupHandler(e) {
            const { key } = e;
            if (key === 'Meta' || key === 'Control') {
                this.showDelimiterVariants = false;
            }
        },

        getTrimHtml(val) {
            if (val) {
                return val.trim() + `&nbsp;`;
            } else {
                return '';
            }
        },

        lightOnHoverCell(event) {
            const target = event.target;
            const hot = this.$refs.myTable.hotInstance
            let changedCells = false
            if (this.hoverCellCoords.row) {
                const { row, col } = this.hoverCellCoords
                const cell = this.dataFromTable[row][col]
                cell.className = cell.className.filter(i => i !== 'hoverCell')
                changedCells = true
            }
            if (target.classList.contains('cellMarker') && !target.dataset.cellwithoterform && !target.dataset.prevperiod) {
                if (this.hoverCellCoords.row === +event.target.dataset.row && this.hoverCellCoords.col === +event.target.dataset.col) return;
                const layerCellSharedId = event.target.dataset.sharedid
                const layerCell = this.cellIncluded.formTemplateCells.find(i => i.attributes.sharedId === layerCellSharedId)
                if (layerCell) {
                    this.placeCursorAfterCell()
                    return;
                }
                this.hoverCellCoords.row = +event.target.dataset.row;
                const row = +event.target.dataset.row;
                this.hoverCellCoords.col = +event.target.dataset.col;
                const col = +event.target.dataset.col;
                const cell = this.dataFromTable[row][col]
                cell.className.push('hoverCell')
                this.placeCursorAfterCell()
                // this.updateData();
                // this.$refs.myTable.hotInstance.updateSettings({ language: 'en-US' });
                //скролить до нужной ячейки таблицу
                // this.$refs.myTable.hotInstance.scrollViewportTo(this.hoverCellCoords.row, this.hoverCellCoords.col);
                hot.updateSettings({})
                event.stopPropagation();
                return;
            }
            if (this.hoverCellCoords.row === null && this.hoverCellCoords.col === null) return;
            // this.$refs.myTable.hotInstance.scrollViewportTo(this.currentRow, this.currentColl);
            this.hoverCellCoords.row = null;
            this.hoverCellCoords.col = null;
            if (changedCells) hot.updateSettings({});
        },

        async insertHTML(html, saveCursor = false) {
            try {
                this.$refs.contentEditableDiv.focus();
                if (!saveCursor) {
                    const { range } = getSelectionRange();
                    this.currentRange = range;
                }
                const { commonAncestorContainer } = this.currentRange;
                // проверка на то, что вставляем в нужный div с content=editable
                // если не прошло проверку, то в catch ставим курсор
                // в последнее место и вставляем куда нужно
                if (!this.isValidNode(commonAncestorContainer)) {
                    throw new Error('');
                }
                const newEl = document.createElement('div');
                newEl.innerHTML = html;
                // переворачиваем массив так как после вставки одного элемента
                // курсор остается на старом месте
                const childNodes = Array.from(newEl.childNodes).reverse();
                this.selectCellForChange(saveCursor);
                this.currentRange.deleteContents();
                childNodes.forEach(n => {
                    if (n.nodeName !== '#text' || n.data) this.currentRange.insertNode(n);
                });
                await this.unselectRange();
            } catch (z) {
                console.log(z);
                try {
                    this.placeCursorAfterCell();
                    this.$nextTick().then(() => {
                        this.startFormulaWriting();
                        this.insertHTML(html);
                    });
                } catch (z) {
                    console.log(z);
                }
            }
        },

        formulaLayerContextmenuHandler(event) {
            if (event.target.classList.contains('cellMarker')) {
                event.target.remove();
                event.preventDefault();
                return;
            }
            this.insertCellsArray(this.choiseCellsArray);
        },

        async insertCellsFromForm({ cells, delimiter }) {
            if (delimiter) {
                this.insertCellDelimiter = delimiter;
            }
            this.refreshFormulaValue({ coords: cells, saveCursor: true });
            for (let cell of cells) {
                if (!this.otherFormCells[cell.listInfo.id]) {
                    try {
                        const { data: cellArray } = await getWorkSheetCells(cell.listInfo.id);
                        this.otherFormCells[cell.listInfo.id] = cellArray;
                        if (cellArray) this.cellIncluded.formTemplateCells = this.cellIncluded.formTemplateCells.concat(cellArray);
                        if (cell.form) this.cellIncluded.forms.push(cell.form);
                        if (cell.template) this.cellIncluded.formTemplates.push(cell.template);
                        if (cell.list) this.cellIncluded.formTemplateWorksheets.push(cell.list);
                    } catch (error) {
                        this.$requestError(error.message);
                    }
                }
            }
        },

        insertGetByTag({ cells, tag }) {
            const [cell] = cells;
            const { id: sharedId, formInfo, listInfo, templateInfo, row, col: column } = cell;
            const htmlString = `getByTag(${createCellLayout({
                sharedId,
                row,
                column,
                worksheetTitle: listInfo.name,
                templateTitle: templateInfo.name,
                formTitle: formInfo.name,
                cellWithOterForm: true,
                hot: this.$refs.myTable.hotInstance,
                coordinates: listInfo?.coordinates || []
            })},${tag})`;
            this.refreshFormulaValue({ formulaVariant: htmlString, saveCursor: true });
        },

        async restore(version) {
            this.$emit('loadingChange', true);
            try {
                this.showTable = false;
                this.endFormulaWriting();

                const { data: cellArray, included } = await getWorkSheetCells(this.currentWorcksheetId, version);
                let worksheet = included.find((item) => item.id === cellArray[0].relationships.worksheet.data.id);
                this.currentWorksheetVersion = worksheet?.attributes.version;

                this.dataFromTable = this.agregateCells(cellArray, included);
                this.cellArray = cellArray;
                included.forEach((curItem) => {
                    if (curItem.type === 'form') this.cellIncluded.forms.push(curItem);
                    if (curItem.type === 'form-template') this.cellIncluded.formTemplates.push(curItem);
                    if (curItem.type === 'form-template-worksheet') this.cellIncluded.formTemplateWorksheets.push(curItem);
                    if (curItem.type === 'form-template-cell') this.cellIncluded.formTemplateCells.push(curItem);
                });

                this.startDataFromTable = deepArrayCopy(this.dataFromTable);
                this.showArrayMap();
                this.updateData();
                if (worksheet.attributes.properties.fixedColumnsLeft > 0 || worksheet.attributes.properties.fixedRowsTop > 0) {
                    this.fixedRow = worksheet.attributes.properties.fixedRowsTop;
                    this.fixedColumn = worksheet.attributes.properties.fixedColumnsLeft;
                    this.coordinates = {
                        columnLabels: worksheet.attributes.properties.columnLabels ? worksheet.attributes.properties.columnLabels : [],
                        rowLabels: worksheet.attributes.properties.rowLabels ? worksheet.attributes.properties.rowLabels : []
                    };
                    await this.fixHeaders(true, false);
                }
                setTimeout(() => {
                    this.$refs.myTable?.hotInstance?.render();
                }, 100);
                this.showTable = true;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        formulaVarintSelect(payload) {
            if (Array.isArray(payload)) {
                return;
            }
            if (this.formulaBuilding) this.refreshFormulaValue({ coords: null, formulaVariant: payload });
        },

        insertCellsArray(cellArray = this.choiseCellsArray) {
            if (!cellArray.length) return;
            //Обработать вставку массива ячеек через +
            let agregateCellsString = cellArray
                .map((cell, cellIndex) => {
                    let sharedId = cell.sharedId ? cell.sharedId : this.dataFromTable[cell.row][cell.col].attributes.sharedId;
                    let worcksheetTitle = cell.worcksheetTitle ? cell.worcksheetTitle : this.currentWorksheetTitle;
                    let templateTitle = cell.templateTitle ? cell.templateTitle : null;
                    let formTitle = cell.formTitle ? cell.formTitle : null;

                    if (cell && cell.tag) {
                        return `getByTag(${createCellLayout(sharedId, cell.row, cell.col, worcksheetTitle, templateTitle, formTitle, cell.periodId, cell.prevPeriod, cell.otherForm)}, ${cell.tag}) `;
                    }

                    if (cellArray.length > 1) {
                        if (cellIndex === cellArray.length - 1) {
                            return `${createCellLayout(sharedId, cell.row, cell.col, worcksheetTitle, templateTitle, formTitle, cell.periodId, cell.prevPeriod, cell.otherForm)} `;
                        }
                        return `${createCellLayout(sharedId, cell.row, cell.col, worcksheetTitle, templateTitle, formTitle, cell.periodId, cell.prevPeriod, cell.otherForm)} + `;
                    } else {
                        return `${createCellLayout(sharedId, cell.row, cell.col, worcksheetTitle, templateTitle, formTitle, cell.periodId, cell.prevPeriod, cell.otherForm)} `;
                    }
                })
                .join('');
            this.refreshFormulaValue({ coords: null, formulaVariant: agregateCellsString });
            this.choiseCellsArray = [];
            this.updateData();
        },

        refreshFormulaValue({ coords, formulaVariant = null, delimiter = false, saveCursor = false }) {
            let newInputValue = '';
            if (Array.isArray(coords)) {
                newInputValue = coords.map(i => {
                    const sharedId = i.id || this.dataFromTable[i.row][i.col].attributes.sharedId;
                    const worksheetTitle = i.listInfo?.name || this.currentWorksheetTitle;
                    const templateTitle = i.templateInfo?.name;
                    const formTitle = i.formInfo?.name;
                    const periodId = i.periodInfo?.id;
                    const prevPeriodMode = i.prevPeriod;
                    const accumulateMode = i.accumulate;
                    const cellWithOterForm = i.otherForm;
                    const coordinates = i.listInfo?.coordinates || this.coordinates;
                    const resultString = `${createCellLayout({
                        sharedId,
                        row: i.row,
                        column: i.col,
                        worksheetTitle,
                        templateTitle,
                        formTitle,
                        periodId,
                        prevPeriodMode,
                        accumulateMode,
                        cellWithOterForm,
                        hot: this.$refs.myTable.hotInstance,
                        coordinates
                    })}`
                    if (prevPeriodMode) {
                        return `prev_period(${resultString})`;
                    }
                    if (accumulateMode) {
                        return `accumulate(${resultString})`;
                    }
                    return resultString;
                }).join(` ${ this.insertCellDelimiter } `);
            } else if (coords) {
                newInputValue = createCellLayout({
                    sharedId: this.dataFromTable[coords.row][coords.col].attributes.sharedId,
                    row: coords.row,
                    column: coords.col,
                    worksheetTitle: this.currentWorksheetTitle,
                    hot: this.$refs.myTable.hotInstance,
                    coordinates: this.coordinates
                });
            } else {
                if (formulaVariant.indexOf('period(') >= 0) {

                    if (formulaVariant === 'period()') newInputValue = formulaVariant;
                    else {
                        let periodId = /'([^;]+)'/.exec(formulaVariant)[1];
                        const period = this.allTemplatePeriods.find((period) => period.id === periodId);
                        formulaVariant = `<div contenteditable='false' class='cellMarker' data-periodid='${periodId}' >period(${period && period.attributes.name})</div>`;
                    }
                }
                newInputValue = formulaVariant;
            }
            if (delimiter) {
                newInputValue = `${newInputValue} ${ this.insertCellDelimiter } `;
            }
            this.insertHTML(`${ newInputValue } `, saveCursor);
        },

        async clearInsertData(event) {
            let clearedData = event.clipboardData.getData('text');
            this.insertHTML(clearedData.toString());
            event.preventDefault();
        },

        async startFormulaWriting() {
            if (this.formulaBuilding) return;
            this.formulaBuilding = true;
            this.currentLayerType = this.currentLayer.layerType;
            /** Сохранение предыдущего значения формулы */
            this.previousValue = this.currentLayer.layersDescr;
            this.$refs.myTable.hotInstance.updateSettings({
                contextMenu: null,
                dropdownMenu: null,
            });
            await this.$nextTick();
            this.cursorToEndOfRow();
            await this.focusOnLayer(this.currentLayer.layerType);
            this.selectedMode = this.allModes[2];
        },

        endFormulaWriting(e = null) {
            try {
                this.currentLayerId = null;
                this.currentLayerType = null;
                this.choiseCellsArray = [];
                this.shiftBuildCellsArrayIndicator = 0;

                this.formulaBuilding = false;

                this.$refs.contentEditableDiv.blur();

                this.$refs.myTable.hotInstance.updateSettings({
                    contextMenu: this.contextMenuReserveSettings
                });
                setTimeout(() => {
                    this.$refs.myTable?.hotInstance?.selectCell(this.currentRow, this.currentColl);
                }, 10);
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                return false;
            } catch (e) {
                return;
            }
        },

        endEditLayer(index) {
            let layerTitle = `layer${index}`;
            const textEl = this.$refs[layerTitle].$el;
            if (textEl.value?.length > 0) {
                this.dataFromTable[this.textRow][this.textColumn].className = ['header', 'blocked-header' ];
                this.dataFromTable[this.textRow][this.textColumn].readOnly = true;
                this.dataFromTable[this.textRow][this.textColumn].layers = [this.dataFromTable[this.textRow][this.textColumn].layers[0]];
            }
            else {
                this.dataFromTable[this.textRow][this.textColumn].className = [''];
                this.dataFromTable[this.textRow][this.textColumn].readOnly = false;
            }
            textEl.blur();
        },

        updateData() {
            this.transformData = this.getTableHeaders(this.dataFromTable);
            this.$refs.myTable?.hotInstance?.updateSettings({
                data: this.transformData,
                mergeCells: this.mergeCellsArray,
            });
        },

        getTableHeaders(dataFromTable) {
            let headers = [];

            dataFromTable.forEach((cell) => {
                headers = [...headers, cell.map((data) => data.layers[0].layersDescr)];
            });
            return headers;
        },

        createLayersButtonGroup(layersArray, row, col) {
            let layerIconsGroup = [];
            const imgObj = {
                check: 'custom-icon-layer-check',
                calc: 'custom-icon-layer-func',
                aggregate: 'custom-icon-layer-summ',
                type: 'custom-icon-number',
                hint: 'custom-hint'
            };
            /**
             * Если слоёв больше трёх, тогда показываем троеточие
             */
            let calculatingPosition = 0;
            layersArray.forEach((layer, index) => {
                if (layer.layerType !== 'text') {
                    if (layer.layerType === 'calc') {
                        calculatingPosition = 1;
                        layerIconsGroup.unshift(`<span class="icon ${ imgObj[layer.layerType] } p-inputgroup-addon"
                        style="width: 21px; height: 21px" data-layerIndex="${ index }" data-row="${ row }"
                        data-col="${ col }" data-layertype="${ layer.layerType }" data-layerid='${ layer.id }'></span>`);
                    } else if (layer.layerType === 'check') {
                        layerIconsGroup.splice(calculatingPosition, 0, `<span class="icon ${ imgObj[layer.layerType] } p-inputgroup-addon"
                        style="width: 21px; height: 21px" data-layerIndex="${ index }" data-row="${ row }"
                        data-col="${ col }" data-layertype="${ layer.layerType }" data-layerid='${ layer.id }'></span>`);
                    } else {
                        layerIconsGroup.push(`<span class="icon ${imgObj[layer.layerType]} p-inputgroup-addon"
                        style="width: 21px; height: 21px" data-layerIndex="${index}" data-row="${row}"
                        data-col="${col}" data-layertype="${layer.layerType}" data-layerid='${layer.id}'></span>`);
                    }
                    if (layerIconsGroup.length > 3) {
                        layerIconsGroup.pop();
                    }
                }
            });
            if (layersArray.length > 4) {
                layerIconsGroup.push(`<span class="p-inputgroup-addon threeDots" style="padding-top: 5px">...</span>`);
            }
            const elem = document.createElement('div');
            elem.innerHTML = `${layerIconsGroup.join('')}`;
            elem.classList.add('customLayerCellRender');
            return elem;
        },

        async focusOnLayer(layerType) {
            try {
                if (['aggregate', 'type', 'hint'].includes(layerType)) {
                    this.currentLayer && this.editLayer(this.currentLayer);
                } else {
                    await this.$nextTick();
                    this.$refs.contentEditableDiv.focus();
                }
                return;
            } catch (e) {
                return;
            }
        },

        rightMenuShow() {
            this.showRightMenu = true;
            setTimeout(() => {
                this.$refs.myTable && this.$refs.myTable.hotInstance && this.$refs.myTable.hotInstance.render();
            }, 100);
        },

        hideRightMenu() {
            this.showRightMenu = false;
            this.selectedMode = null;

            setTimeout(() => {
                this.$refs.myTable && this.$refs.myTable.hotInstance && this.$refs.myTable.hotInstance.render();
            }, 100);
        },

        changeComponent(button) {
            this.selectedMode = this.allModes.filter((mode) => mode.label === button.label)[0];
        },

        customMergeCells() {
            if (this.currentRow === this.endRowCurrentDiapazon && this.currentColl === this.endCollCurrentDiapazon) return;

            let curentMergeCellsArray = this.mergeCellsArray.map((cell) => JSON.parse(JSON.stringify(cell)));
            // фильтрация убирает элемент из массива обьединения если с этой ячейки начинается другое обьединение
            for (let cellIndex = this.currentRow; cellIndex < this.endRowCurrentDiapazon + 1; cellIndex++) {
                for (let collIndex = this.currentColl; collIndex < this.endCollCurrentDiapazon + 1; collIndex++) {
                    const curCellIndex = curentMergeCellsArray.findIndex((cell) => cell.row === cellIndex && cell.col === collIndex);
                    if (~curCellIndex) curentMergeCellsArray.splice(curCellIndex, 1);
                }
            }
            let newMergeCellsDiapazon = {
                row: this.currentRow,
                col: this.currentColl,
                rowspan: this.endRowCurrentDiapazon - this.currentRow + 1,
                colspan: this.endCollCurrentDiapazon - this.currentColl + 1,
            };

            curentMergeCellsArray.push(newMergeCellsDiapazon);

            this.mergeCellsArray = curentMergeCellsArray;
            this.$refs.myTable.hotInstance.updateSettings({
                mergeCells: curentMergeCellsArray,
            });
        },

        cancelLastMergeCells() {
            let curentMergeCellsArray = this.mergeCellsArray.map((cell) => JSON.parse(JSON.stringify(cell)));
            let indexLayer = curentMergeCellsArray.findIndex((layer) => layer.row === this.currentRow && layer.col === this.currentColl);

            if (indexLayer === -1) return;
            curentMergeCellsArray.splice(indexLayer, 1);
            this.mergeCellsArray = curentMergeCellsArray;
            this.$refs.myTable.hotInstance.updateSettings({
                mergeCells: curentMergeCellsArray,
            });
        },

        insertLayerStart(fastCreate = null) {
            /** Не добавляем другие слои, если текстовый слой не пустой */
            if (this.currentLayer && this.currentLayer.layersDescr?.length > 0 && this.currentLayer.layerType === 'text') {
                return;
            }

            if (fastCreate) {
                this.layerChange();
                this.onLayerSelectorHandler(this.currentLayer, false);
                this.focusOnLayer(this.currentLayer.layerType);
                this.startFormulaWriting();
                return;
            }
            this.titleLayerDialog = 'Добавление нового слоя';
            this.showLayerDialog = true;
        },

        editLayer(layer) {
            this.currentLayerId = layer.id;
            this.titleLayerDialog = 'Изменение слоя';
            this.editLayerStart = true;

            this.selectedLayerType = this.allLayerTypes.find((type) => type.layerType === layer.layerType);

            if (layer.layerType === 'type') {
                this.layerDescription = this.valueTypes.find((type) => type.label === layer.layersDescr);
            } else if (layer.layerType === 'aggregate') {
                this.layerDescription = this.aggregateTypes.find((type) => type.label === layer.layersDescr);
            } else if (layer.layerType === 'hint') {
                this.layerDescription = layer.layersDescr;
            } else {
                this.layerDescription = this.layers[layer.id].layersDescr;
            }
            this.showLayerDialog = true;
        },

        async layerChange() {
            if (this.$refs['descrArea']) this.layerDescription = this.$refs['descrArea'].textContent;

            //Если это редактирование слоя сохрани его изменения по индексу
            if (this.editLayerStart) {
                let curentLayers = this.dataFromTable[this.currentRow][this.currentColl].layers;
                let layerIndex = curentLayers.findIndex((layer) => layer.id === this.currentLayerId);
                this.dataFromTable[this.currentRow][this.currentColl].layers = curentLayers.map((layer) => {
                    if (layer.id === this.currentLayerId) {
                        if (['hint'].includes(this.selectedLayerType.layerType)) {
                            this.currentLayer.layersDescr = this.layerDescription;
                        }
                        if (['aggregate', 'type'].includes(this.selectedLayerType.layerType)) {
                            this.layerDescription = this.layerDescription.label;
                            this.currentLayer.layersDescr = this.layerDescription;
                        }
                        return {
                            layerType: this.selectedLayerType.layerType,
                            layersDescr: this.layerDescription,
                            hover: false,
                            id: curentLayers[layerIndex].id,
                        };
                    }
                    return layer;
                });
                this.updateData();
                this.closeLayerDialog();
                return;
            }
            //Если это создание слоя сгенерируй его и добавь в конец массива слоев ячейки
            if (['aggregate', 'type'].includes(this.selectedLayerType.layerType)) {
                this.layerDescription = this.layerDescription.label;
            }
            let newLayer = {
                layerType: this.selectedLayerType.layerType,
                layersDescr: this.layerDescription,
                hover: false,
                id: uuidv4(),
            };
            this.currentLayer = newLayer;
            this.dataFromTable[this.currentRow][this.currentColl].layers.push(newLayer);
            this.dataFromTable[this.currentRow][this.currentColl].className = ['header', 'blocked-header' ];
            this.dataFromTable[this.currentRow][this.currentColl].readOnly = true;
            this.updateData();
            this.closeLayerDialog();
        },

        closeLayerDialog() {
            this.selectedLayerType = null;
            this.layerDescription = null;
            this.titleLayerDialog = '';
            this.editLayerStart = false;
            this.showLayerDialog = false;
            this.currentLayerId = null;
        },

        showDeleteLayerPopup(layerId) {
            this.deleteCurentLayerShow = true;
            this.currentLayerId = layerId;
        },

        deleteLayer() {
            let curentLayers = this.dataFromTable[this.currentRow][this.currentColl].layers;
            this.dataFromTable[this.currentRow][this.currentColl].layers = curentLayers.filter((layer) => layer.id !== this.currentLayerId);
            const newSelectedLayer = this.filteredLayers[0];
            this.currentLayer = newSelectedLayer;
            this.currentLayerId = newSelectedLayer.id;

            this.formulaBuilding = false;

            this.updateData();
            this.closeDeleteLayer();
        },

        closeDeleteLayer() {
            this.deleteCurentLayerShow = false;
            this.currentLayerId = false;
        },

        archBehaviorShow() {
            let haveRules = this.visibilityLimitedRules.filter((rule) =>
                (rule.attributes.column === this.currentColl && this.currentColl >= 0)
                || (rule.attributes.row === this.currentRow && this.currentRow >= 0)
            );
            this.selectedPeriod = null;
            this.editableByVerifier = false;
            haveRules.forEach((rule) => {
                let period = this.allTemplatePeriods.find((period) => period.id === rule.relationships?.period.data?.id);
                if (period?.id) {
                    this.selectedPeriod = period?.id;
                }
                if (rule.attributes.rule === 'editable_by_verifier') {
                    this.editableByVerifier = rule.attributes.rule === 'editable_by_verifier';
                }
            });
            this.showBehaviorDialog = true;
        },

        behaviorChange(dataRule) {
            let attributes;
            if (this.currentRow === -1 && this.currentColl >= 0) {
                attributes = {
                    row: null,
                    column: this.currentColl,
                    rule: dataRule.rule,
                };
            }
            if (this.currentColl === -1 && this.currentRow >= 0) {
                attributes = {
                    row: this.currentRow,
                    column: null,
                    rule: dataRule.rule,
                };
            }

            this.showBehaviorDialog = false;
            if (dataRule.rule === 'viewAlways' && !dataRule.editOnly) {
                this.selectedPeriod = null;
                if (this.currentColl && this.currentColl >= 0) {
                    this.visibilityLimitedRules = this.visibilityLimitedRules.filter((visibilityRule) => visibilityRule.attributes.column !== this.currentColl);
                }
                if ((this.currentRow && this.currentRow >= 0) || (this.currentRow >= 0 && this.currentColl === 0)) {
                    this.visibilityLimitedRules = this.visibilityLimitedRules.filter((visibilityRule) => visibilityRule.attributes.row !== this.currentRow);
                }
            }

            if (dataRule.rule === 'visible_during_period') {
                if (this.currentColl && this.currentColl >= 0) {
                    this.visibilityLimitedRules = this.visibilityLimitedRules.filter((visibilityRule) => visibilityRule.attributes.column !== this.currentColl);
                }
                if (this.currentRow && this.currentRow >= 0) {
                    this.visibilityLimitedRules = this.visibilityLimitedRules.filter((visibilityRule) => visibilityRule.attributes.row !== this.currentRow);
                }
                this.visibilityLimitedRules.push({
                    type: 'form-template-visibility-rule',
                    id: uuidv4(),
                    attributes,
                    relationships: {
                        period: {
                            data: {
                                type: 'form-distribution-period',
                                id: dataRule.period,
                            },
                        },
                    },
                });
            }

            if (this.currentColl && this.currentColl >= 0) {
                const ruleId = this.visibilityLimitedRules.findIndex((visibilityRule) => visibilityRule.attributes.column === this.currentColl && visibilityRule.attributes.rule === 'editable_by_verifier');

                if (ruleId >= 0) {
                    this.visibilityLimitedRules.splice(ruleId, 1);
                }
            }

            if (this.currentRow && this.currentRow >= 0) {
                const ruleId = this.visibilityLimitedRules.findIndex((visibilityRule) => visibilityRule.attributes.row === this.currentRow && visibilityRule.attributes.rule === 'editable_by_verifier');

                if (ruleId >= 0) {
                    this.visibilityLimitedRules.splice(ruleId, 1);
                }
            }

            if (dataRule.editOnly) {
                this.visibilityLimitedRules.push({
                    type: 'form-template-visibility-rule',
                    id: uuidv4(),
                    attributes: {
                        row: attributes.row,
                        column: attributes.column,
                        rule: 'editable_by_verifier',
                    }
                });
            }

            this.currentRow = this.currentRow === -1 ? 0 : this.currentRow;
            this.currentColl = this.currentColl === -1 ? 0 : this.currentColl;
            this.endRowCurrentDiapazon = this.currentRow;
            this.endCollCurrentDiapazon = this.currentColl;
            this.updateData();
        },

        closeBehaviorDialog() {
            this.endRowCurrentDiapazon = this.currentRow;
            this.endCollCurrentDiapazon = this.currentColl;
            this.showBehaviorDialog = false;
            this.updateData();
        },

        insertRow(insertType) {
            let dataTable = this.dataFromTable;

            let newCell = {
                id: uuidv4(),
                head: false,
                value: ``,
                readOnly: false,
                className: [],
                layers: [
                    {
                        layerType: 'text',
                        layersDescr: '',
                        hover: false,
                        id: uuidv4(),
                    },
                ],
                attributes: {
                    sharedId: uuidv4(),
                    row: insertType === 'Above' ? this.currentRow : this.currentRow + 1,
                    column: null,
                    rowSpan: 1,
                    style: [],
                    columnSpan: 1,
                },
            };
            let newRow = [];
            dataTable[this.currentRow].map((item, i) => {
                newCell.id = uuidv4();
                newCell.attributes.sharedId = uuidv4();
                newCell.attributes.column = i;

                newRow.push(JSON.parse(JSON.stringify(newCell)));
            });

            if (insertType === 'Above') {
                //сдвиг индекса строки при вставке
                dataTable.map((row, rowIndex) => {
                    if (rowIndex >= this.currentRow) {
                        row.map((cell) => {
                            cell.attributes.row = cell.attributes.row + 1;
                        });
                    }
                });
                dataTable.splice(this.currentRow, 0, newRow);
            } else {
                dataTable.map((row, rowIndex) => {
                    if (rowIndex >= this.currentRow + 1) {
                        row.map((cell) => {
                            cell.attributes.row = cell.attributes.row + 1;
                        });
                    }
                });
                dataTable.splice(this.currentRow + 1, 0, newRow);
            }

            this.mergeCellsArray = this.mergeCellsArray.map((cell) => {
                if (cell.row >= this.currentRow) cell.row = cell.row + 1;
                return cell;
            });

            this.dataFromTable = dataTable;
            this.updateData();
            this.$refs.myTable.hotInstance.selectCell(this.currentRow, this.currentColl);

            this.showArrayMap();
        },

        insertCol(insertType) {
            let dataTable = this.dataFromTable;
            let newCell = {
                id: uuidv4(),
                head: false,
                value: ``,
                readOnly: false,
                className: [],
                layers: [
                    {
                        layerType: 'text',
                        layersDescr: '',
                        hover: false,
                        id: uuidv4(),
                    },
                ],
                attributes: {
                    sharedId: uuidv4(),
                    row: null,
                    column: insertType === 'left' ? this.currentColl : this.currentColl + 1,
                    rowSpan: 1,
                    style: [],
                    columnSpan: 1,
                },
            };
            if (insertType === 'left') {
                dataTable.forEach((rowArray, i) => {
                    newCell.id = uuidv4();
                    newCell.attributes.sharedId = uuidv4();
                    newCell.attributes.row = i;
                    //сдвиг индекса столбца при вставке
                    rowArray.forEach((cell) => {
                        if (cell.attributes.column >= this.currentColl) cell.attributes.column = cell.attributes.column + 1;
                    });
                    rowArray.splice(this.currentColl, 0, JSON.parse(JSON.stringify(newCell)));
                });
            } else {
                dataTable.forEach((rowArray, i) => {
                    newCell.id = uuidv4();
                    newCell.attributes.sharedId = uuidv4();
                    newCell.attributes.row = i;
                    //сдвиг индекса столбца при вставке
                    rowArray.forEach((cell) => {
                        if (cell.attributes.column >= this.currentColl + 1) cell.attributes.column = cell.attributes.column + 1;
                    });
                    rowArray.splice(this.currentColl + 1, 0, JSON.parse(JSON.stringify(newCell)));
                });
            }
            this.mergeCellsArray = this.mergeCellsArray.map((cell) => {
                if (cell.col >= this.currentColl) cell.col = cell.col + 1;
                return cell;
            });

            this.showArrayMap();
            this.updateData();
            this.$refs.myTable.hotInstance.selectCell(this.currentRow, this.currentColl);
        },

        deleteRow() {
            this.dataFromTable = this.dataFromTable.filter((row, i) => i !== this.currentRow);
            this.dataFromTable = this.dataFromTable.map((row) => {
                return row.map((cell) => {
                    if (cell.attributes.row > this.currentRow) cell.attributes.row = cell.attributes.row - 1 >= 0 ? cell.attributes.row - 1 : 0;
                    return cell;
                });
            });
            //очитсить массив мержей если какие то мержи начинались с удаленной строки
            this.mergeCellsArray = this.mergeCellsArray.filter((cell) => cell.row !== this.currentRow);
            this.currentRow = this.currentRow - 1 === -1 ? 0 : this.currentRow - 1;

            this.showArrayMap();
            this.updateData();
            this.$refs.myTable.hotInstance.selectCell(this.currentRow, this.currentColl);
        },
        deleteColl() {
            this.dataFromTable = this.dataFromTable.map((row) => {
                return row
                    .filter((cell, i) => i !== this.currentColl)
                    .map((cell) => {
                        if (cell.attributes.column > this.currentColl) cell.attributes.column = cell.attributes.column - 1 >= 0 ? cell.attributes.column - 1 : 0;
                        return cell;
                    });
            });

            //очитсить массив мержей если какие то мержи начинались с удаленного столбца
            this.mergeCellsArray = this.mergeCellsArray.filter((cell) => cell.col !== this.currentColl);
            this.currentColl = this.currentColl - 1 === -1 ? 0 : this.currentColl - 1;

            this.showArrayMap();
            this.updateData();
            this.$refs.myTable.hotInstance.selectCell(this.currentRow, this.currentColl);
        },

        showArrayMap() {
            let rowsMap = [];
            this.dataFromTable.forEach((row, rowIndex) => {
                row.forEach((cell) => {
                    rowsMap[rowIndex] = rowsMap[rowIndex] ? rowsMap[rowIndex] : [];
                    rowsMap[rowIndex].push(`${cell.attributes.row} ${cell.attributes.column}`);
                });
            });
        },

        async saveWidths() {
            if (!this.headerFixed) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Для сохранения ширины и высоты граф необходимо зафиксировать шапку',
                    life: '3200',
                });
                return;
            }

            for (let index = 0; index <= this.maxColNumber - 1; index++) {
                if (!this.colWidths[index]) {
                    this.colWidths[index] = 150;
                }
            }

            for (let index = 0; index <= this.maxRowNumber - 1; index++) {
                if (!this.rowHeights[index]) {
                    this.rowHeights[index] = 30;
                }
            }

            this.coordinates = this.matchingCoordinates(this.fixedRow, this.fixedColumn);

            const data = {
                data: {
                    id: this.currentWorcksheetId,
                    type: 'form-template-worksheet',
                    attributes: {
                        properties: {
                            fixedRowsTop: this.fixedRow,
                            fixedColumnsLeft: this.fixedColumn,
                            columnLabels: this.coordinates.columnLabels,
                            rowLabels: this.coordinates.rowLabels,
                            columnWidths: this.colWidths,
                            rowHeights: this.rowHeights
                        }
                    }
                },
            };

            try {
                await editWorksheet(this.currentWorcksheetId, data);
            } catch (error) {
                this.$requestError(error.message);
            }

            return;
        },

        async fixHeaders(payload, reload) {
            if (reload) {
                this.coordinates = this.matchingCoordinates(this.fixedRow, this.fixedColumn);

                const data = {
                    data: {
                        id: this.currentWorcksheetId,
                        type: 'form-template-worksheet',
                        attributes: {
                            properties: {
                                fixedRowsTop: payload ? this.fixedRow : 0,
                                fixedColumnsLeft: payload ? this.fixedColumn : 0,
                                columnLabels: payload ? this.coordinates.columnLabels : [],
                                rowLabels: payload ? this.coordinates.rowLabels : [],
                            }
                        }
                    },
                };
                if (this.colWidths.length > 0) {
                    for (let index = 0; index <= this.maxColNumber - 1; index++) {
                        if (!this.colWidths[index]) {
                            this.colWidths[index] = 150;
                        }
                    }
                    data.data.attributes.properties.columnWidths = this.colWidths;
                }

                if (this.rowHeights.length > 0) {
                    for (let index = 0; index <= this.maxRowNumber - 1; index++) {
                        if (!this.rowHeights[index]) {
                            this.rowHeights[index] = 30;
                        }
                    }
                    data.data.attributes.properties.rowHeights = this.rowHeights;
                }

                try {
                    await editWorksheet(this.currentWorcksheetId, data);
                } catch (error) {
                    this.$requestError(error.message);
                } finally {
                    this.$emit('loadingChange', false);
                }
                this.worksheetReload();
                return;
            }
            this.headerFixed = this.fixedRow > 0 || this.fixedColumn > 0;

            let settings = {
                fixedRowsTop: payload ? this.fixedRow : 0,
                fixedColumnsLeft: payload ? this.fixedColumn : 0,
            };

            if (this.rowHeights.length > 0) {
                settings.rowHeights = this.rowHeights;
                settings.autoRowSize = false;
            } else {
                settings.autoRowSize = { syncLimit: '40%', allowSampleDuplicates: true };
                settings.rowHeights = 30;
            }

            this.$refs.myTable.hotInstance.updateSettings(settings);
        },

        matchingCoordinates(fixedColumn, fixedRow) {
            let coordinates = {
                columnLabels: [],
                rowLabels: []
            };

            if (fixedColumn > 0 || fixedRow > 0) {
                try {
                    for (let row = fixedColumn; row <= this.maxRowNumber - 1; row++) {
                        if (this.transformData[row]) {
                            coordinates.rowLabels[row] = this.transformData[row][fixedRow - 1];
                        }
                    }
                    for (let column = fixedRow; column <= this.maxColNumber - 1; column++) {
                        if (this.transformData[fixedColumn - 1]) {
                            coordinates.columnLabels[column] = this.transformData[fixedColumn - 1][column];
                        }
                    }
                } catch (error) {
                    this.$requestError(error.message);
                }
            }

            return coordinates;
        },

        clearTableData() {
            this.endFormulaWriting();
            let copyData = this.dataFromTable;
            copyData.map((row) => {
                row.map((cell) => {
                    cell.layers[0].layersDescr = '';
                    cell.layers = [cell.layers[0]];
                });
            });
            this.dataFromTable = copyData;
            this.updateData();
        },

        async downloadChecksXLS() {
            try {
                this.$emit('loadingChange', true);
                const { data: { data: checkData, included: checkIncluded } } = await downloadCheckLayers(this.templateId);

                let worksheetData = {};
                let header = [
                    'Идентификатор проверки',
                    'Код ячейки',
                    'Идентификатор шаблона',
                    'Название листа',
                    'Графа',
                    'Строка',
                    'Проверка'
                ];

                if (checkData.length === 0) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Проверки не найдены.',
                        life: 2500,
                    });
                    return;
                }

                checkData?.forEach((check) => {
                    let cell = checkIncluded.find((item) => item.id === check.relationships.cell.data.id);
                    let worksheet = checkIncluded.find((item) => item.id === cell.relationships.worksheet.data.id);
                    let template = checkIncluded.find((item) => item.id === worksheet.relationships.template.data.id);

                    if (!worksheetData[worksheet.attributes.shortName]) {
                        worksheetData[worksheet.attributes.shortName] = [header];
                    }

                    worksheetData[worksheet.attributes.shortName].push([
                        check.id,
                        check.relationships.cell.data.id,
                        template.id,
                        worksheet.attributes.shortName,
                        worksheet.attributes.properties.columnLabels
                            ? worksheet.attributes.properties.columnLabels[cell.attributes.column-1] : cell.meta.coordinate[0],
                        worksheet.attributes.properties.rowLabels
                            ? worksheet.attributes.properties.rowLabels[cell.attributes.row-1] : cell.attributes.row,
                        convertLayer({
                            layer: check.attributes.value,
                            included: checkIncluded
                        })
                    ]);
                });

                let workbook = XLSX.utils.book_new();
                Object.entries(worksheetData).forEach(([sheetName, sheetData]) => {
                    let worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                    XLSX.utils.book_append_sheet(workbook, worksheet, `${ sheetName }`);
                })
                XLSX.writeFileXLSX(workbook, 'checks.xlsx');
            } catch(error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async downloadConstructorTemplateXLS(format = 'xlsx') {
            this.$emit('loadingChange', true);
            await downloadConstructorTemplateXLS(this.templateId, `${ this.formTitle }(${ this.templateTitle })`, format);
            this.$emit('loadingChange');
        },

        showUploadFileDialog() {
            this.uploadFileDialog = true;
        },

        async uploadXLSWorksheet() {
            this.$emit('loadingChange', true);
            try {
                await uploadConstructorXLS(this.templateId, this.uploadFile);
                window.location.reload();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }

            this.clearUpload();
        },

        onUpload(event) {
            const fD = new FormData();
            fD.append('file', event.files[0]);
            this.uploadFile = fD;
        },

        clearUpload() {
            this.uploadFileDialog = false;
            this.uploadFile = null;
        },

        cancelChanges() {
            this.validationErrorShow = false;
            this.currentLayer.layersDescr = this.previousValue;
            this.$refs.contentEditableDiv.innerHTML = this.previousValue;
            this.previousValue = null;
            this.endFormulaWriting();
        },

        blockUnblockCell(payload) {
            //Блок ячеек в диапазоне
            if (this.currentRow !== this.endRowCurrentDiapazon || this.currentColl !== this.endCollCurrentDiapazon) {
                if (this.currentRow === -1) this.currentRow = 0;
                if (this.currentColl === -1) this.currentColl = 0;
                this.dataFromTable.forEach((row, i) => {
                    if (i >= this.currentRow && i <= this.endRowCurrentDiapazon) {
                        row.forEach((cell, cellIndex) => {
                            if (cellIndex >= this.currentColl && cellIndex <= this.endCollCurrentDiapazon) {
                                this.dataFromTable[i][cellIndex].readOnly = payload;
                            }
                        });
                    }
                });
                this.endRowCurrentDiapazon = this.currentRow;
                this.endCollCurrentDiapazon = this.currentColl;
                this.$refs.myTable.hotInstance.selectCell(this.currentRow, this.currentColl);
                this.updateData();
                return;
            }

            this.dataFromTable[this.currentRow][this.currentColl].readOnly = payload;

            this.updateData();
        },

        showLayersCopyOrDeletePopup(payload) {
            this.selectedLayers = [];
            this.layersCopy = payload;
            if (payload === 'copy') {
                this.cellForAutoFillingCoordinates = {
                    row: this.currentRow,
                    col: this.currentColl
                };
            }
            this.layers.map((layer) => {
                if (!layer.needConvert && layer.layersDescr?.length && ['check', 'calc'].includes(layer.layerType)) {
                    layer.layersDescr = this.unconvertLayer(layer.layersDescr);
                    layer.needConvert = true;
                }
                return layer;
            });
            this.layersCopyOrDeleteShow = true;

            return Promise.resolve();
        },

        closeLayersCopyOrDeletePoup() {
            this.layersCopy = null;
            this.layersCopyOrDeleteShow = false;
            this.copyLayersArray = [];
            this.acceptedCopy = false;
            this.cellForAutoFillingCoordinates = null;
            this.$emit('rejectCopyDialog');
        },

        agregateLayers(agregatePayload) {
            switch (agregatePayload) {
                case 'cut':
                    this.copyLayers('cut');
                    break;
                case 'copy':
                    this.copyLayers('copy');
                    this.acceptedCopy = true;
                    break;
                case 'pas':
                    this.copyLayers('copy');
                    this.acceptedCopy = true;
                    break;
                case 'delete':
                    this.deleteLayers();
                    break;
            }
            this.$emit('resolveCopyDialog');
        },

        copyLayers(payload) {
            this.copyLayersArray = [];
            if (payload === 'copy') {
                this.copyLayersArray = this.selectedLayers.map((layer) => {
                    let newLayer = JSON.parse(JSON.stringify(layer));
                    newLayer.id = uuidv4();
                    return newLayer;
                });
            }
            if (payload === 'cut') {
                this.copyLayersArray = this.selectedLayers.map((layer) => {
                    let newLayer = JSON.parse(JSON.stringify(layer));
                    newLayer.id = uuidv4();
                    return newLayer;
                });
                this.deleteLayers();
            }

            this.layersCopyOrDeleteShow = false;
        },

        async toggleCellStyle(value) {
            // если выбран диапазон ячеек
            if (this.currentRow !== this.endRowCurrentDiapazon || this.currentColl !== this.endCollCurrentDiapazon) {
                if (this.currentRow === -1) this.currentRow = 0;
                if (this.currentColl === -1) this.currentColl = 0;

                for (let row = this.currentRow; row <= this.endRowCurrentDiapazon; row++) {
                    for (let col = this.currentColl; col <= this.endCollCurrentDiapazon; col++) {
                        let cell = this.dataFromTable[row][col];
                        cell.attributes.style = this.replaceStyle(cell, value);
                    }
                }

                this.setStyles();
                this.cellForAutoFillingCoordinates = null;
                return;
            }

            let cell = this.dataFromTable[this.currentRow][this.currentColl];
            cell.attributes.style = this.replaceStyle(cell, value);

            this.setStyles();
        },

        replaceStyle(cell, value) {
            if ('strikethrough' === value) {
                let styleIndex = cell.attributes.style.indexOf('underline');
                if (styleIndex >= 0) {
                    cell.attributes.style.splice(styleIndex, 1);
                }
            }

            if ('underline' === value) {
                let styleIndex = cell.attributes.style.indexOf('strikethrough');
                if (styleIndex >= 0) {
                    cell.attributes.style.splice(styleIndex, 1);
                }
            }

            if (value.includes('font-size:')) {
                let styleIndex = cell.attributes.style.findIndex((style) => style.includes('font-size:'));
                if (styleIndex >= 0) {
                    cell.attributes.style.splice(styleIndex, 1);
                }
            }

            if (value.includes('color:')) {
                let styleIndex = cell.attributes.style.findIndex((style) => style.includes('color:'));
                if (styleIndex >= 0) {
                    cell.attributes.style.splice(styleIndex, 1);
                }
            }

            if (value.includes('background:')) {
                let styleIndex = cell.attributes.style.findIndex((style) => style.includes('background:'));
                if (styleIndex >= 0) {
                    cell.attributes.style.splice(styleIndex, 1);
                }
            }

            let styleIndex = cell.attributes.style.indexOf(value);
            if (styleIndex >= 0) {
                cell.attributes.style.splice(styleIndex, 1);
            } else {
                cell.attributes.style.push(value);
            }

            return cell.attributes.style;
        },

        setStyles() {
            let hot = this.$refs.myTable?.hotInstance;
            let styleData = this.dataFromTable;
            let colorPalette = this.colorPalette;
            setTimeout(() => {
                hot?.updateSettings({
                    colWidths: this.colWidths,
                    fixedRowsTop: this.fixedRow,
                    fixedColumnsLeft: this.fixedColumn,
                    cells: function (row, col) {
                        let cell = null;
                        try {
                            cell = hot.getCell(row, col);
                        } catch(error) {
                            return;
                        }
                        if (styleData[row] && styleData[row][col]?.attributes.style?.length > 0 && cell?.style) {
                            cell.style.fontStyle = '';
                            cell.style.textDecoration = '';
                            cell.style.fontWeight = '';
                            /** Очистка от классов стилей **/
                            let classSet = [];
                            if (styleData[row][col].className.includes('header')) {
                                classSet.push('header');
                            }
                            if (styleData[row][col].className.includes('blocked-header')) {
                                classSet.push('blocked-header');
                            }
                            styleData[row][col].className = classSet;

                            styleData[row][col].attributes.style.forEach((styleParam) => {
                                /** Задать полужирный стиль */
                                if ('bold' === styleParam) {
                                    cell.style.fontWeight = styleParam;

                                    if (styleData[row][col].className?.length > 0) {
                                        styleData[row][col].className = [...styleData[row][col].className, 'bold-style'];
                                    } else {
                                        styleData[row][col].className = ['bold-style'];
                                    }
                                }

                                /** Задать Курсив */
                                if ('italic' === styleParam) {
                                    cell.style.fontStyle = styleParam;

                                    if (styleData[row][col].className?.length > 0) {
                                        styleData[row][col].className = [...styleData[row][col].className, 'italic-style'];
                                    } else {
                                        styleData[row][col].className = ['italic-style'];
                                    }
                                }

                                /** Задать зачёркнутый стиль */
                                if ('strikethrough' === styleParam) {
                                    cell.style.textDecoration = 'line-through';

                                    if (styleData[row][col].className?.length > 0) {
                                        styleData[row][col].className = [...styleData[row][col].className, 'strikethrough-style'];
                                    } else {
                                        styleData[row][col].className = ['strikethrough-style'];
                                    }
                                }

                                /** Задать подчёркнутый стиль */
                                if ('underline' === styleParam) {
                                    cell.style.textDecoration = styleParam;

                                    if (styleData[row][col].className?.length > 0) {
                                        styleData[row][col].className = [...styleData[row][col].className, 'underline-style'];
                                    } else {
                                        styleData[row][col].className = ['underline-style'];
                                    }
                                }

                                /** Задать цвет фона ячейки */
                                if (styleParam.includes('background:')) {
                                    let colorArray = styleParam.split('background:');
                                    let color = colorPalette.find((color) => color.code === colorArray[1]);
                                    if (styleData[row][col].readOnly) {
                                        if (styleData[row][col].className === 'blocked-header') {
                                            cell.style.backgroundColor = `#${color.calculated}`;
                                        } else {
                                            cell.style.backgroundColor = `#${color.blocked}`;
                                        }
                                    } else {
                                        cell.style.backgroundColor = `#${color.background}`;
                                    }

                                    if (styleData[row][col].className?.length > 0) {
                                        styleData[row][col].className = [...styleData[row][col].className, `${color.code}-background-style`];
                                    } else {
                                        styleData[row][col].className = [`${color.code}-background-style`];
                                    }
                                }

                                /** Задать цвет текста ячейки */
                                if (styleParam.includes('color:')) {
                                    let colorArray = styleParam.split('color:');
                                    let color = colorPalette.find((color) => color.code === colorArray[1]);
                                    cell.style.color = `#${color.font}`;

                                    if (styleData[row][col].className?.length > 0) {
                                        styleData[row][col].className = [...styleData[row][col].className, `${color.code}-color-style`];
                                    } else {
                                        styleData[row][col].className = [`${color.code}-color-style`];
                                    }
                                }

                                /** Задать шрифт ячейки */
                                if (styleParam.includes('font-size:')) {
                                    let sizeArray = styleParam.split('font-size:');
                                    cell.style.fontSize = sizeArray[1];

                                    if (styleData[row][col].className?.length > 0) {
                                        styleData[row][col].className = [...styleData[row][col].className, `style-${sizeArray[1]}`];
                                    } else {
                                        styleData[row][col].className = [`style-${sizeArray[1]}`];
                                    }
                                }
                            });
                        }
                    }
                });
            }, 50);
            this.updateData();
        },

        clearCellStyle() {
            // если диапазон ячеек
            if (this.currentRow !== this.endRowCurrentDiapazon || this.currentColl !== this.endCollCurrentDiapazon) {
                if (this.currentRow === -1) this.currentRow = 0;
                if (this.currentColl === -1) this.currentColl = 0;

                for (let row = this.currentRow; row <= this.endRowCurrentDiapazon; row++) {
                    for (let col = this.currentColl; col <= this.endCollCurrentDiapazon; col++) {
                        let cell = this.dataFromTable[row][col];
                        cell.attributes.style = [];
                    }
                }

                this.clearStyles();
                this.cellForAutoFillingCoordinates = null;
                return;
            }

            let cell = this.dataFromTable[this.currentRow][this.currentColl];
            cell.attributes.style = [];

            this.clearStyles();
        },

        clearStyles() {
            let hot = this.$refs.myTable?.hotInstance;
            let styleData = this.dataFromTable;
            setTimeout(() => {
                hot?.updateSettings({
                    colWidths: this.colWidths,
                    rowHeights: this.rowHeights,
                    fixedRowsTop: this.fixedRow,
                    fixedColumnsLeft: this.fixedColumn,
                    cells: function (row, col) {
                        let cell = null;
                        try {
                            cell = hot.getCell(row, col);
                        } catch(error) {
                            return;
                        }
                        if (styleData[row][col].attributes.style.length === 0 && cell?.style) {
                            cell.style.fontStyle = '';
                            cell.style.textDecoration = '';
                            cell.style.fontWeight = '';
                            cell.style.backgroundColor = '';
                            cell.style.color = '';
                            cell.style.fontSize = '';

                            /** Очистка от классов стилей **/
                            let classSet = [];
                            if (styleData[row][col].className.includes('header')) {
                                classSet.push('header');
                            }
                            if (styleData[row][col].className.includes('blocked-header')) {
                                classSet.push('blocked-header');
                            }
                            styleData[row][col].className = classSet;
                        }
                    }
                });
            }, 50);
            this.updateData();
        },

        async pasteLayers() {
            let textLayerIndex = this.copyLayersArray.findIndex((layer) => layer.layerType === 'text');
            let textLayer = textLayerIndex >= 0 ? this.copyLayersArray.splice(textLayerIndex, 1) : [];
            if (this.currentRow !== this.endRowCurrentDiapazon || this.currentColl !== this.endCollCurrentDiapazon) {
                if (this.currentRow === -1) this.currentRow = 0;
                if (this.currentColl === -1) this.currentColl = 0;
                // получение координат выбранной ячейки для копирования при протяжке
                const { row: cellForAutofillRow, col: cellForAutofillCol } = this.cellForAutoFillingCoordinates || {};
                const cellCoordinatesString = JSON.stringify([cellForAutofillRow, cellForAutofillCol]);

                for (let row = this.currentRow; row <= this.endRowCurrentDiapazon; row++) {
                    for (let col = this.currentColl; col <= this.endCollCurrentDiapazon; col++) {
                        const cell = this.dataFromTable[row][col];
                        if (textLayerIndex >= 0) {
                            if (this.copyLayersArray?.length === 0) {
                                cell.layers = [JSON.parse(JSON.stringify(textLayer[0]))];
                                if (cell.layers[0]?.layersDescr.length > 0) {
                                    cell.className = [ 'header', 'blocked-header' ];
                                    cell.readOnly = true;
                                }
                                continue;
                            } else {
                                cell.layers[0] = JSON.parse(JSON.stringify(textLayer[0]));
                            }
                        }
                        if (cell.layers[0].layersDescr?.length > 0) {
                            continue;
                        }

                        if (cellForAutofillCol !== undefined && JSON.stringify([row, col]) !== cellCoordinatesString) {
                            let localCopyLayersArray = this.checkLayers(row, col);
                            for (const layer of localCopyLayersArray) {
                                const alreadyIncludedIdx = cell.layers.findIndex(l => l.layerType === layer.layerType);
                                if (~alreadyIncludedIdx && layer.layerType !== 'check') {
                                    cell.layers[alreadyIncludedIdx] = layer;
                                } else {
                                    cell.layers.push(layer);
                                }
                            }
                        }
                    }
                }
                this.copyLayersArray = [];
                this.selectedLayers = [];
                this.updateData();
                this.cellForAutoFillingCoordinates = null;
                return;
            }

            //если есть текстовый слой замени им текстовый слой  ячейки
            let localCopyLayersArray = this.checkLayers(this.currentRow, this.currentColl);
            if (textLayerIndex >= 0) {
                if (Object.keys(localCopyLayersArray).length > 0) {
                    this.dataFromTable[this.currentRow][this.currentColl].layers[0] = JSON.parse(JSON.stringify(textLayer[0]));
                } else {
                    this.dataFromTable[this.currentRow][this.currentColl].layers = [JSON.parse(JSON.stringify(textLayer[0]))];
                }
                this.dataFromTable[this.currentRow][this.currentColl].className = [...this.dataFromTable[this.currentRow][this.currentColl].className, 'blocked-header'];
            }

            if (Object.keys(localCopyLayersArray).length > 0) {
                if (this.dataFromTable[this.currentRow][this.currentColl].layers) {
                    this.dataFromTable[this.currentRow][this.currentColl].layers = [
                        ...this.dataFromTable[this.currentRow][this.currentColl].layers,
                        ...localCopyLayersArray.map((layer) => {
                            return JSON.parse(JSON.stringify(layer));
                        }),
                    ];
                } else {
                    this.dataFromTable[this.currentRow][this.currentColl].layers = localCopyLayersArray.map((layer) => {
                        return JSON.parse(JSON.stringify(layer));
                    });
                }
            }

            this.updateData();
            this.copyLayersArray = [];
            this.selectedLayers = [];
        },

        checkLayers(currentRow, currentColl) {
            let localCopyLayersArray = JSON.parse(JSON.stringify(this.copyLayersArray));
            const { row: cellRow, col: cellCol } = this.cellForAutoFillingCoordinates || {};

            for (let i = 0; i < localCopyLayersArray.length; i++) {
                let currentLayer = localCopyLayersArray[i];
                if (['calc', 'aggregate', 'type', 'check'].includes(currentLayer.layerType)) {
                    const rowOffset = currentRow - cellRow, colOffset = currentColl - cellCol;
                    let result = this.incrementFormulaElements({
                        htmlString: currentLayer.layersDescr,
                        rowOffset,
                        colOffset,
                        maxRowNumber: this.maxRowNumber,
                        maxColNumber: this.maxColNumber,
                        worksheetTitle: this.currentWorksheetTitle,
                        dataFromTable: this.dataFromTable,
                        hot: this.$refs.myTable.hotInstance,
                        coordinates: this.coordinates,
                        worksheetId: this.currentWorcksheetId,
                        cellData: this.cellIncluded
                    });
                    currentLayer.layersDescr = result.html;

                    if (result.errors?.length > 0) {
                        for (let error of result.errors) {
                            this.$toast.add({
                                severity: 'error',
                                summary: error,
                                life: '7500',
                            });
                        }
                    }

                    currentLayer.id = uuidv4();
                    localCopyLayersArray[i] = currentLayer;
                }
            }
            return localCopyLayersArray;
        },

        incrementFormulaElements({
                 htmlString,
                 rowOffset,
                 colOffset,
                 maxRowNumber,
                 maxColNumber,
                 worksheetTitle,
                 dataFromTable,
                 hot,
                 coordinates,
                 worksheetId,
                 cellData
             }) {
            let errors = [];
            const div = document.createElement('div');
            div.innerHTML = htmlString.trim();
            for (let i of div.childNodes) {
                if (i.attributes) {
                    // проверка на ячейку из другой формы/листа
                    const sharedId = i.attributes['data-sharedid'].value;
                    let sharedCell = cellData.formTemplateCells.find((cell) => cell.attributes.sharedId === sharedId);
                    let sharedCellId, sharedCellWorksheet, sharedCellTemplate, sharedCellForm, sharedRow, sharedColumn;
                    if (sharedCell) {
                        sharedCellWorksheet = cellData.formTemplateWorksheets.find((worksheet) => worksheet.id === sharedCell.relationships.worksheet.data.id);
                        if (sharedCellWorksheet && worksheetId !== sharedCellWorksheet?.id) {
                            sharedCellTemplate = cellData.formTemplates.find((template) => template.id === sharedCellWorksheet.relationships?.template.data.id);
                            sharedCellForm = cellData.forms.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);
                            sharedRow = sharedCell.attributes.row + rowOffset;
                            sharedColumn = (sharedCell.attributes.column - 1) + colOffset;
                            if (this.otherFormCells[sharedCellWorksheet.id]) {
                                let otherSharedCell = this.otherFormCells[sharedCellWorksheet.id].find(cell => cell.attributes.row === sharedRow && cell.attributes.column === sharedColumn + 1);
                                if (otherSharedCell) {
                                    sharedCell = otherSharedCell;
                                    sharedCellId = sharedCell.attributes.sharedId;
                                }
                            // если ячейка другого листа текущей формы
                            } else {
                                let otherSharedCell = cellData.formTemplateCells.find(cell => cell.attributes.row === sharedRow && cell.attributes.column === sharedColumn + 1);
                                if (otherSharedCell) {
                                    sharedCell = otherSharedCell;
                                    sharedCellId = sharedCell.attributes.sharedId;
                                }
                            }
                            if (!sharedCellWorksheet.attributes.properties?.rowLabels[sharedRow-1]
                                || !sharedCellWorksheet.attributes.properties?.columnLabels[sharedColumn]) {
                                errors.push(`Ячейка при протяжке выходит за пределы листа, либо шапка листа не зафиксирована: ${ sharedCellForm.attributes.name }(${ sharedCellTemplate.attributes.name }): '${ sharedCellWorksheet.attributes.name }'!${ sharedCell.meta.coordinate }. Проверьте формулу.`);

                                return { html: null, errors };
                            }
                        }
                    }

                    const elemDataRow = Number(i.attributes['data-row'].value)
                    const elemDataCol = Number(i.attributes['data-col'].value)
                    const resultRowVal = elemDataRow + rowOffset;
                    const resultColVal = elemDataCol + colOffset;
                    const row = sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id
                        ? sharedRow-1 : getRowOrColValue(resultRowVal, maxRowNumber - 1);
                    const column = sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id
                        ? sharedColumn : getRowOrColValue(resultColVal, maxColNumber - 1);
                    const targetSharedId = sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id
                        ? sharedCellId : dataFromTable[row][column].attributes.sharedId;
                    const newElHtml = createCellLayout({
                        sharedId: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? sharedCellId : dataFromTable[row][column].attributes.sharedId,
                        row,
                        column,
                        worksheetTitle: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? sharedCellWorksheet?.attributes.name : worksheetTitle,
                        templateTitle: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? sharedCellTemplate?.attributes.name : null,
                        formTitle: sharedCellWorksheet?.id && worksheetId !== sharedCellWorksheet?.id ? sharedCellForm?.attributes.name : null,
                        hot,
                        coordinates: sharedCellWorksheet?.id ? {
                            rowLabels: sharedCellWorksheet.attributes.properties?.rowLabels || [],
                            columnLabels: sharedCellWorksheet.attributes.properties?.columnLabels || []
                        } : coordinates
                    })
                    const rootForNewEl = document.createElement('div')
                    rootForNewEl.innerHTML = newElHtml.trim()
                    const newEl = rootForNewEl.firstChild
                    i.setAttribute('data-sharedid', targetSharedId);
                    i.setAttribute('data-row', row);
                    i.setAttribute('data-col', column);
                    i.innerHTML = newEl.innerHTML
                }
            }
            return { html: div.innerHTML, errors };
        },

        ///Эти 2 метода обходят дефолтное автозаполнение таблицы при отсутсвии текстовго слоя в массиве слоев для копирования
        collectTextLayers() {
            let textLayerIndex = this.copyLayersArray.findIndex((layer) => layer.layerType === 'text');
            if (textLayerIndex >= 0) return;
            if (this.currentRow !== this.endRowCurrentDiapazon || this.currentColl !== this.endCollCurrentDiapazon) {
                this.dataFromTable.forEach((row, i) => {
                    if (i >= this.currentRow && i <= this.endRowCurrentDiapazon) {
                        row.forEach((cell, cellIndex) => {
                            if (cellIndex >= this.currentColl && cellIndex <= this.endCollCurrentDiapazon) {
                                if (textLayerIndex === -1) this.reserveTextLayers.push(this.dataFromTable[i][cellIndex].layers[0].layersDescr);
                            }
                        });
                    }
                });
            }
        },

        insertTextLayers() {
            let layerCounter = 0;
            this.dataFromTable.forEach((row, i) => {
                if (i >= this.currentRow && i <= this.endRowCurrentDiapazon) {
                    row.forEach((cell, cellIndex) => {
                        if (cellIndex >= this.currentColl && cellIndex <= this.endCollCurrentDiapazon) {
                            this.dataFromTable[i][cellIndex].layers[0].layersDescr = this.reserveTextLayers[layerCounter];
                            if (this.reserveTextLayers[layerCounter]?.length > 0) {
                                this.dataFromTable[i][cellIndex].className = [ 'header', 'blocked-header' ];
                                this.dataFromTable[i][cellIndex].readOnly = true;
                            } else {
                                this.dataFromTable[i][cellIndex].className = [];
                                this.dataFromTable[i][cellIndex].readOnly = false;
                            }
                            layerCounter++;
                        }
                    });
                }
            });
            this.reserveTextLayers = [];
        },

        deleteLayers() {
            let cellLayersArr = this.dataFromTable[this.currentRow][this.currentColl].layers;
            let resultLayersArr = cellLayersArr.filter((layer) =>
                this.selectedLayers.findIndex((delLayer) => delLayer.id === layer.id) === -1
            );
            if (resultLayersArr.filter((layer) => layer.layerType === 'text').length === 0) {
                resultLayersArr.unshift({
                    layerType: 'text',
                    layersDescr: '',
                    hover: false,
                    id: uuidv4(),
                });
                this.dataFromTable[this.currentRow][this.currentColl].className = [''];
                this.dataFromTable[this.currentRow][this.currentColl].readOnly = false;
            }

            this.dataFromTable[this.currentRow][this.currentColl].layers = resultLayersArr;
            this.selectedLayers = [];
            this.layersCopy = null;
            this.layersCopyOrDeleteShow = false;
            setTimeout(() => {
                this.updateData();
            }, 200);
        },

        layerPlaceholder(layerType) {
            return this.allLayerTypes.find(i => i.layerType === layerType)?.layerTitle;
        },

        async getAllTemplateData() {
            try {
                this.$emit('loadingChange', true);
                const { data, included } = await getTemplateData(this.templateId);
                this.templateTitle = data.attributes.name;
                this.templateYear = data.attributes.year;

                const interval = included.find((item) => item.type === 'form-distribution-interval');
                this.formDistributionInterval = interval.id;

                const form = included.find((include) => include.type === 'form');

                this.selectedForm = {
                    code: form.id,
                    name: form.attributes.name,
                    distributionIntervalId: form.relationships.distributionInterval.data?.id
                };
                this.formTitle = form.attributes.name;
                if (data.relationships.worksheets.data.length === 0) {
                    return await this.createWorksheet({
                        name: '1000',
                        shortName: '1000',
                        sort: 0,
                        code: nanoid(),
                        columnCount: 1,
                        rowCount: 1,
                        groupId: null
                    });
                }
                included
                    ? (this.groups = this.agregateGroups(included))
                    : (this.groups = [
                        {
                            id: 'noId',
                            header: 'Листы без группы',
                            lists: [],
                            color: '',
                            sort: -1,
                        },
                    ]);

                const { id: distributionIntervalId } = included.find((include) => include.type === 'form-distribution-interval');

                const { data: allIntervalPeriods } = await getAllIntervalPeriods({
                    intervalId: distributionIntervalId,
                    filter: {
                        active: {
                            '$eq': true
                        }
                    }
                });
                this.allTemplatePeriods = allIntervalPeriods;
                if (!this.currentWorcksheetId) {
                    //если ранее воркшит не был загружен загрузи лист с наименьшей сортировкой
                    const workSheets = included.filter((include) => include.type === 'form-template-worksheet').sort((a, b) => (a.attributes.sort > b.attributes.sort ? 1 : -1));

                    await this.listSelect({ listId: workSheets[0].id, listTitle: workSheets[0].attributes.name });
                }
                this.showTable = true;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },

        agregateGroups(included) {
            let allWorksheets = [];

            let groups = [];
            included.forEach((include) => {
                if (include.type === 'form-template-worksheet') {
                    let groupId = include.relationships.group.data?.id;

                    if (groupId) {
                        if (!allWorksheets[groupId]) {
                            allWorksheets[groupId] = [];
                        }

                        allWorksheets[groupId].push(include);
                    } else {
                        if (!allWorksheets['noGroup']) {
                            allWorksheets['noGroup'] = [];
                        }

                        allWorksheets['noGroup'].push(include);
                    }
                }
                if (include.type === 'form-group') {
                    groups.push(include);
                }
            });

            let allGroups = [];
            groups.forEach((group) => {
                if (allWorksheets[group.id]) {
                    allGroups.push({
                        ...group.attributes,
                        id: group.id,
                        header: group.attributes.name,
                        lists: allWorksheets[group.id].map((worksheet) => {
                            return {
                                name: worksheet.attributes.name,
                                shortName: worksheet.attributes.shortName,
                                code: worksheet.attributes.code,
                                sort: worksheet.attributes.sort,
                                id: worksheet.id,
                            };
                        }),
                        color: group.color || '',
                    });
                } else {
                    if (group.attributes.active) {
                        allGroups.push({
                            ...group.attributes,
                            id: group.id,
                            header: group.attributes.name,
                            lists: [],
                            color: group.color || '',
                        });
                    }
                }
            });

            if (allWorksheets['noGroup']) {
                allGroups.unshift({
                    id: 'noId',
                    header: 'Листы без группы',
                    lists: allWorksheets['noGroup'].map((worksheet) => {
                            return {
                                name: worksheet.attributes.name,
                                shortName: worksheet.attributes.shortName,
                                code: worksheet.attributes.code,
                                sort: worksheet.attributes.sort,
                                id: worksheet.id,
                            };
                        })
                        .sort((a, b) => (a.sort > b.sort ? 1 : -1)),
                    color: '',
                    sort: -1,
                });
            }

            return allGroups.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        },

        async dragWorksheet({ worksheetId, groupId }) {
            this.$emit('loadingChange', true);
            try {
                let dataToServer = {
                    data: {
                        type: 'form-template-worksheet',
                        id: worksheetId,
                        relationships: {
                            group: {
                                data: null
                            }
                        }
                    }
                };
                if (groupId) {
                    dataToServer.data.relationships = {
                        group: {
                            data: {
                                type: 'form-group',
                                id: groupId,
                            },
                        }
                    };
                }
                const result = await editWorksheet(worksheetId, dataToServer);

                if (result) {
                    this.getAllTemplateData();
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async createWorksheet(worksheet, skipCheck) {
            try {
                if (this.startDataFromTable && this.dataFromTable && !skipCheck) {
                    if (this.tableHaveChange || haveDifference(this.startDataFromTable, this.dataFromTable)) {
                        this.newListData = {
                            groupId: worksheet.groupId,
                            name: worksheet.name,
                            sort: worksheet.sort,
                            columnCount: worksheet.columnCount,
                            rowCount: worksheet.rowCount
                        };
                        this.leaveDialog = true;
                        return;
                    }
                }
                this.currentWorksheetVersion = null;
                this.newListData = null;
                this.mergeCellsArray = [];
                this.visibilityLimitedRules = [];
                this.currentWorcksheetId = null;
                this.headerFixed = false;
                const dataToServer = {
                    data: {
                        type: 'form-template-worksheet',
                        id: null,
                        attributes: {
                            name: worksheet.name,
                            shortName: worksheet.shortName,
                            code: worksheet.code,
                            sort: worksheet.sort || 0,
                            version: '',
                        },
                        relationships: {
                            template: {
                                data: {
                                    type: 'form-template',
                                    id: this.templateId,
                                },
                            },
                        },
                    },
                };
                if (worksheet.groupId && worksheet.groupId !== 'noId') {
                    dataToServer.data.relationships.group = {
                        data: {
                            type: 'form-group',
                            id: worksheet.groupId,
                        }
                    };
                }

                this.$emit('loadingChange', true);

                const {
                    data: { id: newWorkshetId, attributes },
                } = await createWorkSheet(this.templateId, dataToServer);
                this.currentWorksheetTitle = attributes.name;
                this.currentWorcksheetId = newWorkshetId;
                const newWorksheetCells = await this.createNewWorkSheetCells(worksheet.columnCount, worksheet.rowCount);
                await this.saveTemplate(null, newWorksheetCells);

                if (newWorkshetId) await this.getAllTemplateData();

            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        createNewWorkSheetCells(colCount, rowCount) {
            let cellsArray = [];
            for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
                let row = [];
                for (let index = 0; index < colCount; index++) {
                    const newCell = {
                        type: 'form-template-cell',
                        id: uuidv4(),
                        attributes: {
                            sharedId: uuidv4(),
                            row: rowIndex,
                            column: index,
                            rowSpan: 1,
                            columnSpan: 1,
                            value: '',
                            editable: true,
                        },
                        layers: [
                            {
                                layerType: 'text',
                                layersDescr: '',
                                hover: false,
                                id: uuidv4(),
                            },
                        ],
                    };
                    row.push(newCell);
                }
                cellsArray.push(row);
            }
            return cellsArray;
        },

        async editWorksheet({ id: workSheetId, attributes, relationships }) {
            this.$emit('loadingChange', true);
            const dataToServer = {
                data: {
                    ...attributes,
                    relationships: {
                        template: {
                            data: {
                                type: 'form-template',
                                id: this.templateId,
                            },
                        }
                    },
                },
            };
            if (relationships.group.id) {
                dataToServer.data.relationships.group = relationships.group;
            }

            try {
                const {
                    data: { data },
                } = await editWorksheet(workSheetId, dataToServer);
                if (this.currentWorcksheetId === data.id) this.currentWorksheetTitle = data.attributes.name;

                if (data) this.getAllTemplateData();
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        async deleteCurWorkSheet(workSheetId) {
            this.$emit('loadingChange', true);
            try {
                if (this.currentWorcksheetId === workSheetId) this.currentWorcksheetId = null;
                const result = await deleteWorkSheet(workSheetId);
                // this.showTable = false;
                this.mergeCellsArray = [];
                if (result) this.getAllTemplateData();
            } catch (error) {
                this.$requestError(error.message);
                this.$emit('loadingChange', false);
            }
        },

        async listSelect({ listId, listTitle }, skipCheck) {
            if (this.startDataFromTable && this.dataFromTable && !skipCheck) {
                if (this.tableHaveChange || haveDifference(this.startDataFromTable, this.dataFromTable)) {
                    this.listData = { listId, listTitle };
                    this.leaveDialog = true;
                    return;
                }
            }
            if (listId !== this.currentWorcksheetId) {
                this.currentLayer = null;
                this.previousValue = null;
            }
            this.leaveDialog = false;
            this.listData = null;
            this.showTable = false;
            this.$emit('loadingChange', true);
            this.endFormulaWriting();
            this.currentRow = 0;
            this.currentColl = 0;
            this.mergeCellsArray = [];
            this.visibilityLimitedRules = [];
            this.startDataFromTable = null;
            this.dataFromTable = mockDataTableDataArray;
            this.updateData();

            this.currentWorksheetTitle = '';
            this.hoverCellCoords.row = null;
            this.hoverCellCoords.col = null;

            this.currentWorksheetVersion = null;

            this.tableHaveChange = false;
            try {
                this.currentWorcksheetId = listId;
                this.fixedRow = 0;
                this.fixedColumn = 0;

                await Promise.all([
                    getWorkSheetCells(listId).then(({ data: cellArray, included }) => {
                        let worksheet = included.find((item) => item.type === 'form-template-worksheet');
                        this.currentWorksheetVersion = worksheet?.attributes.version;
                        if (worksheet.attributes.properties.fixedColumnsLeft > 0 || worksheet.attributes.properties.fixedRowsTop > 0) {
                            this.fixedRow = worksheet.attributes.properties.fixedRowsTop;
                            this.fixedColumn = worksheet.attributes.properties.fixedColumnsLeft;
                            this.coordinates = {
                                columnLabels: worksheet.attributes.properties.columnLabels ? worksheet.attributes.properties.columnLabels : [],
                                rowLabels: worksheet.attributes.properties.rowLabels ? worksheet.attributes.properties.rowLabels : []
                            };
                        } else {
                            this.coordinates = {
                                columnLabels: [],
                                rowLabels: []
                            };
                            this.fixedRow = 0;
                            this.fixedColumn = 0;
                        }

                        this.colWidths = [];
                        this.rowHeights = [];
                        if (worksheet.attributes?.properties.columnWidths?.length > 0) {
                            this.colWidths = worksheet.attributes.properties.columnWidths;
                        }

                        if (worksheet.attributes?.properties.rowHeights?.length > 0) {
                            this.rowHeights = worksheet.attributes.properties.rowHeights;
                        }

                        if (cellArray.length) {
                            this.dataFromTable = this.agregateCells(cellArray, included);
                            this.cellArray = cellArray;
                            included.forEach((curItem) => {
                                if (curItem.type === 'form') this.cellIncluded.forms.push(curItem);
                                if (curItem.type === 'form-template') this.cellIncluded.formTemplates.push(curItem);
                                if (curItem.type === 'form-template-worksheet') this.cellIncluded.formTemplateWorksheets.push(curItem);
                                if (curItem.type === 'form-template-cell') this.cellIncluded.formTemplateCells.push(curItem);
                            });

                            this.startDataFromTable = deepArrayCopy(this.dataFromTable);
                        }
                    }),

                    getVisibilityRules(listId).then((visibilityRulesArray) => {
                        this.visibilityLimitedRules = visibilityRulesArray.map((rule) => {
                            rule.attributes['row'] = rule.attributes.row >= 0 ? rule.attributes.row - 1 : rule.attributes.row;
                            rule.attributes['column'] = rule.attributes.column >= 0 ? rule.attributes.column - 1 : rule.attributes.column;

                            return rule;
                        });
                    }),
                    getWorkSheetVersions(listId).then(({ data: { data: userData, included: versionIncluded } }) => {
                        this.worksheetHistory = this.agregateHistory(userData, versionIncluded);
                    }),
                ]);
                if (this.cellArray.length) this.showArrayMap();
                this.currentWorksheetTitle = listTitle;
                this.currentWorcksheetId = listId;

                if (this.colWidths?.length <= 0) {
                    for (let index = 0; index <= this.maxColNumber - 1; index++) {
                        if (!this.colWidths[index]) {
                            this.colWidths[index] = 150;
                        }
                    }
                }

                this.setStyles();

                await this.fixHeaders(true, false);
                this.showTable = true;
                setTimeout(() => {
                    this.$refs.myTable?.hotInstance?.render();
                }, 100);
            } catch (error) {
                console.log(error);
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
            this.currentWorksheetTitle = listTitle;

            for (const worksheet of this.cellIncluded.formTemplateWorksheets) {
                //if ((this.otherFormCells && this.otherFormCells[worksheet.id]?.length > 0)) {
                    try {
                        const {data: cellArray } = await getWorkSheetCells(worksheet.id);
                        this.otherFormCells[worksheet.id] = cellArray;
                        /*if (cellArray) this.cellIncluded.formTemplateCells = this.cellIncluded.formTemplateCells.concat(cellArray);
                        included.forEach((curItem) => {
                            if (curItem.type === 'form') this.cellIncluded.forms.push(curItem);
                            if (curItem.type === 'form-template') this.cellIncluded.formTemplates.push(curItem);
                            if (curItem.type === 'form-template-worksheet') this.cellIncluded.formTemplateWorksheets.push(curItem);
                            if (curItem.type === 'form-template-cell') this.cellIncluded.formTemplateCells.push(curItem);
                        });*/
                    } catch (error) {
                        this.$requestError(error.message);
                    }
               // }
            }
        },

        agregateCells(cellArray, included) {
            const modCellArray = cellArray
                .map((cell) => {
                    cell.attributes.row = cell.attributes.row - 1;
                    cell.attributes.column = cell.attributes.column - 1;
                    return cell;
                })
                .sort((a, b) => a.attributes.row - b.attributes.row);
            let rowsArray = [];

            const separateIncluded = {
                forms: [],
                formTemplates: [],
                formTemplateWorksheets: [],
                formTemplateCells: [],
            };

            included.forEach((curItem) => {
                if (curItem.type === 'form') separateIncluded.forms.push(curItem);
                if (curItem.type === 'form-template') separateIncluded.formTemplates.push(curItem);
                if (curItem.type === 'form-template-worksheet') separateIncluded.formTemplateWorksheets.push(curItem);
                if (curItem.type === 'form-template-cell') separateIncluded.formTemplateCells.push(curItem);
            });
            for (let index = 0; index <= modCellArray[modCellArray.length - 1]?.attributes.row; index++) {
                let row = [];

                cellArray.forEach((cell) => {
                    const cellLayers = cell.relationships.layers.data.map((layer) => {
                        let curentlLayer = included.find((item) => item.id === layer.id);

                        let layerValue = curentlLayer.attributes.value;

                        if (['aggregate', 'type'].includes(curentlLayer.attributes.type)) {
                            layerValue = this.convertTypeOrAggregateLayer(layerValue, false);
                        }

                        return {
                            layerType: curentlLayer.attributes.type,
                            layersDescr: layerValue,
                            hover: false,
                            id: curentlLayer.id,
                        };
                    });
                    const cellTextValue = cell.attributes.value;

                    if (cell.attributes.row === index) {
                        let isHeader = cellTextValue?.length > 0;
                        if (this.fixedRow > 0 || this.fixedColumn > 0) {
                            isHeader = cell.attributes.row < this.fixedRow || cell.attributes.column < this.fixedColumn;
                        }

                        let className = [isHeader ? 'header' : (cellLayers?.length > 0 ? 'blocked-header' : '')];
                        if (cell.attributes.style) {
                            if (cell.attributes.style.includes('bold')){
                                if (className?.length > 0) {
                                    className = [...className, 'bold-style'];
                                } else {
                                    className = ['bold-style'];
                                }
                            }
                            if (cell.attributes.style.includes('italic')){
                                if (className?.length > 0) {
                                    className = [...className, 'italic-style'];
                                } else {
                                    className = ['italic-style'];
                                }
                            }
                            if (cell.attributes.style.includes('strikethrough')){
                                if (className?.length > 0) {
                                    className = [...className, 'strikethrough-style'];
                                } else {
                                    className = ['strikethrough-style'];
                                }
                            }

                            if (cell.attributes.style.includes('underline')){
                                if (className?.length > 0) {
                                    className = [...className, 'underline-style'];
                                } else {
                                    className = ['underline-style'];
                                }
                            }

                            if (cell.attributes.style.includes('background:')) {
                                let styleParam = cell.attributes.style.find(param => param.includes('background:'));
                                let colorArray = styleParam.split('background:');
                                let color = this.colorPalette.find((color) => color.code === colorArray[1]);


                                if (className?.length > 0) {
                                    className = [...className, `${color.code}-background-style`];
                                } else {
                                    className = [`${color.code}-background-style`];
                                }
                            }

                            if (cell.attributes.style.includes('color:')) {
                                let styleParam = cell.attributes.style.find(param => param.includes('color:'));
                                let colorArray = styleParam.split('color:');
                                let color = this.colorPalette.find((color) => color.code === colorArray[1]);
                                cell.style.color = `#${color.font}`;

                                if (className?.length > 0) {
                                    className = [...className, `${color.code}-style`];
                                } else {
                                    className = [`${color.code}-style`];
                                }
                            }

                            if (cell.attributes.style.includes('font-size:')) {
                                let styleParam = cell.attributes.style.find(param => param.includes('font-size:'));
                                let sizeArray = styleParam.split('font-size:');
                                cell.style.fontSize = sizeArray[1];

                                if (className?.length > 0) {
                                    className = [...className, `style-${sizeArray[1]}`];
                                } else {
                                    className = [`style-${sizeArray[1]}`];
                                }
                            }
                        }
                        let transformCell = {
                            id: cell.id,
                            head: false,
                            value: '',
                            readOnly: cellTextValue?.length > 0 || cellLayers?.length > 0,
                            className: className,
                            layers: [
                                {
                                    layerType: 'text',
                                    layersDescr: cellTextValue,
                                    hover: false,
                                    id: uuidv4(),
                                },
                                ...cellLayers,
                            ],
                            attributes: {
                                sharedId: cell.attributes.sharedId,
                                row: cell.attributes.row,
                                column: cell.attributes.column,
                                rowSpan: cell.attributes.rowSpan,
                                columnSpan: cell.attributes.columnSpan,
                                style: cell.attributes.style
                            },
                        };

                        if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                            let mergeRowCell = {
                                col: cell.attributes.column,
                                colspan: cell.attributes.columnSpan || 1,
                                row: cell.attributes.row,
                                rowspan: cell.attributes.rowSpan || 1,
                                value: cell.attributes.value,
                            };

                            const haveCopyMergeCell = this.mergeCellsArray.find((cell) => cell.col === mergeRowCell.col && cell.row === mergeRowCell.row);
                            if (!haveCopyMergeCell) this.mergeCellsArray.push(mergeRowCell);
                        }

                        cell.attributes.row === index && row.push(transformCell);
                    }
                });

                rowsArray.push(row);
            }

            rowsArray.forEach((row) => {
                row.forEach((cell) => {
                    if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                        if (cell.attributes.rowSpan > 1) {
                            for (let rowIndex = 0; rowIndex < cell.attributes.rowSpan; rowIndex++) {
                                if (rowIndex === 0) {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        if (cellIndex === 0) continue;
                                        let mockCell = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, 0, cellIndex, cell.className);
                                        rowsArray[cell.attributes.row].push(mockCell);
                                    }
                                } else {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        let mockCell1 = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, rowIndex, cellIndex, cell.className);

                                        rowsArray[cell.attributes.row + rowIndex].push(mockCell1);
                                    }
                                }
                            }
                        } else {
                            for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                if (cellIndex === 0) continue;
                                let mockCell2 = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, 0, cellIndex, cell.className);

                                rowsArray[cell.attributes.row].push(mockCell2);
                            }
                        }
                    }
                });
            });
            rowsArray = rowsArray.map((row) => row.sort((a, b) => (a.attributes.column > b.attributes.column ? 1 : -1)));
            return rowsArray.filter((row) => row.length > 0);
        },

        generateMockCell(editable, row, column, rowCoof = 0, columnCoof = 0, className = []) {
            return {
                type: 'form-template-cell',
                id: uuidv4(),
                mock: true,
                readOnly: editable,
                attributes: {
                    sharedId: uuidv4(),
                    row: row + rowCoof,
                    column: column + columnCoof,
                    rowSpan: 1,
                    columnSpan: 1,
                    value: '',
                    style: [],
                    editable: false,
                },
                className: className,
                layers: [
                    {
                        layerType: 'text',
                        layersDescr: '',
                        hover: false,
                        id: uuidv4(),
                    },
                ],
                relationships: {
                    layers: {
                        data: [],
                    },
                },
            };
        },

        worksheetReload() {
            this.listSelect({ listId: this.currentWorcksheetId, listTitle: this.currentWorksheetTitle });
            this.currentLayer = null;
            this.previousValue = null;
        },

        agregateHistory(userData, versionIncluded) {
            let historyArray = userData.map((historyItem) => {
                const userId = historyItem.relationships.createdBy.data ? historyItem.relationships.createdBy.data.id : null;
                let user = null;
                if (userId) user = versionIncluded ? versionIncluded.filter((item) => item.id === userId) : null;
                return {
                    date: historyItem.attributes.createdAt,
                    name: user ? `${user[0].attributes.firstName} ${user[0].attributes.lastName}` : `Пользователь не указан`,
                    id: historyItem.id,
                    timeStamp: +new Date(historyItem.attributes.createdAt),
                };
            });
            return historyArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
        },

        async saveTemplate($event, dataCellsArray = this.dataFromTable) {
            try {
                this.$root.reachGoal('save-template');
                this.$emit('loadingChange', true);
                this.blockSave = true;
                this.endFormulaWriting($event);
                if ($event !== null) {
                    this.saveWidths();
                }
                this.openLayers = false;
                if (this.currentLayer) {
                    this.currentLayer.layersDescr = this.$refs.contentEditableDiv?.innerHTML || this.currentLayer.layersDescr;
                    this.currentLayer.needConvert = true;
                }

                const { allConvertCellsArray, layersArray, error } = this.convertCellsToSave(dataCellsArray);
                if (error.length) {
                    this.validationTitle = 'Ошибка сохранения шаблона';
                    this.validationError = `<div class="p-d-flex p-flex-column"><span>Ячейки содержат более одного слоя вычисления:</span><br>`;
                    for (let cellId in error) {
                        this.validationError += this.getFormula('', error[cellId], '');
                    }
                    this.validationError += '</div>';
                    this.validationErrorShow = true;
                    this.blockSave = false;
                    return;
                }

                const visibilityRules = this.visibilityLimitedRules.map((rule) => {
                    const newRule = { ...rule, attributes: { ...rule.attributes } };
                    newRule.attributes['row'] = rule.attributes.row !== null ? rule.attributes.row + 1 : rule.attributes.row;
                    newRule.attributes['column'] = rule.attributes.column !== null ? rule.attributes.column + 1 : rule.attributes.column;
                    return newRule;
                });

                let dataToServer = {
                    data: [
                        {
                            type: 'form-template-worksheet-version',
                            id: uuidv4(),
                            attributes: {
                                description: '',
                                createdAt: '',
                            },
                        },
                        ...allConvertCellsArray,
                        ...layersArray,
                        ...visibilityRules,
                    ],
                };

                const result = await saveWorkSheetCells(this.currentWorcksheetId, dataToServer, this.currentWorksheetVersion);
                if (result === 204) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Изменения сохранены.',
                        life: '2200',
                    });
                    const { data: cellArray, included } = await getWorkSheetCells(this.currentWorcksheetId);
                    let worksheet = included.find((item) => item.id === cellArray[0]?.relationships.worksheet.data.id);
                    this.currentWorksheetVersion = worksheet?.attributes.version;
                    const now = new Date();
                    this.worksheetHistory.unshift({
                        id: this.currentWorksheetVersion,
                        name: `${ this.currentUser.firstName } ${ this.currentUser.lastName }`,
                        date: now.toISOString(),
                        timestamp: now
                    });

                    this.dataFromTable = this.agregateCells(cellArray, included);
                    this.cellArray = cellArray;
                    included.forEach((curItem) => {
                        if (curItem.type === 'form') this.cellIncluded.forms.push(curItem);
                        if (curItem.type === 'form-template') this.cellIncluded.formTemplates.push(curItem);
                        if (curItem.type === 'form-template-worksheet') this.cellIncluded.formTemplateWorksheets.push(curItem);
                        if (curItem.type === 'form-template-cell') this.cellIncluded.formTemplateCells.push(curItem);
                    });

                    this.startDataFromTable = deepArrayCopy(this.dataFromTable);
                    this.showArrayMap();
                    this.setStyles();

                    if (worksheet?.attributes.properties.fixedColumnsLeft > 0 || worksheet?.attributes.properties.fixedRowsTop > 0) {
                        this.coordinates = {
                            columnLabels: worksheet.attributes.properties.columnLabels ? worksheet.attributes.properties.columnLabels : [],
                            rowLabels: worksheet.attributes.properties.rowLabels ? worksheet.attributes.properties.rowLabels : []
                        };
                        await this.fixHeaders(true, false);
                    }
                }
                this.tableHaveChange = false;
            } catch (error) {
                this.validationTitle = 'Ошибка сохранения шаблона';
                this.validationError = `Не удалось сохранить шаблон.<br><br>`;
                this.validationError += this.unconvertLayer(error.message);
                this.validationErrorShow = true;
            } finally {
                this.$emit('loadingChange');
                this.blockSave = false;
            }
        },

        convertCellsToSave(cellsArray) {
            let allConvertCellsArray = [];
            let layersArray = [];
            let error = [];

            cellsArray = cellsArray.map((row) => {
                row.forEach((cell) => {
                    cell['mock'] = false;
                    return cell;
                });
                return row;
            });
            cellsArray = cellsArray.map((row) => {
                row.forEach((cell) => {
                    let cellHaveMerge = this.mergeCellsArray.find((mergeCell) => mergeCell.col === cell.attributes.column && mergeCell.row === cell.attributes.row);

                    if (cellHaveMerge) {
                        if (cellHaveMerge.rowspan > 1) {
                            for (let rowIndex = 0; rowIndex < cellHaveMerge.rowspan; rowIndex++) {
                                if (rowIndex === 0) {
                                    for (let cellIndex = 0; cellIndex < cellHaveMerge.colspan; cellIndex++) {
                                        if (cellIndex === 0) continue;
                                        cellsArray[cellHaveMerge.row + rowIndex][cellHaveMerge.col + cellIndex]['mock'] = true;
                                    }
                                } else {
                                    for (let cellIndex = 0; cellIndex < cellHaveMerge.colspan; cellIndex++) {
                                        cellsArray[cellHaveMerge.row + rowIndex][cellHaveMerge.col + cellIndex]['mock'] = true;
                                    }
                                }
                            }
                        } else {
                            for (let index = 0; index < cellHaveMerge.colspan; index++) {
                                if (index === 0) continue;
                                cellsArray[cellHaveMerge.row][cellHaveMerge.col + index]['mock'] = true;
                            }
                        }
                    }
                    return cell;
                });
                return row;
            });

            cellsArray.forEach((row) => {
                row.forEach((cell) => {
                    let cellHaveMerge = this.mergeCellsArray.find((mergeCell) => mergeCell.col === cell.attributes.column && mergeCell.row === cell.attributes.row);
                    if (!cell['mock']) {
                        let calcLayers = cell.layers.filter(layer => layer.layerType === 'calc');
                        if (calcLayers.length > 1) {
                            error.push(cell.attributes.sharedId);
                        }

                        let convertCell = {
                            type: 'form-template-cell',
                            id: cell.attributes ? cell.id : uuidv4(),
                            attributes: {
                                sharedId: cell.attributes ? cell.attributes.sharedId : uuidv4(),
                                row: cell.attributes ? cell.attributes.row + 1 : 1,
                                column: cell.attributes ? cell.attributes.column + 1 : 1,
                                rowSpan: 1,
                                columnSpan: 1,
                                style: cell.attributes.style,
                                value: cell.layers[0].layersDescr || '',
                                editable: !cell.readOnly,
                            },
                            relationships: {
                                worksheet: {
                                    data: {
                                        type: 'form-template-worksheet',
                                        id: this.currentWorcksheetId,
                                    },
                                },
                                layers: {
                                    data: cell.layers
                                        .filter((layer) => layer.layerType !== 'text')
                                        .map((layer) => {
                                            return { type: 'form-template-cell-layer', id: layer.id };
                                        }),
                                },
                            },
                        };

                        if (cellHaveMerge) {
                            convertCell.attributes.rowSpan = cellHaveMerge.rowspan;
                            convertCell.attributes.columnSpan = cellHaveMerge.colspan;
                        }

                        if (cell.attributes && (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1)) {
                            let cellLessMerge = this.mergeCellsArray.find((mergeCell) => mergeCell.col === cell.attributes.column && mergeCell.row === cell.attributes.row);
                            if (!cellLessMerge) {
                                convertCell.attributes.rowSpan = 1;
                                convertCell.attributes.columnSpan = 1;
                            }
                        }

                        allConvertCellsArray.push(convertCell);

                        let convertLayers = cell.layers
                            .filter((layer) => layer.layerType !== 'text' && layer.layersDescr)
                            .map((layer) => {
                                return {
                                    type: 'form-template-cell-layer',
                                    id: layer.id,
                                    attributes: {
                                        type: layer.layerType,
                                        value: ['type', 'aggregate'].includes(layer.layerType)
                                            ? this.convertTypeOrAggregateLayer(layer.layersDescr, true)
                                            : (layer.needConvert
                                                ? this.convertLayerDescr(layer.layersDescr)
                                                : layer.layersDescr),
                                        required: true,
                                    },
                                    relationships: {
                                        cell: {
                                            data: {
                                                type: 'form-template-cell',
                                                id: cell.id,
                                            },
                                        },
                                    },
                                };
                            });
                        layersArray = [...layersArray, ...convertLayers];
                    }
                });
            });

            return { allConvertCellsArray, layersArray, error };
        },

        clearAndLeave() {
            this.dataFromTable = mockDataTableDataArray;
            this.updateData();

            this.tableHaveChange = false;
            this.startDataFromTable = null;
            this.mergeCellsArray = [];
            this.currentRow = 0;
            this.currentColl = 0;
            this.showTable = false;

            this.$router.push(this.leaveRoute);
        },

        convertLayerDescr(layersDescr) {
            if (!layersDescr) return '';

            if (layersDescr.value) layersDescr = layersDescr.value;
            if (layersDescr.indexOf('value') >= 0) {
                const layersDescrArr = layersDescr.split(':');
                layersDescr = layersDescrArr[layersDescrArr.length - 1];
            }

            const temp = document.createElement('div');
            temp.innerHTML = layersDescr;
            let htmlSplit = Array.from(temp.childNodes).map(i => {
                return i.nodeName === 'DIV' ? i.outerHTML : i.data;
            });

            let resultArr = [];
            htmlSplit.forEach((string) => {
                if (string.indexOf('<') > 0) {
                    let fragment1 = string.slice(0, string.indexOf('<'));
                    let fragment2 = string.slice(string.indexOf('<'));
                    resultArr.push(fragment1.trim());
                    resultArr.push(fragment2.trim());
                    return;
                }
                resultArr.push(string.trim());
            });
            resultArr = resultArr.map((string, stringIndex) => {
                if (~string.indexOf('getByTag')) {
                    const nodeStr = resultArr[stringIndex + 1];
                    let sharedid = nodeStr
                        .split(' ')
                        .filter((str) => str.indexOf('data-sharedid') >= 0)
                        .join('')
                        .split('"')
                        .filter((str) => str.length > 15);
                    if (sharedid) resultArr[stringIndex + 1] = `'${sharedid[0]}'`.replaceAll(' ', '');

                    const tagStr = resultArr[stringIndex + 2];
                    let tag = '';
                    if (tagStr) tag = /,([^;]+)\)/.exec(tagStr)[1];
                    if (tag) resultArr[stringIndex + 2] = tagStr.replaceAll(`${tag}`, `'${tag}'`);
                    return string;
                }
                if (string.indexOf('data-sharedid') >= 0) {
                    let sharedid = string
                        .split(' ')
                        .filter((str) => str.indexOf('data-sharedid') >= 0 || str.indexOf('data-periodid') >= 0)
                        .join('')
                        .split('"')
                        .filter((str) => str.length > 15);
                    if (string.indexOf('data-prevperiod') >= 0) {
                        return `'${sharedid[0]}'`;
                    } else if (string.indexOf('data-accumulate') >= 0) {
                        return `'${sharedid[0]}'`;
                    } else {
                        if (sharedid.length > 1) return `cell('${sharedid[0]}','${sharedid[1]}')`;
                        return `cell('${sharedid[0]}')`;
                    }
                } else if (string.indexOf('data-periodid') >= 0) {
                    let periodid = string
                        .split(' ')
                        .filter((str) => str.indexOf('data-periodid') >= 0)
                        .join('')
                        .split('"')
                        .filter((str) => str.length > 15);

                    return `period('${periodid[0]}')`;
                } else {
                    return string;
                }
            });

            return resultArr.join('').replaceAll('&gt;', '>').replaceAll('&lt;', '<').replaceAll('&nbsp;', '');
        },

        unconvertLayer(layerValue) {
            let nodeElement = '';
            let arrayElements = layerValue.split('\')').map((element) => {
                if (element.includes('getByTag(')) {
                    nodeElement = element.concat('', '\')');
                } else if (element.includes('in_array(')) {
                    nodeElement = element;
                } else {
                    element.includes('(\'') ? (nodeElement = element + '\')') : (nodeElement = element);
                }

                return nodeElement.trim();
            });

            let resultArr = [];
            arrayElements.forEach((string) => {
                let isFormula = false;
                ['getByTag', 'cell', 'period', 'accumulate'].forEach((type) => {
                    if (string.indexOf(type) >= 0) {
                        if (type === 'period' && string.indexOf('prev_period') < 0) {
                            if (string.indexOf('count_periods') >= 0 || string.indexOf('current_period') >= 0) {
                                isFormula = true;
                                return resultArr.push(string);
                            }

                            string.split(' ').forEach((substring) => {
                                if (substring.trim().length === 0) {
                                    return;
                                }
                                if (substring.indexOf('period') < 0) {
                                    return resultArr.push(substring);
                                }
                                if (substring.indexOf('period') >= 0) {
                                    let formulaArray = string.split(new RegExp(`${ type }\\('|'\\)`));
                                    let newItem = formulaArray.map((item) => {
                                        if (item.length === 36) {
                                            isFormula = true;
                                            return this.getFormula(
                                                type === 'cell' ? '' : `${ type }(`,
                                                item,
                                                type === 'cell' ? '' : ')'
                                            );
                                        }

                                        return item;
                                    }).join(' ');

                                    resultArr.push(newItem);
                                }
                            });
                            return;
                        } else {
                            if (type === 'period') {
                                type = 'prev_period';
                            }

                            let formulaArray = string.split(new RegExp(`${ type }\\('|'\\)`));
                            if (type === 'prev_period' && string.indexOf('prev_period(cell') >= 0) {
                                return;
                            }

                            if (type === 'accumulate' && string.indexOf('accumulate(cell') >= 0) {
                                return;
                            }

                            let newItem = formulaArray.map((item) => {
                                if (item.indexOf(',') > 0) {
                                    if (type === 'getByTag') {
                                        const [cellSharedId, tagString] = item.split('\',\'');

                                        if (cellSharedId.length === 36) {
                                            isFormula = true;
                                            return this.getFormula(`${type}(`, cellSharedId, `,${tagString})`);
                                        }
                                    }
                                }
                                if (item.length === 36) {
                                    isFormula = true;
                                    return this.getFormula(
                                        type === 'cell' ? '' : `${type}(`,
                                        item,
                                        type === 'cell' ? '' : ')'
                                    );
                                }

                                return item;
                            }).join(' ');

                            return resultArr.push(newItem);
                        }
                    }
                });

                if (!isFormula) {
                    resultArr.push(string);
                }
            });
            return resultArr.join(' ');
        },

        getFormula(formula = '', sharedId, params = '') {
            if (formula === 'period(') {
                let period = this.allTemplatePeriods.find((period) => period.id === sharedId);

                if (period) {
                    return `<div contenteditable="false" class="cellMarker" data-periodid="${ sharedId }" >period(${ period && period.attributes.name })</div>`;
                }
            }
            let sharedCell = this.cellArray.find((cell) => cell.attributes.sharedId === sharedId);
            if (!sharedCell) {
                sharedCell = this.cellIncluded.formTemplateCells.find((cell) => cell.attributes.sharedId === sharedId);
            }

            if (!sharedCell) {
                return createCellLayout({
                    sharedId: 'NOID',
                    row: 0,
                    column: 0,
                    worksheetTitle: 'Без имени',
                    templateTitle: 'Без шаблона',
                    formTitle: 'Без формы',
                    hot: this.$refs.myTable.hotInstance,
                });
            } else {
                const {
                    sharedId,
                    row,
                    column,
                    worksheetTitle,
                    templateTitle,
                    formTitle,
                    coordinates
                } = this.compareData(sharedCell, this.cellIncluded);

                return `${ formula }${ createCellLayout({
                    sharedId,
                    row: row,
                    column: column,
                    worksheetTitle,
                    templateTitle,
                    formTitle,
                    hot: this.$refs.myTable.hotInstance,
                    cellWithOterForm: formula === '',
                    prevPeriodMode: formula === 'prev_period(',
                    accumulateMode: formula === 'accumulate(',
                    coordinates: coordinates || this.coordinates
                }) }${ params }`;
            }
        },

        convertTypeOrAggregateLayer(description, convert) {
            return convert
                ? (this.aggregateTypes.find(i => i.label === description)
                    ? this.aggregateTypes.find(i => i.label === description).value
                    : this.valueTypes.find(i => i.label === description)?.value)
                : (this.aggregateTypes.find(i => i.value === description)
                    ? this.aggregateTypes.find(i => i.value === description).label
                    : this.valueTypes.find(i => i.value === description)?.label);
        },

        compareData(sharedCell, included) {
            if (!sharedCell) {
                return;
            }
            const sharedCellWorksheet = included.formTemplateWorksheets.find((worksheet) => worksheet.id === sharedCell.relationships.worksheet.data.id);
            const sharedCellTemplate = included.formTemplates.find((template) => template.id === sharedCellWorksheet.relationships?.template.data.id);
            const sharedCellForm = included.forms.find((form) => form.id === sharedCellTemplate?.relationships.form.data.id);

            let isOtherForm = sharedCellWorksheet.id !== this.currentWorcksheetId;
            return {
                sharedId: sharedCell.attributes.sharedId,
                row: isOtherForm ? sharedCell.attributes.row - 1 : sharedCell.attributes.row,
                column: isOtherForm ? sharedCell.attributes.column - 1 : sharedCell.attributes.column,
                worksheetTitle: sharedCellWorksheet.attributes.name,
                templateTitle: sharedCellTemplate?.attributes.name,
                formTitle: sharedCellForm?.attributes.name,
                coordinates: {
                    columnLabels: sharedCellWorksheet.attributes.properties?.columnLabels || [],
                    rowLabels: sharedCellWorksheet.attributes.properties?.rowLabels || []
                }
            };
        }
    },

    computed: {
        ...mapGetters('auth', [ 'currentUser' ]),

        startLayerOnCell() {
            const layersCount = this.filteredLayers?.length;
            if (!layersCount) {
                return this.currentLayer;
            }
            return layersCount === 1 ? this.filteredLayers[0] : this.filteredLayers[1];
        },

        maxColNumber() {
            return (this.dataFromTable[0] && this.dataFromTable[0].length);
        },

        maxRowNumber() {
            return this.dataFromTable.length || 0;
        },

        selectedCell() {
            const { row, col } = this.cellForAutoFillingCoordinates || {};
            if (row !== undefined && row !== null) return this.dataFromTable[row][col];
            return null;
        },

        topBarMenuItemsComputed() {
            return [
                {
                    label: 'Форма',
                    icon: 'icon iconFontSize custom-icon-form',
                    disabled: !this.showTable || this.formulaBuilding,
                    items: [
                        {
                            label: 'Сохранить',
                            icon: 'pi pi-fw pi-save',
                            disabled: this.blockSave,
                            command: () => {
                                this.saveTemplate();
                            },
                        },
                        {
                            label: 'Перезагрузить',
                            icon: 'pi icon custom-status-change',
                            command: () => {
                                this.worksheetReload();
                            },
                            disabled: !this.showTable,
                        },
                        {
                            label: 'Очистить',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.clearTableData();
                            },
                            disabled: !this.showTable,
                        },
                        {
                            separator: true,
                        },
                        {
                            label: 'Скачать шаблон',
                            icon: 'pi pi-fw pi-download',
                            command: () => {
                                this.downloadConstructorTemplateXLS();
                            },
                        },
                        {
                            label: 'Скачать шаблон с sharedId',
                            icon: 'pi pi-fw pi-download',
                            command: () => {
                                this.downloadConstructorTemplateXLS('xlsx?showSharedId=1');
                            },
                        },
                        {
                            label: 'Скачать проверки',
                            icon: 'pi pi-fw pi-download',
                            command: () => {
                                this.downloadChecksXLS();
                            },
                        },
                        {
                            label: 'Загрузить шаблон из файла',
                            icon: 'pi pi-fw pi-upload',
                            command: () => {
                                this.showUploadFileDialog();
                            },
                        }
                    ],
                },
                {
                    label: 'Таблица',
                    icon: 'icon custom-icon-table',
                    disabled: !this.showTable || this.formulaBuilding,
                    items: [
                        {
                            label: 'Вставить строку выше',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.insertRow('Above');
                            },
                            disabled: !this.showTable,
                        },
                        {
                            label: 'Вставить строку ниже',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.insertRow('Below');
                            },
                            disabled: !this.showTable,
                        },
                        {
                            separator: true,
                        },
                        {
                            label: 'Вставить столбец слева',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.insertCol('left');
                            },
                            disabled: !this.showTable,
                        },
                        {
                            label: 'Вставить столбец справа',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.insertCol('right');
                            },
                            disabled: !this.showTable,
                        },
                        {
                            separator: true,
                        },
                        {
                            label: 'Удалить строку',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.deleteRow();
                            },
                            disabled: !this.showTable,
                        },
                        {
                            label: 'Удалить столбец',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.deleteColl();
                            },
                            disabled: !this.showTable,
                        },
                        {
                            separator: true,
                        },
                        {
                            label: 'Зафиксировать шапку',
                            icon: 'icon custom-lock',
                            command: () => {
                                this.fixedRow = this.currentRow;
                                this.fixedColumn = this.currentColl;
                                this.fixHeaders(true, true);
                            },
                            visible: !this.headerFixed,
                            disabled: !this.showTable,
                        },
                        {
                            label: 'Распустить шапку',
                            icon: 'icon custom-unlock',
                            command: () => {
                                this.fixedRow = 0;
                                this.fixedColumn = 0;
                                this.fixHeaders(false, true);
                            },
                            visible: !!this.headerFixed,
                            disabled: !this.showTable,
                        }
                    ],
                },
                {
                    label: 'Ячейка',
                    icon: 'icon custom-icon-cell',
                    disabled: !this.showTable || this.formulaBuilding,
                    items: [
                        {
                            label: 'Правка',
                            icon: 'pi pi-fw pi-pencil',
                            items: [
                                {
                                    label: 'Вырезать',
                                    icon: 'icon custom-crop',
                                    command: () => {
                                        this.showLayersCopyOrDeletePopup('cut');
                                    },
                                },
                                {
                                    label: 'Копировать',
                                    icon: 'pi pi-fw pi-clone',
                                    command: () => {
                                        this.showLayersCopyOrDeletePopup('copy');
                                    },
                                },
                                {
                                    label: 'Вставить',
                                    icon: 'icon custom-paste',
                                    disabled: this.haveLayersToInsert,
                                    command: () => {
                                        this.pasteLayers();
                                    },
                                },
                            ]
                        },
                        {
                            label: 'Формат',
                            icon: 'icon custom-format',
                            items: [
                                {
                                    label: 'Полужирный',
                                    icon: 'icon custom-bold',
                                    command: () => {
                                        this.toggleCellStyle('bold');
                                    }
                                },
                                {
                                    label: 'Курсив',
                                    icon: 'icon custom-italic',
                                    command: () => {
                                        this.toggleCellStyle('italic');
                                    }
                                },
                                {
                                    label: 'Зачёркнутый',
                                    icon: 'icon custom-crossed-out',
                                    command: () => {
                                        this.toggleCellStyle('strikethrough');
                                    }
                                },
                                {
                                    label: 'Подчёркнутый',
                                    icon: 'icon custom-underline',
                                    command: () => {
                                        this.toggleCellStyle('underline');
                                    }
                                },
                                {
                                    separator: true,
                                },
                                {
                                    label: 'Цвет текста',
                                    icon: 'icon custom-text-color',
                                    items: [
                                        {
                                            label: 'Бордовый',
                                            icon: 'icon custom-burgundy',
                                            command: () => {
                                                this.toggleCellStyle('color:burgundy');
                                            }
                                        },
                                        {
                                            label: 'Красный',
                                            icon: 'icon custom-red',
                                            command: () => {
                                                this.toggleCellStyle('color:red');
                                            }
                                        },
                                        {
                                            label: 'Оранжевый',
                                            icon: 'icon custom-orange',
                                            command: () => {
                                                this.toggleCellStyle('color:orange');
                                            }
                                        },
                                        {
                                            label: 'Жёлтый',
                                            icon: 'icon custom-yellow',
                                            command: () => {
                                                this.toggleCellStyle('color:yellow');
                                            }
                                        },
                                        {
                                            label: 'Зелёный',
                                            icon: 'icon custom-green',
                                            command: () => {
                                                this.toggleCellStyle('color:green');
                                            }
                                        },
                                        {
                                            label: 'Бирюзовый',
                                            icon: 'icon custom-turquoise',
                                            command: () => {
                                                this.toggleCellStyle('color:turquoise');
                                            }
                                        },
                                        {
                                            label: 'Голубой',
                                            icon: 'icon custom-blue',
                                            command: () => {
                                                this.toggleCellStyle('color:blue');
                                            }
                                        },
                                        {
                                            label: 'Синий',
                                            icon: 'icon custom-dark-blue',
                                            command: () => {
                                                this.toggleCellStyle('color:darkblue');
                                            }
                                        },
                                        {
                                            label: 'Фиолетовый',
                                            icon: 'icon custom-violet',
                                            command: () => {
                                                this.toggleCellStyle('color:violet');
                                            }
                                        },
                                        {
                                            label: 'Пурпурный',
                                            icon: 'icon custom-purple',
                                            command: () => {
                                                this.toggleCellStyle('color:purple');
                                            }
                                        }
                                    ]
                                },
                                {
                                    label: 'Цвет заливки',
                                    icon: 'icon custom-background',
                                    items: [
                                        {
                                            label: 'Бордовый',
                                            icon: 'icon custom-burgundy',
                                            command: () => {
                                                this.toggleCellStyle('background:burgundy');
                                            }
                                        },
                                        {
                                            label: 'Красный',
                                            icon: 'icon custom-red',
                                            command: () => {
                                                this.toggleCellStyle('background:red');
                                            }
                                        },
                                        {
                                            label: 'Оранжевый',
                                            icon: 'icon custom-orange',
                                            command: () => {
                                                this.toggleCellStyle('background:orange');
                                            }
                                        },
                                        {
                                            label: 'Жёлтый',
                                            icon: 'icon custom-yellow',
                                            command: () => {
                                                this.toggleCellStyle('background:yellow');
                                            }
                                        },
                                        {
                                            label: 'Зелёный',
                                            icon: 'icon custom-green',
                                            command: () => {
                                                this.toggleCellStyle('background:green');
                                            }
                                        },
                                        {
                                            label: 'Бирюзовый',
                                            icon: 'icon custom-turquoise',
                                            command: () => {
                                                this.toggleCellStyle('background:turquoise');
                                            }
                                        },
                                        {
                                            label: 'Голубой',
                                            icon: 'icon custom-blue',
                                            command: () => {
                                                this.toggleCellStyle('background:blue');
                                            }
                                        },
                                        {
                                            label: 'Синий',
                                            icon: 'icon custom-dark-blue',
                                            command: () => {
                                                this.toggleCellStyle('background:darkblue');
                                            }
                                        },
                                        {
                                            label: 'Фиолетовый',
                                            icon: 'icon custom-violet',
                                            command: () => {
                                                this.toggleCellStyle('background:violet');
                                            }
                                        },
                                        {
                                            label: 'Пурпурный',
                                            icon: 'icon custom-purple',
                                            command: () => {
                                                this.toggleCellStyle('background:purple');
                                            }
                                        }
                                    ]
                                },
                                {
                                    separator: true,
                                },
                                {
                                    label: 'Размер шрифта',
                                    icon: 'icon custom-font',
                                    items: [
                                        {
                                            label: '10 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:10px');
                                            }
                                        },
                                        {
                                            label: '12 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:12px');
                                            }
                                        },
                                        {
                                            label: '14 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:14px');
                                            }
                                        },
                                        {
                                            label: '16 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:16px');
                                            }
                                        },
                                        {
                                            label: '18 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:18px');
                                            }
                                        },
                                        {
                                            label: '20 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:20px');
                                            }
                                        },
                                        {
                                            label: '22 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:22px');
                                            }
                                        },
                                        {
                                            label: '24 px',
                                            icon: 'pi empty',
                                            command: () => {
                                                this.toggleCellStyle('font-size:24px');
                                            }
                                        }
                                    ]
                                },
                                {
                                    label: 'Очистить форматирование',
                                    icon: 'pi pi-fw pi-trash',
                                    command: () => {
                                        this.clearCellStyle();
                                    }
                                },
                            ]
                        },
                        {
                            label: 'Слои',
                            icon: 'icon custom-icon-layer',
                            items: [
                                {
                                    label: 'Удалить слои',
                                    icon: 'pi pi-fw pi-trash',
                                    command: () => {
                                        this.showLayersCopyOrDeletePopup('delete');
                                    },
                                },
                            ],
                        }
                    ],
                },
            ];
        },

        corectPading() {
            return this.layers.length < 4;
        },

        curentSelectedLayerType() {
            return this.selectedLayerType ? this.selectedLayerType.layerType : '';
        },

        layers() {
            if (this.cellForAutoFillingCoordinates) {
                const { row, col } = this.cellForAutoFillingCoordinates;
                return this.dataFromTable[row][col] ? this.dataFromTable[row][col].layers : [];
            }

            const row = this.currentRow === -1 ? 0 : this.currentRow;
            const col = this.currentColl === -1 ? 0 : this.currentColl;

            return this.dataFromTable[row][col] ? this.dataFromTable[row][col].layers : [];
        },

        filteredLayers() {
            if (!this.dataFromTable) {
                return [];
            }

            const row = this.currentRow === -1 ? 0 : this.currentRow;
            const col = this.currentColl === -1 ? 0 : this.currentColl;

            return this.dataFromTable[row][col] ? this.dataFromTable[row][col].layers : [];
        },

        haveLayersToInsert() {
            return this.copyLayersArray.length === 0;
        },

        allAvialableLayerTypes() {
            let arraySinglesLayers = SINGLE_LAYERS;
            this.editLayerStart ? (arraySinglesLayers = []) : null;
            let localCopyLayersArray = this.allLayerTypes.map((layer) => JSON.parse(JSON.stringify(layer)));

            arraySinglesLayers.map((layerType) => {
                let layerIndex = this.layers.findIndex((layer) => layer.layerType === layerType);

                if (layerIndex >= 0) localCopyLayersArray = localCopyLayersArray.filter((layer) => layer.layerType !== layerType);
            });

            return localCopyLayersArray;
        },

        formId() {
            return this.$route.params.formId;
        },

        templateId() {
            return this.$route.params.templateId;
        },

        disabledConditions() {
            if (['aggregate', 'type'].includes(this.curentSelectedLayerType)) {
                return !this.layerDescription;
            }

            return !this.selectedLayerType;
        },
    },

    watch: {
        selectedMode(next) {
            if (!next) {
                this.hideRightMenu();
                return;
            }
            this.rightMenuShow();
        }
    },

    created() {
        this.dataFromTable = mockDataTableDataArray;
    },

    async mounted() {
        await this.getAllTemplateData();
        await this.updateData();

        this.$root.$on('render-table', () => {
            setTimeout(() => {
                this.$refs.myTable?.hotInstance.render();
            }, 1000);
        });
    },

    async beforeRouteLeave(to, from, next) {
        this.leaveRoute = to.fullPath;
        if (this.tableHaveChange || (this.startDataFromTable && this.dataFromTable && haveDifference(this.startDataFromTable, this.dataFromTable))) {
            this.$root.$emit('show-leave-dialog', {
                acceptCb: async () => {
                    await this.saveTemplate();
                },
                beforeClose: next,
            });
            return;
        }
        next();
    },
};
// 3946
</script>

<style lang="scss" scoped>
::v-deep .p-scrollpanel-bar-y {
    display: none !important;
}

.hideElem {
    display: none !important;
}

.menuShow {
    opacity: 1;
    width: 255px;
    min-height: 100px;
    max-height: 83vh;
}

.customFontSize {
    font-size: 18px;
}

::v-deep .customDelDialog {
    .p-dialog-footer {
        padding: 0 14px;
        padding-bottom: 20px;
    }
}

.saveButtonStyles {
    padding: 10px;
}

.card {
    padding: 0;
    height: 100%;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
}

.p-col-custom {
    width: calc(100% - 272px);
}

.p-col-customPad {
    background-color: #fff;
    padding: 0 16px !important;
    border-radius: 3px;
    //height: 83vh;
    //overflow: hidden;
    max-height: 100%;
}

.p-inputgroup-addon {
    background-color: inherit;
    min-width: 45px;
    height: 45px;
    padding: 0;

    > div:first-child {
        height: 100%;
    }

    //padding-top: 10px;
    //padding-bottom: 10px;
    //border-left: none;
    //border-top: none;
    // border-left: none;
    //border-bottom: none;
}

.p-inputgroup-addon:last-child {
    padding-right: 15px;
}

.bottomBorder {
    border-bottom: 1px solid #eaeaea;
}

.customRightBorder {
    border-right: 1px solid #eaeaea !important;
}

.p-inputtextarea-resizable {
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
}

.p-inputtext:enabled:hover {
    border-color: #388E3C;
}

.showLayerButton {
    cursor: pointer;
}

.customRed {
    color: #f44336;
}

.customRedAlert {
    background-color: #f44336;
    padding: 2px 8px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;

    i {
        margin-left: 4px;
    }

    .green {
        background: #66BB6A;
        border-radius: 6px;
    }
}

.customRedCircle {
    color: #f44336;
    display: flex;
    align-items: center;
}

.customHeight {
    //overflow-y: hidden;
    //overflow-x: hidden;
    // border: 1px solid #e4e4e4;
    border-radius: 4px;
}

.layerContainer {
    position: relative;
    // animation: all 2s ease;
    //padding-left: 30px;
    .p-button {
        transition: none;
    }

    .p-button.p-button-text:enabled:hover {
        background-color: transparent;
    }

    .p-button:enabled:focus {
        box-shadow: none;
    }

    .layer-selector {
        position: absolute;
        bottom: 0;
        left: 0;
        background: white;
        transform: translateY(100%);
        z-index: 1000;
        border-radius: 4px;
        box-shadow: 0 1px 8px rgb(0 0 0 / 8%);
        white-space: nowrap;

        &-item {
            padding: 10px;
            cursor: pointer;
            display: grid;
            grid-template-columns: 1fr 5fr 1fr;
            grid-gap: 10px;

            &:hover {
                background: #eaeaea;
                color: #8c8c8c;

                .layer-selector-item-actions {
                    opacity: 1 !important;
                }
            }

            & > * {
                //align-self: center;
                display: flex;
                align-items: center;
            }

            &-actions {
                opacity: 0;
                transition: opacity ease-in-out .2s;
                color: red;
                font-size: 16px;
            }
        }
    }

    .p-inputdiv {
        width: 90%;
        padding: 0.2rem 0.5rem;
        padding-top: 10px;
        min-height: 43px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.5rem;
        overflow-wrap: break-word;
    }

    .p-inputdiv:focus-visible {
        outline: none;
    }

    .customLayerHover:hover .formulaEditor {
        border-color: #388E3C;
        box-shadow: 0 0 0 0.2rem #bbdefb;
    }

    .formulaEditor {
        min-height: 35px;
        padding: 9.5px 8px;
        border: 1px solid #e4e4e4;
    }

    .displayBlock {
        display: block !important;
        max-height: 100px;
        overflow-y: scroll;
    }

    ::v-deep .cellMarker {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #495058;
        border-radius: 3px;
        cursor: pointer;
        margin-bottom: 0;
        display: inline-block !important;
    }
}

.layersOpenButton {
    position: absolute;
    left: 0;
    top: 5px;
    animation: all 1s ease;
}

.layersContainerOpen {
    transform: rotate(90deg);
}

::v-deep .ScrollPanelCustomSettings {
    position: relative;

    .p-scrollpanel-bar {
        background-color: rgba(51, 51, 51, 0.6);
        opacity: 1;
        border-radius: 10px;
        width: 4px;
        right: 8px !important;
        // top: calc(0% - 3px) !important;
        position: absolute;
        // transition: background-color 0.3s;
    }

    .p-scrollpanel-bar-y {
        display: none;
    }

    .p-scrollpanel-bar {
        display: none;
    }

    .p-scrollpanel-content {
        overflow: hidden;
        padding-right: 0;

        div:first-child {
            .p-inputgroup {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }
        }

        div:last-child {
            .p-inputgroup {
                border-bottom: 1px solid #e4e4e4;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    .p-inputgroup {
        width: calc(100% - 2px);
    }

    .customBorder {
        border-left: 1px solid #e4e4e4;
        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
    }

    .customRightMargin {
        margin-right: 10px;
    }

    .customLayerHover:hover .p-inputgroup,
    .customLayerHover:hover .p-inputtext {
        background-color: #f5f5f5;
    }

    .p-inputgroup {
        width: calc(100% - 2px);
        border-top: 1px solid #e4e4e4;
        border-left: 1px solid #e4e4e4;

        .p-inputtext {
            max-width: 100%;
            padding: 9.5px 8px;
        }
    }

    .p-inputtext,
    .p-inputdiv {
        border-left: 1px solid #e4e4e4;
        border-right: 1px solid #e4e4e4;
        border-top: 0;
        border-bottom: 0;
    }

    .p-inputdiv {
        width: 90%;
        padding: 0.2rem 0.5rem;
        padding-top: 10px;
        min-height: 43px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.5rem;
        overflow-wrap: break-word;
    }

    .p-inputdiv:focus-visible {
        outline: none;
    }

    .displayBlock {
        display: block !important;
    }

    .formulaEditor {
        min-height: 35px;
        padding: 9.5px 8px;
    }

    .cellMarker {
        max-height: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #495058;
        border-radius: 3px;
        // border: 1px solid rgb(20, 118, 184);
        cursor: pointer;
        margin-bottom: 0;
        display: inline-block !important;
    }
}

::v-deep .openContainer {
    min-height: 130px;
    overflow-y: auto;

    .ScrollPanelCustomSettings {
        .p-scrollpanel-content {
            overflow-y: auto !important;
        }

        .p-scrollpanel-bar-y {
            display: block;
        }
    }
}

::v-deep .customScroll {
    .p-scrollpanel-content {
        width: 100%;
        height: 100%;
        padding: 0;
    }
}

::v-deep .corectPading {
    .p-scrollpanel-content {
        width: 100%;
        height: 100%;
        padding: 0;
    }
}

::v-deep .archBehaviorVisible {
    ul {
        li:nth-child(1) {
            a {
                background: #ffffff;
                border-color: #388e3c !important;
                color: #388e3c !important;
            }
        }
    }
}

::v-deep .archBehaviorArch {
    ul {
        li:nth-child(2) {
            a {
                background: #ffffff;
                border-color: #388e3c !important;
                color: #388e3c !important;
            }
        }
    }
}

::v-deep .p-menubar {
    background-color: #f5f5f5;
    border: 1px solid #e4e4e4;
    padding: 8px;
    border-radius: 3px;

    .p-menuitem-link:focus {
        box-shadow: none !important;
    }

    .p-menubar-root-list {
        height: 34px;

        .p-menuitem {
            padding: 0;
            margin-right: 8px;

            .p-menuitem-link {
                padding: 9px 7px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #495058;
                display: flex;
                justify-content: flex-start;
            }

            .p-menuitem-link:hover {
                background: #e0e0e0 !important;
            }
        }

        .p-menuitem-active {
            background: #e0e0e0 !important;

            border-radius: 3px;
        }

        .p-menuitem-active > a {
            background: #e0e0e0 !important;
        }

        //первый выпадающий список топ меню
        li:nth-child(1) {
            .p-submenu-list {
                li:nth-child .p-menuitem-link {
                    padding: 9px 20px 9px 20px !important;

                    .p-menuitem-text {
                        margin-left: -5px;
                    }
                }
            }
        }

        li:nth-child(2) {
            .p-submenu-list {
                .p-menuitem {
                    .p-menuitem-link {
                        padding: 9px 20px 9px 20px !important;
                    }
                }
            }
        }

        li:nth-child(3) {
            .ul:nth-child(1) {
                li:nth-child(2) .p-menuitem-link {
                    .p-menuitem-icon {
                        margin-left: -3px;
                    }
                }
            }

            .p-submenu-list {
                li:nth-child(1) {
                    .p-submenu-list {
                        .p-menuitem {
                            .p-menuitem-link {
                                padding: 9px 20px 9px 20px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .p-submenu-list {
        z-index: 200;
        width: 254px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
        border-radius: 3px;

        padding: 4px 0;

        .p-menuitem {
            margin-right: 0;

            .p-menuitem-link {
                padding: 9px 20px 9px 15px;
            }

            .p-menuitem-link:not(.p-disabled):hover {
                background: #f5f5f5 !important;
            }
        }

        .p-menu-separator {
            margin: 1px 0 1px 40px;
        }

        .p-menuitem-active {
            background: #fff !important;
        }

        .p-menuitem-active > a {
            background: #fff !important;
        }
    }

    .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        box-shadow: none;
    }
}

::v-deep .p-menubar-end {
    .right-menu-activators {
        .p-button {
            background: #f5f5f5;
            border: none;
            padding-right: 0;
            padding-left: 12px;

            .menu-button {
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
            }

            .icon:hover {
                color: #388e3c;
            }

            &.p-highlight {
                color: #388E3C;
            }
        }

        .p-button:hover {
            background-color: #f5f5f5 !important;

            .p-button-label {
                color: #388e3c;
            }

            .icon {
                color: #388e3c;
            }
        }

        .p-selectbutton .p-button.p-highlight {
            color: #388e3c;
        }
    }
}

.icon.iconFontSize {
    font-size: 18px;
}

.constTitle {
    font-family: 'HelveticaNeueCyr';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 38px;
    color: #272727;
    margin-top: 20px !important;
}

.form-label {
    background:#e4e4e4;
    color: #495058;
    padding: 6px;
    border-radius: 4px;
    margin-right: 8px;
}

.elementMargin {
    margin-bottom: 12px;
}

::v-deep {
    .cellMarker {
        background: rgba(56, 142, 60, 0.25);
        padding: 2px;
        margin: 1px;
    }
    .prev_period {
        background: rgb(178, 217, 253);
    }
    .grey-background {
        background: #EAEAEA !important;
    }
}

.cellMarkerStyles {
    font-family: 'HelveticaNeueCyr';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    color: #495058;
}

.customWeight {
    font-weight: 700 !important;
    text-decoration: none;
}

::v-deep .p-dialog {
    width: 480px !important;

    .p-dialog-header {
        padding: 24px;

        .p-dialog-header-icon:focus {
            box-shadow: none;
        }

        h3 {
            font-size: 18px;
            color: #272727;
        }
    }

    .p-dialog-content {
        padding: 0 24px;

        label {
            font-size: 13px;
            line-height: 16px;
            color: #272727;
            margin-bottom: 4px;
            display: block;
        }

        .p-field {
            margin-bottom: 0;
        }

        .errorPos {
            position: relative;
        }

        .errorPosition {
            position: absolute;
            left: 0;
            bottom: 2px;
        }

        .message {
            margin-bottom: 16px;
        }

        .p-fileupload-content {
            min-height: 40px;
            padding: 10px;

            .p-fileupload-row {
                div {
                    padding: 7px;
                }

                div:first-child {
                    width: 65% !important;
                }
            }
        }

        .p-fileupload-buttonbar {
            padding: 10px;

            span:nth-child(1) {
                margin-right: 12px;
            }

            button:nth-child(2) {
                display: none !important;
            }

            .p-button {
                box-shadow: none;
            }
        }
    }

    .p-inputtext,
    .p-dropdown {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
    }

    .p-dropdown {
        .p-inputtext {
            margin-bottom: 0;
        }
    }

    .p-dialog-footer {
        padding: 0 24px 24px 24px;

        button {
            margin: 0 12px 0 0;
        }
    }

    .p-pr8 {
        padding-right: 8px;
    }

    .p-pl8 {
        padding-left: 8px;
    }
}

::v-deep .layersDialog {
    width: 56.875vw !important;
    max-width: 728px !important;

    table {
        border-collapse: separate;
        border-spacing: 0 0;
    }

    .p-datatable {
        margin-bottom: 16px;
    }

    .p-datatable-header {
        padding: 0 !important;
        border: none;
        position: relative;

        .cellinformation {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #495058;
            margin-left: 16px;
        }

        .table-header {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #495058;
            bottom: -28px;
            left: 41px;
            position: absolute;
        }
    }

    .p-datatable-thead {
        height: 37px !important;

        tr {
            th {
                background: #ffffff !important;
                border: none;
            }
        }
    }

    .p-datatable-tbody {
        margin-top: 37px !important;

        tr:first-child td:first-child {
            border-top-left-radius: 3px !important;
        }

        tr:first-child td:last-child {
            border-top-right-radius: 3px;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: 3px;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: 3px;
        }

        tr:last-child td {
            border-bottom: 1px solid #e4e4e4;
        }

        tr {
            height: 37px !important;

            td {
                border-top: 1px solid #e4e4e4;
                border-bottom: none;
            }

            td:first-child {
                border-left: 1px solid #e4e4e4;
            }

            td:nth-child(2) {
                position: relative;
                border-right: 1px solid #e4e4e4;
            }

            td:last-child {
                border-right: 1px solid #e4e4e4;
            }

            .imgFrame {
                position: absolute;
                top: calc(50% - 10px);
                left: 0;
            }
        }
    }
}

::v-deep .customDialogSettings {
    width: 800px !important;

    .p-dialog-content {
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;

        label {
            margin-bottom: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #495057;
        }
    }

    .p-dialog-footer {
        padding: 0 14px 20px;
    }
}

::v-deep .escapeDialog {
    width: 640px !important;

    .p-dialog-header {
        padding: 24px 0 0 0;
    }

    .p-dialog-content {
        padding: 0 12px 0;
    }

    .p-dialog-footer {
        padding: 24px 12px 24px;
        display: flex;
        justify-content: flex-end;
    }
}

::v-deep .p-dialog {
    .p-dialog-content {
        .descrArea {
            border: 1px solid #eaeaea;
            border-radius: 3px;
            display: block;
            padding: 8px 12px;
            margin-bottom: 16px;
            min-height: 80px;
            max-height: 80px;
            overflow-y: auto;
            flex-wrap: wrap;
            justify-content: flex-start;

            overflow-wrap: break-word;
            width: 432px;

            .cellMarker {
                display: inline-block !important;
            }
        }

        .descrAreaTable {
            display: block;

            overflow-y: auto;
            flex-wrap: wrap;
            justify-content: flex-start;
            overflow-wrap: break-word;
            width: 100%;

            .cellMarker {
                display: inline-block !important;
            }
        }
    }
}

.descrArea:focus-visible {
    outline: none;
}

.minusMargin {
    margin: -20px -16px;
    background-color: #eaeaea;
}

.minusMargin2 {
    background-color: #eaeaea;
}

.notShow {
    display: none !important;
}

::v-deep .header {
    background-color: #dcdcdc;
    color: black;
}
::v-deep .blocked-header.header {
    background-color: #fff;
}

::v-deep .bold-style {
    font-weight: bold;
}

::v-deep .italic-style {
    font-style: italic;
}

::v-deep .strikethrough-style {
    text-decoration: line-through;
}

::v-deep .underline-style {
    text-decoration: underline;
}

::v-deep .burgundy-color-style {
    color: #980000;
}

::v-deep .red-color-style {
    color: #ff0000;
}

::v-deep .orange-color-style {
    color: #ff9900;
}

::v-deep .yellow-color-style {
    color: #ffff00;
}

::v-deep .green-color-style {
    color: #00ff00;
}

::v-deep .green-color-style {
    color: #00ff00;
}

::v-deep .turquoise-color-style {
    color: #00ffff;
}

::v-deep .blue-color-style {
    color: #4a86e8;
}

::v-deep .darkblue-color-style {
    color: #0000ff;
}

::v-deep .violet-color-style {
    color: #9900ff;
}

::v-deep .purple-color-style {
    color: #ff00ff;
}

::v-deep .burgundy-background-style {
    background-color: #dd7e6b;
}

::v-deep .red-background-style {
    background-color: #ea9999;
}

::v-deep .orange-background-style {
    background-color: #f9cb9c;
}

::v-deep .yellow-background-style {
    background-color: #ffe599;
}

::v-deep .green-background-style {
    background-color: #b6d7a8;
}

::v-deep .turquoise-background-style {
    background-color: #a2c4c9;
}

::v-deep .blue-background-style {
    background-color: #a4c2f4;
}

::v-deep .darkblue-background-style {
    background-color: #9fc5e8;
}

::v-deep .violet-background-style {
    background-color: #b4a7d6;
}

::v-deep .purple-background-style {
    background-color: #d5a6bd;
}

::v-deep .header .burgundy-background-style {
    background-color: #cc4125;
}

::v-deep .header .red-background-style {
    background-color: #e06666;
}

::v-deep .header .orange-background-style {
    background-color: #f6b26b;
}

::v-deep .header .yellow-background-style {
    background-color: #ffd966;
}

::v-deep .header .green-background-style {
    background-color: #93c47d;
}

::v-deep .header .turquoise-background-style {
    background-color: #76a5af;
}

::v-deep .header .blue-background-style {
    background-color: #6d9eeb;
}

::v-deep .header .darkblue-background-style {
    background-color: #6fa8dc;
}

::v-deep .header .violet-background-style {
    background-color: #8e7cc3;
}

::v-deep .header .purple-background-style {
    background-color: #c27ba0;
}

::v-deep .header .blocked-header .burgundy-background-style {
    background-color: #e6b8af;
}

::v-deep .header .blocked-header .red-background-style {
    background-color: #f4cccc;
}

::v-deep .header .blocked-header .orange-background-style {
    background-color: #fce5cd;
}

::v-deep .header .blocked-header .yellow-background-style {
    background-color: #fff2cc;
}

::v-deep .header .blocked-header .green-background-style {
    background-color: #d9ead3;
}

::v-deep .header .blocked-header .turquoise-background-style {
    background-color: #d0e0e3;
}

::v-deep .header .blocked-header .blue-background-style {
    background-color: #c9daf8;
}

::v-deep .header .blocked-header .darkblue-background-style {
    background-color: #cfe2f3;
}

::v-deep .header .blocked-header .violet-background-style {
    background-color: #d9d2e9;
}

::v-deep .header .blocked-header .purple-background-style {
    background-color: #ead1dc;
}

::v-deep .style-10px {
    font-size: 10px;
}

::v-deep .style-12px {
    font-size: 12px;
}

::v-deep .style-14px {
    font-size: 14px;
}

::v-deep .style-16px {
    font-size: 16px;
}

::v-deep .style-18px {
    font-size: 18px;
}

::v-deep .style-20px {
    font-size: 20px;
}

::v-deep .style-22px {
    font-size: 22px;
}

::v-deep .style-24px {
    font-size: 24px;
}
</style>
