<template>
    <ScrollPanel class="customStyles custom">
        <Card v-for="(history, index) in worksheetHistory" :key="history.id">
            <template #content>
                <div :class="[history.id === version || index === version ? 'boldText' : '' ,
                    index % 2 !== 0 ? 'greyBg' : '', 'userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']">
                    <div class="p-d-flex p-flex-column">
                        <span>{{ history.date | dateTime }}</span>
                        <span>{{ history.name || 'Имя не указано' }}</span>
                    </div>
                    <button class="p-panel-header-icon p-link" @click.stop="showListMenu($event, history.id, `listMenu${ history.id }`)">
                        <span class="pi pi-ellipsis-h"></span>
                    </button>
                    <Menu id="history_menu" class="redLastListElement" :ref="`listMenu${ history.id }`" :model="listMenuItems" :popup="true" />
                </div>
            </template>
        </Card>
    </ScrollPanel>
</template>

<script>
import ScrollPanel from 'primevue/scrollpanel';

export default {
    name: 'history',

    props: {
        worksheetHistory: {
            require: true,
            type: Array
        },
        currentVersion: {
            type: String,
            default: '0'
        }
    },

    emits: ['restore'],

    data() {
        return {
            version: this.currentVersion,
            currentHistoryId: null,
            listMenuItems: [
                {
                    label: 'Восстановить',
                    icon: 'pi icon custom-status-change',
                    command: ($event) => {
                        $event.originalEvent.stopPropagation();
                        this.restore($event);
                    },
                },
            ],
        };
    },

    components: {
        ScrollPanel,
    },

    methods: {
        async showListMenu(event, listId, index) {
            Object.keys(this.$refs)
                .map((key) => {
                    this.$refs[`${ key }`][0] && this.$refs[`${ key }`][0].hide(event);
                });
            const menuEl = this.$refs[index][0];

            menuEl.toggle(event);
            await this.$nextTick();
            const { y } = event;
            const { $el } = menuEl;

            if ($el.style) {
                $el.style.top = `${ y - 110 }px`;
            }
            $el.style.left = '45px';
            this.currentHistoryId = listId;
        },

        restore() {
            this.$emit('restore', this.currentHistoryId);
            this.version = this.currentHistoryId;
        }
    },

    watch: {
        currentVersion: {
            handler(to) {
                if (to) {
                    this.version = this.currentVersion;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.customStyles {
    width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 10;
}

.boldText {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 125%;
}

::v-deep .p-scrollpanel {
    &.custom {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--surface-b);
            border-bottom: 9px solid var(--surface-b);
        }

        .p-scrollpanel-bar {
            background-color: red;
            border-radius: 0;
            opacity: 1;
            transition: background-color 0.2s;
        }
        .p-scrollpanel-bar-y {
            width: 6px;
        }
    }
}

.userContainer {
    align-items: start;
    padding: 8px;

    & .p-panel-header-icon {
        visibility: hidden;
    }

    &:hover {
        .p-panel-header-icon {
            visibility: visible;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: rgba(135, 148, 163, 0.25);
        }
    }
}

.greyBg {
    background: #f5f5f5;
}

.history-button {
    z-index: 1;
    margin-top: 8px;
    margin-right: 8px;
}

::v-deep .p-card {
    box-shadow: none;

    & .p-card-body {
        border-radius: 0;
        padding: 0 !important;
        border: 1px solid #eaeaea;
    }

    & .p-card-content {
        padding: 0 !important;
        font-size: 13px;
    }
}
</style>
