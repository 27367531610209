<template>
    <div class='p-col-12 p-lg-12 p-mb-lg-0 p-pt-0 flex-grow p-d-flex p-flex-column'>
        <h5 class='titleStyles'>Построитель формул</h5>
        <Dropdown
            v-model='selectedFormula'
            :disabled='!canInsert'
            :options='allFormuls'
            optionLabel='name'
            placeholder='Выберите формулу'
            :showClear='true'
            class='p-col-12 p-py-0 p-px-0 customDropDown'
        >
            <template #option='slotProps'>
                <div :title='slotProps.option.name'>{{ slotProps.option.name }}</div>
            </template>
        </Dropdown>

        <template>
            <div v-if="canInsert" class="p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0 p-d-flex p-flex-column">
                <Dropdown
                    v-show="selectedFormula && selectedFormula.code === 'period'"
                    v-model="selectedPeriod"
                    dataKey="code"
                    :options="allPeriods"
                    optionLabel="name"
                    placeholder="Выберите период"
                    :showClear="true"
                    class="p-col-12 p-py-0 p-px-0 customDropDown"
                >
                    <template #option='slotProps'>
                        <div>{{ slotProps.option.name }}</div>
                    </template>
                </Dropdown>
            </div>
            <div v-if="canInsert && selectedFormula" class='p-as-stretch formulaDescription p-pt-4'
                 v-html='formulaDescription(selectedFormula.code)'></div>
            <div v-else class='p-as-stretch formulaDescription p-pt-4'>
                Выбор формулы возможен только <b>в режиме построения формул</b>.<br><br>
                Выберите слой или добавьте новый для добавления формулы.
            </div>
            <div v-if='selectedFormula' class='p-d-flex p-jc-around'>
                <Button
                    class="p-button"
                    type="button"
                    :disabled="applyAvailable"
                    @click="saveFormulaVariant"
                >
                    <span class="p-button-label">Вставить</span>
                    <span class='p-ink'></span>
                </Button>
                <Button class='p-button p-button-outlined' @click='canselSelectedFormula'>
                    <span class='p-button-label black'>Отменить</span>
                </Button>
            </div>
            <insertOtherCells
                v-if='showSelectCellInOtherForm'
                :showSelectCellInOtherForm='showSelectCellInOtherForm'
                @showComponent='chengeShowInsertOtherCellComponent'
                @cellsSelect='cellsSelectInOtherForm'
                @cellsAndTagSelect='cellsAndTagSelect'
                :thisForm='selectedForm'
                :currentLayerType='currentLayerType'
                :templateYear='templateYear'
                :prevPeriodMode='isPrevPeriodMode'
                :accumulateMode='isAccumulateMode'
                :tagMode='isTagMode'
                :single-select-mode='isTagMode'
                :form-distributioninterval='formDistributioninterval'
            />
        </template>
    </div>
</template>

<script>
import { getAllIntervalPeriods } from '@/api/form/formsPeriods';
import insertOtherCells from '@/components/constructor/insertOtherCells.vue';
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'formulaBuilder',

    props: {
        selectedForm: {
            require: true,
            type: Object,
        },
        currentLayerType: {
            type: String,
        },
        templateYear: {
            type: Number,
            default: 2021,
        },
        formDistributioninterval: {
            type: String,
        },
        canInsert: {
            type: Boolean,
            default: false
        }
    },
    mixins: [requestToastHandler],
    data() {
        return {
            selectedFormula: null,
            showSelectCellInOtherForm: false,
            selectedObject: null,
            selectedTag: null,
            selectedPeriod: null,
            allOrganizationTags: null,
            allPeriods: null,
            selectedCells: null,
            // prevPeriodMode: false,
            allObjectsTypes: [
                { name: 'Массив тегов организации', code: 'getOrganizationTags' },
                { name: 'Тег организации', code: 'orgTag' },
                { name: 'Период', code: 'period' },
            ],
            filteredTags: null,
        };
    },
    methods: {
        async getPeriods(periodicityId) {
            try {
                this.$emit('loadingChange', true);
                this.allOrganizationTags = null;
                const { data: allIntervalPeriods } = await getAllIntervalPeriods({ intervalId: periodicityId });
                this.allPeriods = allIntervalPeriods
                    .map((period) => {
                        period.attributes['activeFromTimeStamp'] = this.generateTimestamp(period.attributes.activeFrom);
                        return period;
                    })
                    .sort((a, b) => (a.attributes.activeFromTimeStamp < b.attributes.activeFromTimeStamp ? 1 : -1))
                    .filter((period) => period.attributes.active)
                    .map((period) => {
                        return { name: period.attributes.name, code: period.id };
                    });
            } catch (error) {
                this.$requestError(error.message);
                return;
            } finally {
                this.$emit('loadingChange');
            }
        },
        searchTags(event) {
            if (!event.query.trim().length) {
                this.filteredTags = [...this.allOrganizationTags];
            } else {
                this.filteredTags = this.allOrganizationTags.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },
        selectFormInOtherForm() {
            this.showSelectCellInOtherForm = true;
        },
        cellsSelectInOtherForm({ cells, delimiter }) {
            this.selectedCells = cells;
            this.delimiter = delimiter || null;
            if (this.selectedFormula.code === 'cellwitchOtherForm' && this.selectedCells.length > 0) {
                this.$emit('insert-cells-from-form', { cells: this.selectedCells, delimiter: this.delimiter });
            } else if (this.isPrevPeriodMode && this.selectedCells.length > 0) {
                this.$emit('insert-cells-from-form', { cells: this.selectedCells, delimiter: this.delimiter });
            } else if (this.isAccumulateMode && this.selectedCells.length > 0) {
                this.$emit('insert-cells-from-form', { cells: this.selectedCells, delimiter: this.delimiter });
            }
            this.canselSelectedFormula();
        },
        cellsAndTagSelect({ cells, tag }) {
            this.selectedCells = cells;
            this.selectedTag = tag;
            if (this.selectedCells.length > 0) {
                this.$emit('insert-get-by-tag', { cells: this.selectedCells, tag: this.selectedTag })
            }
            this.canselSelectedFormula();
        },
        chengeShowInsertOtherCellComponent(value) {
            if (!value && !this.selectedCells) this.canselSelectedFormula();

            this.showSelectCellInOtherForm = value;
        },
        saveFormulaVariant() {
            if (this.selectedFormula.code === 'orgTag') {
                this.selectFormInOtherForm();
            }
            else if (this.selectedFormula.code === 'getOrganizationTags') {
                this.$emit('formulaVarintSelect', 'getOrganizationTags()');
                this.canselSelectedFormula();
            }
            else if (this.selectedFormula.code === 'period') {
                const periodString = this.selectedPeriod ? `period('${this.selectedPeriod ? this.selectedPeriod.code : ''}')` : 'period()';
                this.$emit('formulaVarintSelect', periodString);
                this.canselSelectedFormula();
            } else if (this.selectedFormula.code === 'cellwitchOtherForm') {
                this.selectFormInOtherForm();
            } else if (this.isPrevPeriodMode) {
                this.selectFormInOtherForm();
            } else if (this.isAccumulateMode) {
                this.selectFormInOtherForm();
            } else {
                this.$emit('formulaVarintSelect', this.selectedFormula.name);
                this.canselSelectedFormula();
            }
        },
        formulaDescription(descrCode) {
            if (!descrCode) {
                return 'Выбор формулы возможен только <b>в режиме построения формул</b>.<br><br>Выберите слой или добавьте новый для добавления формулы.';
            }
            let allFormulsDescr = {
                in_array:
                    '<b>in_array</b><br/><i>Проверяет наличие первого аргумента среди остальных аргументов</i><br/><b>1:</b> искомое значение;<br/><b>2:</b> массив значений, среди которых осуществляется поиск;<br/><b>Результат:</b> ИСТИНА/ЛОЖЬ<br/><br/>Пример:<br/><pre>in_array(889, [12,66,71,12])</pre> результатом функции будет ЛОЖЬ т.к. в массиве отсутствует значение 889',

                count: '<b>count</b><br/><i>Вычисляет количество элементов массива</i><br/><b>1:</b> массив значений 1;<br/><b>Результат:</b> ЧИСЛО (целое)<br/><br/>Пример:<br/><pre>count([8,11,51,9,14,44])</pre> результатом функции будет <b>6</b> т.к. массив содержит 6 значений',
                count_periods: '<b>count_periods</b><br/><i>Возвращает 1</i><br/><br/><b>Результат:</b> ЧИСЛО (целое)<br/><br/>',
                cellwitchEarlyPeriod:
                    '<b>prev_period</b><br/><i>Получает значение ячейки из форм с аналогичной периодичностью в предыдущем периоде</i><br/><b>1:</b> ячейка;<br/><b>Результат:</b> ЧИСЛО/СТРОКА<br/><br/>Пример:<br/><pre>prev_period<br/><br/>(лист Worksheet:грф.3: стр.2)</pre>результатом функции будет<br/>значение выбранной ячейки в<br/>предыдущем периоде',
                cellwitchAccumulate:
                    '<b>accumulate</b><br/><i>Получает значение ячейки из форм с аналогичной периодичностью за предыдущие периоды в рамках года</i><br/><b>1:</b> ячейка;<br/><b>Результат:</b> ЧИСЛО/СТРОКА<br/><br/>Пример:<br/><pre>accumulate<br/><br/>(лист Worksheet:грф.3: стр.2)</pre>результатом функции будет<br/>сумма значений по указанной ячейке<br/>от начала года до текущего периода включительно',
                current_period_days:
                    '<b>current_period_days</b><br/><i>Получает количество календарных дней в текущем периоде</i><br/><b>Результат:</b> ЧИСЛО<br/><br/>Пример:<br/><pre>current_period_days()</pre> результатом функции будет число календарных дней в текущем периоде',
                currentMonthDays:
                    '<b>currentMonthDays</b><br/><i>Возвращает количество дней в месяце текущего периода раздачи (определяется по месяцу даты начала периода).</i><br/><b>Результат:</b> ЧИСЛО<br/><br/>Пример:<br/><pre>currentMonthDays()</pre> результатом функции будет число календарных дней  в месяце текущего периода раздачи',
                modulusRemainder:
                    '<b>modulusRemainder</b><br/><i>Проверяет что number делится без остатка на divisor</i><br/><b>Результат:</b> True/False<br/><br/>Пример:<br/><pre>modulusRemainder(5,2)</pre> результатом функции будет False, так как 5 не делится на 2 без остатка',
                cellwitchOtherForm: '<i>Для вставки выбранной ячейки из другой формы с любой периодичностью, поставьте курсор в нужное место слоя и нажмите <b>Вставить</b>.</i><br><br>Если выбираете форму с другой периодичностью, то значения будут суммироваться, если дата конца попадает в период формы.',
                getOrganizationTags: '<b>getOrganizationTags</b><br/><i>возвращает теги организации в текущем году периода раздачи (определяется по месяцу даты начала периода), а так же теги без указания года.</i>',
                orgTag: '<b>getByTag</b><br/><i>возвращает значение ячейки по shared_id, если организация отмечена тегом, или ноль.</i>',
                period: '<b>period</b><br><i>возвращает объект периода по указанному id или текущего периода раздачи.</i>',
            };
            return allFormulsDescr[descrCode];
        },
        canselSelectedFormula() {
            this.selectedFormula = null;
            this.selectedTag = null;
            this.selectedPeriod = null;
            this.allOrganizationTags = null;
            this.allPeriods = null;
            this.selectedCells = null;
        },
        generateTimestamp(dateValue) {
            let date = new Date(dateValue);
            return +date;
        },
    },
    computed: {
        isTagMode() {
            return this.selectedFormula?.code === 'orgTag'
        },
        isPrevPeriodMode() {
            return this.selectedFormula?.code === 'cellwitchEarlyPeriod'
        },
        isAccumulateMode() {
            return this.selectedFormula?.code === 'cellwitchAccumulate'
        },
        selectedFormulaShowDescr() {
            if (this.selectedFormula && this.selectedFormula.code === 'orgTag') {
                return !!(this.selectedFormula && this.selectedCells);
            }

            return !!this.selectedFormula;
        },
        allFormuls() {
            if (this.currentLayerType === 'check')
                return [
                    { name: 'in_array(Что ищем,[массив])', code: 'in_array' },
                    { name: 'modulusRemainder(Что делим, На что делим)', code: 'modulusRemainder' },
                    { name: 'Выбрать ячейку(ки) из другой таблицы/формы', code: 'cellwitchOtherForm' },
                    { name: 'Тег организации', code: 'orgTag' },
                ];
            if (this.currentLayerType === 'calc')
                return [
                    { name: 'in_array(Что ищем,[массив])', code: 'in_array' },
                    { name: 'count_periods()', code: 'count_periods' },
                    { name: 'current_period_days()', code: 'current_period_days' },
                    { name: 'currentMonthDays()', code: 'currentMonthDays' },
                    { name: 'modulusRemainder(Что делим, На что делим)', code: 'modulusRemainder' },
                    { name: 'Выбрать ячейку(ки) из другой таблицы/формы', code: 'cellwitchOtherForm' },
                    { name: 'Выбрать ячейку за предыдущий период', code: 'cellwitchEarlyPeriod' },
                    { name: 'Выбрать ячейку для накопительного итога', code: 'cellwitchAccumulate' },
                    { name: 'Массив тегов организации', code: 'getOrganizationTags' },
                    { name: 'Тег организации', code: 'orgTag' },
                    { name: 'Период', code: 'period' }
                ];
            return [
                { name: 'Войдите в режим построения формул', code: 'in_array' }
            ];
        },
        applyAvailable() {
            if (!this.selectedFormula) {
                return true;
            }
            if (this.selectedFormula.code === 'getOrganizationTags') {
                return false;
            }
            if (this.selectedFormula.code === 'orgTag') {
                return false;
            }
            if (this.selectedFormula.code === 'period') {
                return false;
            }
            if (this.selectedFormula.code === 'cellwitchOtherForm') {
                return false;
            }
            if (this.selectedFormula.code === 'cellwitchEarlyPeriod') {
                return false;
            }
            if (this.selectedFormula.code === 'cellwitchAccumulate') {
                return false;
            }
            const checkArray = ['in_array', 'modulusRemainder', 'count_periods', 'current_period_days', 'currentMonthDays'];
            if (checkArray.includes(this.selectedFormula.code)) {
                return false;
            }
            return true;
        },
    },
    watch: {
        selectedFormula: {
            handler: async function() {
                if (this.selectedFormula && this.selectedFormula.code === 'period') {
                    await this.getPeriods(this.formDistributioninterval);
                }
                this.selectedCells = null;
            },
            deep: true,
        },
        showSelectCellInOtherForm: {
            handler(to) {
                this.$emit('unlock-cursor', to);
            },
        },
    },
    components: {
        insertOtherCells,
    },
};
</script>

<style lang='scss' scoped>
.titleStyles {
    font-family: 'HelveticaNeueCyr';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 19px;
    color: #495058;
    margin-top: 22px;
    margin-bottom: 17px;
}

.div {
    width: 100px;
    height: 100px;
}

::v-deep .customDropDown {
    margin-bottom: 16px;
    border: 1px solid #eaeaea;
    border-radius: 3px;

    .p-dropdown-items-wrapper {
        max-height: 350px !important;
        width: 225px !important;

        .p-dropdown-item {
            div {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .p-inputtext {
        border: none !important;
    }

    .p-button-icon-only {
        background: transparent !important;
        color: #8c8c8c !important;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: none;
    }
}

.formulaDescription {
    max-height: 100%;
    flex-grow: 1;
    padding-top: 0 !important;
}

.flex-grow {
    flex-grow: 1;
}
</style>
