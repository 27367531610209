<template>
    <Dialog
        :visible.sync="insertOtherCellShow"
        position="center"
        :modal="true"
        :closeOnEscape="true"
        :closable="true"
        class="customDialogCells"
    >
        <template #header>
            <h3 class="p-mb-0 customTitle">Вставить ссылку на ячейку</h3>
        </template>
        <!-- <hr class="p-mt-0" /> -->
		<div class="p-col-12 p-lg-12 p-mb-lg-0 p-pt-0 p-d-flex p-jc-start p-pl-0 p-pt-1">
            <Dropdown
                v-model="selectedForm"
                :options="allForms"
                class="p-col-3 p-py-0 p-px-0 p-mr-4 maxWidth"
                optionLabel="name"
                placeholder="Выберите форму"
                @change="formSelect"
                dataKey="code"
                :filter='true'
            >
                <template #option="slotProps">
                    <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                    </div>
                </template>
            </Dropdown>
			<Dropdown
				v-model='selectedTemplate'
				:disabled='true'
				:options='allFormTemplates'
				class='p-col-3 p-py-0 p-px-0 p-mr-4 maxWidth'
				optionLabel='name'
				placeholder='Выберите шаблон'
				@change='templateSelect'
				dataKey='code'
			>
				<template #option='slotProps'>
					<div class='country-item'>
						<div>{{ slotProps.option.name }}</div>
					</div>
				</template>
			</Dropdown>
			<Dropdown
				v-model="selectedWorksheet"
				:disabled="!selectedTemplate"
				:options="allWorksheets"
				class="p-col-3 p-py-0 p-px-0 p-mr-4 maxWidth"
				optionLabel="name"
				placeholder="Выберите лист"
				@change="worksheetSelect"
				dataKey="code"
			>
                <template #option="slotProps">
                    <div class="country-item">
                        <div>{{ slotProps.option.name }}</div>
                    </div>
                </template>
            </Dropdown>
            <Dropdown
                v-if='prevPeriodMode'
                placeholder='Предыдущий период'
                disabled
                class="p-col-3 p-py-0 p-px-0 p-mr-4 maxWidth"
            />
            <Dropdown
				v-if='tagMode'
                v-model="selectedTag"
                :options="allTags"
                ref='tagsSelect'
                class="p-col-3 p-py-0 p-px-0 p-mr-4 maxWidth"
                placeholder="Выберите тег"
				filter
                editable
                @keyup.enter.native.prevent='saveSelectedTag'
            >
<!--                <template #option="slotProps">-->
<!--                    <div class="country-item">-->
<!--                        <div>{{ slotProps.option.name }}</div>-->
<!--                    </div>-->
<!--                </template>-->
            </Dropdown>
        </div>
		<div
			class='card'
			:class='{ hideElem: !showTable }'
		>
			<hot-table :settings='hotSettings' :language='language' ref='myTable'></hot-table>
		</div>
        <div class="card p-pt-3 p-pl-0 p-mt-0" :class="{ hideElem: showTable }">
            <p v-if="templateNotFound">За {{ templateYear }} год шаблон не найден.</p>
            <p v-else >Выберите форму, шаблон и лист.</p>
        </div>

        <!-- <hr class="p-mt-0" /> -->
        <template #footer>
            <div class="p-d-flex p-jc-start customMargin">
                <Button type="button" @click="cellsSelect" :disabled="disableInsert">
                    <span class="p-button-label">Вставить</span>
                    <span class="p-ink"></span>
                </Button>
                <Button class="p-button p-button-outlined" @click="insertOtherCellShow = false">
                    <span class="p-button-label black">Отменить</span>
                </Button>
                <delimiter-select
                    v-if='showDelimiterVariants'
                    v-model='selectedDelimiter'
                />
            </div>
        </template>
		<div class='p-datatable-loading-overlay p-component-overlay' v-if='loading'>
			<img src='@/assets/img/spiner-loader.png' alt='loading' />
		</div>
    </Dialog>
</template>

<script>
import { HotTable } from '@handsontable/vue';
import delimiterSelect from '@/components/constructor/delimiterSelect';
import { getTemplateData, getFormTemplates, getWorkSheetCells } from '@/api/form/formsTemplates';
import mockDataTableDataArray from '@/utils/mockDataTableDataArray';
import { requestToastHandler } from '@/main/mixins';
import { v4 as uuidv4 } from 'uuid';
import { getAllTags } from '@/api/organization/orgsTags';
import { jsonApiListParser } from '@/main/utils/common';
import { getAllForms } from '@/api/form';

export default {
    name: 'insertOtherCells',
    mixins: [requestToastHandler],
    props: {
        thisForm: {
            type: Object,
            required: true,
        },
        formDistributioninterval: {
            type: String,
        },
        showSelectCellInOtherForm: {
            type: Boolean,
            required: true,
        },
        currentLayerType: {
            type: String,
        },
        prevPeriodMode: {
            type: Boolean,
            default: false,
        },
        accumulateMode: {
            type: Boolean,
            default: false,
        },
        tagMode: {
            type: Boolean,
            default: false,
        },
        templateYear: {
            type: Number,
        },
        singleSelectMode: {
            type: Boolean,
            default: false
        }
    },
    emits: ['showComponent', 'cellsSelect'],
    data() {
        return {
            selectedDelimiter: '+',
            insertOtherCellShow: true,
            selectedForm: null,
            templateNotFound: false,

            allForms: [],
            allFormsFullInfo: [],
            loading: false,

            checkedCells: [],

            selectedTemplate: null,
            allFormTemplates: [],
            allFormsTemplatesFullInfo: [],
            selectedWorksheet: null,
            saveCells: [],
            allFormLists: [],
            selectedPeriod: null,
			selectedTag: null,
            allTags: [],

            allWorksheets: [],
            showTable: false,

            cellsDataFromTable: [],
            transformData: [],
            mergeCellsArray: [],

            curentColl: 0,
            curentRow: 0,
            endRowCurentDiapazon: 0,
            endCollCurentDiapazon: 0,

            choiseCellsArray: [],

            hotSettings: {
                data: [],
                colHeaders: true,
                rowHeaders: true,
                fillHandle: false,

                width: '100%',

                selectionMode: 'single', // 'single', 'range' or 'multiple',

                stretchH: 'none',
                licenseKey: 'non-commercial-and-evaluation',

                afterSelection: (row, column, row2, column2) => {
                    // if (this.curentRow !== row || this.curentColl !== column) this.openLayers = false;

                    //ячейка выбрана
                    this.curentRow = row;
                    this.curentColl = column;
                    this.endRowCurentDiapazon = row2;
                    this.endCollCurentDiapazon = column2;

                    if (row >= 0 && column >= 0) {
                        this.addCellInCheckedCells(row, column);
                        if (this.singleSelectMode) {
                            const lastElem = this.checkedCells.at(-1)
                            this.checkedCells = lastElem ? [lastElem] : [];
                            this.$refs.myTable.hotInstance.updateSettings({})
                        }
                    }
                    if (this.curentRow !== this.endRowCurentDiapazon || this.curentColl !== this.endCollCurentDiapazon) {
                        this.$refs.myTable.hotInstance.deselectCell();
                    }
                },

                afterRenderer: (td, row, col, prop, value, cellProperties) => {
                    cellProperties.id = this.cellsDataFromTable[row][col].id;
                    cellProperties.head = this.cellsDataFromTable[row][col].head;
                    cellProperties.sharedId = this.cellsDataFromTable[row][col].attributes?.sharedId;

                    cellProperties.className = this.cellsDataFromTable[row][col].className.join(' ');

                    cellProperties.readOnly = this.cellsDataFromTable[row][col].readOnly;

                    if (this.curentRow === row && this.curentColl === col) {
                        cellProperties.className = `${this.cellsDataFromTable[row][col].className.join(' ')} choiseCell`;
                    }
                    cellProperties.editor = false;
                    // if (this.hoverCellCoords.row == row && this.hoverCellCoords.col == col) {
                    //     cellProperties.className = `${this.cellsDataFromTable[row][col].className.join(' ')} hoverCell`;

                    //     // console.log(this.choiseCellsArray);
                    // }
                    if (this.checkedCells.length > 0) {
                        this.checkedCells.map((markCell) => {
                            if (markCell.col === col && markCell.row === row && markCell.id === cellProperties.sharedId) {
                                cellProperties.className = `${this.cellsDataFromTable[row][col].className.join(' ')} yellowsubmarine`;
                            }
                        });
                        // console.log(this.checkedCells);
                    }
                    // if (this.curentRow == row && this.curentColl == col && this.formulaBuilding) {
                    //     cellProperties.className = `${this.cellsDataFromTable[row][col].className.join(' ')} buildFormula`;
                    //     cellProperties.readOnly = true;
                    // }
                    // if (this.cellsDataFromTable[row][col].layers.length > 1) {
                    //     //получаем длинну элемента чтобы определить сколько кнопок в него влезет
                    //     let curentCountButtons = Math.floor(td.offsetWidth / 33) > this.cellsDataFromTable[row][col].layers.length ? this.cellsDataFromTable[row][col].layers.length : Math.floor(td.offsetWidth / 33) - 1;
                    //     const elem = this.createLayersButtonGroup(curentCountButtons, this.cellsDataFromTable[row][col].layers, row, col);
                    //     td.appendChild(elem);
                    // }
                },
            },
            language: 'en-US',
        };
    },
    methods: {
        async saveSelectedTag() {
            this.$refs.tagsSelect.hide()
            const input = this.$refs.tagsSelect.$el.querySelector('input.p-dropdown-label')
            input.blur()
        },
        addCellInCheckedCells(row, column) {
            let haveDuplicate = this.checkedCells.findIndex((cell) => cell.col === column && cell.row === row && cell.id === this.cellsDataFromTable[row][column].attributes.sharedId);
            if (haveDuplicate === -1) {
                const form = this.allFormsFullInfo.find((form) => form.id === this.selectedForm.code);
                const template = this.allFormsTemplatesFullInfo.find((template) => template.id === this.selectedTemplate.code);
                const list = this.allWorksheetsullInfo.find((list) => list.id === this.selectedWorksheet.code);

                const checkedCell = {
                    form,
                    template,
                    list,
                    cellInfo: this.saveCells.find((cell) => cell.sharedId === this.cellsDataFromTable[row][column].attributes.sharedId),
                    formInfo: { id: form.id, name: form.attributes.name },
                    templateInfo: { id: template.id, name: template.attributes.name },
                    listInfo: {
                        id: list.id,
                        name: list.attributes.name,
                        coordinates: {
                            rowLabels: list.attributes.properties?.rowLabels || [],
                            columnLabels: list.attributes.properties?.columnLabels || []
                        }
                    },
                    col: column,
                    row: row,
                    id: this.cellsDataFromTable[row][column].attributes.sharedId,
                    periodInfo: { id: this.selectedPeriod && this.selectedPeriod.code, name: this.selectedPeriod && this.selectedPeriod.name },
                    prevPeriod: this.prevPeriodMode,
                    accumulate: this.accumulateMode,
                    otherForm: true,
                };

                this.checkedCells.push(checkedCell);
                this.$refs.myTable.hotInstance.updateSettings({ language: 'en-US' });
                return;
            }
            this.checkedCells.splice(haveDuplicate, 1);
            this.$refs.myTable.hotInstance.updateSettings({ language: 'en-US' });
        },
        async worksheetSelect() {
            try {
                this.showTable = false;
                this.loading = true;
                const { data: cellArray, included } = await getWorkSheetCells(this.selectedWorksheet.code);
                this.cellsDataFromTable = this.agregateCells(cellArray, included);
                included.forEach((curItem) => {
                    if (curItem.type === 'form-template-cell') this.saveCells.push(curItem);
                });
                setTimeout(() => {
                    this.$refs.myTable.hotInstance.updateSettings({ language: 'en-US' });
                }, 50);
                this.updateData();
                this.showTable = true;
                this.loading = false;
            } catch (error) {
                this.$requestError(error.message);
                this.loading = false;
            }
        },
        async templateSelect() {
            try {
                this.showTable = false;
                this.selectedWorksheet = null;
                this.loading = true;
                let { included } = await getTemplateData(this.selectedTemplate.code);
                included = included.filter((template) => template.type == 'form-template-worksheet');
                this.allWorksheetsullInfo = included;
                this.allWorksheets = this.convertValuesForDropdown(included);

                this.loading = false;
            } catch (error) {
                this.$requestError(error.message);
                this.loading = false;
            }
        },
        async formSelect() {
            // console.log('this.selectedForm.coder', this.selectedForm.code);
            try {
                this.showTable = false;
                this.selectedTemplate = null;
                this.selectedWorksheet = null;
                // this.allTags = [];
                this.loading = true;

                const { data: allFormTemplates } = await getFormTemplates({
                    formId: this.selectedForm.code,
                    relationShip: true,
                    filter: { year: this.templateYear, active: true }
                });

                if (allFormTemplates.length) {
                    this.templateNotFound = false;
                    this.allFormsTemplatesFullInfo = allFormTemplates;
                    this.allFormTemplates = this.convertValuesForDropdown(allFormTemplates);

                    if (this.allFormTemplates) {
                        this.selectedTemplate = this.allFormTemplates[0];
                        await this.templateSelect();
                    }
                } else {
                    this.templateNotFound = true;
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.loading = false;
            }
        },

        async getForms() {
            this.cleareStore();
            this.loading = true;
            try {
                const filter = {
                    active: { '$eq': true }
                }
                if (this.prevPeriodMode) {
                    filter.distributionIntervalId = {
                        $eq: this.formDistributioninterval
                    }
                }
                const result = await getAllForms({
                    relationShip: true,
                    filter
                });
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.allFormsFullInfo = result.forms;
                this.allForms = this.convertValuesForDropdown(result.forms, true);
                this.selectedForm = this.thisForm;
                this.formSelect();

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$requestError(error.message);
                return;
            }
        },
        convertValuesForDropdown(array, flag) {
            return array.map((form) => {
                return {
                    name: form.attributes.name,
                    code: form.id,
                    distributionIntervalId: flag ? form.relationships.distributionInterval?.data.id : null,
                };
            });
        },
        cellsSelect() {
            const emitName = this.tagMode ? 'cellsAndTagSelect' : 'cellsSelect'
            const emitData = this.tagMode ?
                { cells: this.checkedCells, tag: this.selectedTag } :
                { cells: this.checkedCells, delimiter: this.showDelimiterVariants && this.selectedDelimiter }
            this.$emit(emitName, emitData);
            this.insertOtherCellShow = false;
            this.cleareStore();
        },
        updateData() {
            this.transformData = this.getTableHeaders(this.cellsDataFromTable);
            this.$refs.myTable.hotInstance.updateSettings({
                data: this.transformData,
                mergeCells: this.mergeCellsArray,
            });
        },

        getTableHeaders(cellsDataFromTable) {
            let headers = [];

            cellsDataFromTable.map((cell) => {
                headers = [...headers, cell.map((data) => data.layers[0].layersDescr)];
            });
            return headers;
        },

        agregateCells(cellArray, included) {
            const modCellArray = cellArray
                .map((cell) => {
                    cell.attributes.row = cell.attributes.row - 1;
                    cell.attributes.column = cell.attributes.column - 1;
                    return cell;
                })
                .sort((a, b) => a.attributes.row - b.attributes.row);

            let rowsArray = [];

            // modCellArray[modCellArray.length - 1].attributes.row
            for (let index = 0; index <= modCellArray[modCellArray.length - 1].attributes.row; index++) {
                let row = [];

                cellArray.map((cell) => {
                    if (cell.relationships.layers.length) console.log('cell.relationships.layers', cell.relationships.layers);
                    let transformCell = {
                        id: cell.id,
                        head: false,
                        value: '',
                        readOnly: !cell.attributes.editable,
                        className: [],
                        layers: [
                            {
                                layerType: 'text',
                                layersDescr: cell.attributes.value,
                                hover: false,
                                id: uuidv4(),
                            },
                            ...cell.relationships.layers.data.map((layer) => {
                                let curentlLayer = included.filter((item) => item.id == layer.id);

                                let layerValue = curentlLayer[0].attributes.value;
                                // if (curentlLayer[0].attributes.type == 'check') layerValue = this.unConvertLayerDescr(curentlLayer[0].attributes.value, cellArray, included);

                                return {
                                    layerType: curentlLayer[0].attributes.type,
                                    layersDescr: layerValue,
                                    hover: false,
                                    id: curentlLayer[0].id,
                                };
                            }),
                        ],
                        attributes: {
                            sharedId: cell.attributes.sharedId,
                            row: cell.attributes.row,
                            column: cell.attributes.column,
                            rowSpan: cell.attributes.rowSpan,
                            columnSpan: cell.attributes.columnSpan,
                        },
                    };
                    //добавляем ячейки в массив мержей
                    if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                        let mergeRowCell = {
                            col: cell.attributes.column,
                            colspan: cell.attributes.columnSpan || 1,
                            row: cell.attributes.row,
                            rowspan: cell.attributes.rowSpan || 1,
                            value: cell.attributes.value,
                        };

                        const haveCopyMergeCell = this.mergeCellsArray.find((cell) => cell.col === mergeRowCell.col && cell.row === mergeRowCell.row);
                        // if (haveCopyMergeCell) console.log('ЕСТЬ КОПИЯ', cell, this.mergeCellsArray);
                        if (!haveCopyMergeCell) this.mergeCellsArray.push(mergeRowCell);
                    }

                    cell.attributes.row == index && row.push(transformCell);
                });

                rowsArray.push(row);
            }

            this.mergeCellsArray = this.mergeCellsArray.sort((a, b) => (a.row > b.row ? 1 : -1));
            rowsArray = rowsArray.map((row) => row.sort((a, b) => (a.attributes.column > b.attributes.column ? 1 : -1)));

            rowsArray.map((row) => {
                row.map((cell) => {
                    if (cell.attributes.rowSpan > 1 || cell.attributes.columnSpan > 1) {
                        // console.log('Есть мерж', cell.attributes.rowSpan, cell.attributes.columnSpan);
                        if (cell.attributes.rowSpan > 1) {
                            // console.log('Есть ровспан', cell.attributes.rowSpan);
                            for (let rowIndex = 0; rowIndex < cell.attributes.rowSpan; rowIndex++) {
                                // console.log('rowIndex', rowIndex);
                                if (rowIndex == 0) {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        // console.log('cellIndex', cellIndex);
                                        if (cellIndex == 0) continue;
                                        let mockCell = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, 0, cellIndex);
                                        rowsArray[cell.attributes.row].push(mockCell);
                                    }
                                } else {
                                    for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                        // console.log('cellIndex', cellIndex);
                                        let mockCell1 = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, rowIndex, cellIndex);

                                        rowsArray[cell.attributes.row + rowIndex].push(mockCell1);
                                    }
                                }
                            }
                        } else {
                            for (let cellIndex = 0; cellIndex < cell.attributes.columnSpan; cellIndex++) {
                                if (cellIndex == 0) continue;

                                let mockCell2 = this.generateMockCell(cell.readOnly, cell.attributes.row, cell.attributes.column, 0, cellIndex);

                                rowsArray[cell.attributes.row].push(mockCell2);
                            }
                        }
                    }
                });
            });

            rowsArray = rowsArray.map((row) => row.sort((a, b) => (a.attributes.column > b.attributes.column ? 1 : -1)));

            return rowsArray.filter((row) => row.length > 0);
        },
        cleareStore() {
            this.showTable = false;
            this.selectedForm = null;
            this.allForms = [];
            this.mergeCellsArray = [];
            this.checkedCells = [];
            this.selectedTemplate = null;
            this.allFormTemplates = [];
            this.selectedWorksheet = null;
            this.allFormLists = [];
            this.allWorksheets = [];
            this.cellsDataFromTable = mockDataTableDataArray;
            this.loading = false;
        },
        generateMockCell(editable, row, column, rowCoof = 0, columnCoof = 0) {
            return {
                type: 'form-template-cell',
                id: uuidv4(),
                mock: true,
                readOnly: editable,
                attributes: {
                    sharedId: uuidv4(),
                    row: row + rowCoof,
                    column: column + columnCoof,
                    rowSpan: 1,
                    columnSpan: 1,
                    value: '',
                    editable: false,
                },
                className: [],
                layers: [
                    {
                        layerType: 'text',
                        layersDescr: `ROW${row} ${column}`,
                        hover: false,
                        id: uuidv4(),
                    },
                ],
                relationships: {
                    layers: {
                        data: [],
                    },
                },
            };
        },

        createLayersButtonGroup(curentCountButtons, layersArray, row, col) {
            let layerIconsGroup = [];
            for (let index = 0; index < curentCountButtons; index++) {
                let layer = layersArray[index];

                let imgPath = `/assets/img/layersIcons/icon-${layer.layerType}.svg`;
                let layerIcon = `<span class="p-inputgroup-addon " data-layerIndex="${index}" data-row="${row}" data-col="${col}" data-layertype="${layer.layerType}">
                                <img src="${imgPath}" style="width: 21px; height: 21px" data-layerIndex="${index}" data-row="${row}" data-col="${col}" data-layertype="${layer.layerType}"/>
                            </span>`;
                layerIconsGroup.push(layerIcon);
            }
            if (layersArray.length > curentCountButtons) {
                let threeDots = `<span class="p-inputgroup-addon" style="padding-top: 5px" data-row="${row}" data-col="${col}">
                                ...
                            </span>`;
                layerIconsGroup.push(threeDots);
            }

            const elem = document.createElement('div');
            elem.innerHTML = `${[...layerIconsGroup].join('')}`;
            elem.classList.add('customLayerCellRender');
            return elem;
        },
    },

    components: {
        HotTable,
        delimiterSelect
    },
    computed: {
        disableInsert() {
            if (!this.selectedForm || !this.selectedWorksheet || !this.selectedTemplate || (this.tagMode && !this.selectedTag)) return true
            // if (this.tagMode) {
            //     return this.checkedCells.length == 1 ? false : true;
            // } else return this.checkedCells.length <= 0;
			return this.checkedCells.length <= 0;
        },
        showDelimiterVariants() {
            return this.checkedCells.length > 1
        }
    },
    watch: {
        // cellsDataFromTable: {
        //     handler: function () {
        //         this.updateData();
        //     },
        //     deep: true,
        // },
        insertOtherCellShow() {
            this.$emit('showComponent', this.insertOtherCellShow);
            this.cleareStore();
        },
        selectedPeriod() {
            this.checkedCells = [];
            this.$refs.myTable.hotInstance.updateSettings({ language: 'en-US' });
        },
    },
    async created() {
        this.cellsDataFromTable = mockDataTableDataArray;
        this.insertOtherCellShow = this.showSelectCellInOtherForm;

		if (this.tagMode) {
			const { data } = await getAllTags()
			const [parsedData] = jsonApiListParser(data)
			this.allTags = parsedData.tags
		}
    },

    async mounted() {
        await this.getForms();
        this.updateData();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    max-width: 500px;
    overflow: hidden;
}
.hideElem {
    display: none !important;
}
.maxWidth {
    width: 233px;
}
.card {
    padding: 0;
    min-height: 400px;
    flex: 1 0 auto;
    max-height: 450px;
    overflow: hidden;
    box-shadow: none;
}
.customTitle {
    font-size: 17px;
    font-weight: 500 !important;
}
.p-component-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}
.pi-spinner {
    font-size: 2rem;
}
::v-deep .customDialogCells {
    .p-dialog-footer {
        padding: 0;
        padding-left: 1rem;
        padding-bottom: 1rem;
    }
}
::v-deep .p-dialog.customDialogCells {
    width: 93.75vw !important;
    max-width: 1280px;
}
.customMargin {
    margin-top: 17px;
}
</style>
