<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            style="width: 500px"
            @update:visible="closeDialog"
        >
            <template #header>
                <h3 class="p-mb-0">{{ dialogTitle }}</h3>
            </template>
            <div class="p-d-flex p-flex-column p-col-12 p-lg-12 p-mb-lg-0 p-pt-0 p-px-0 p-py-0 errorPos">
                <label class="p-text-normal">Наименование*</label>
                <InputText
                    v-model="formData.name"
                    type="text"
                    :class="v$.formData.name.$invalid && submitted ? 'p-error' : ''"
                />
                <small
                    v-show="submitted"
                    v-for="error in showErrors(v$.formData.name)"
                    :key="error.$uid"
                    class="p-error errorPosition"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="p-d-flex p-flex-column p-col-12 p-lg-12 p-mb-lg-0 p-pt-0 p-px-0 p-py-0 errorPos">
                <label class="p-text-normal">Краткое наименование*</label>
                <InputText
                    v-model="formData.shortName"
                    type="text"
                    :class="v$.formData.shortName.$invalid && submitted ? 'p-error' : ''"
                />
                <small
                    v-show="submitted"
                    v-for="error in showErrors(v$.formData.shortName)"
                    :key="error.$uid"
                    class="p-error errorPosition"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div v-if="groups.length" class="p-col-12 p-lg-12 p-pt-0 p-px-0 p-mb-lg-0 p-d-flex p-flex-column">
                <label class="p-text-normal">Группа</label>
                <Dropdown
                    v-model="formData.groupId"
                    :options="groups.filter(group => group.id !== 'noId')"
                    filter
                    option-value="id"
                    option-label="name"
                    placeholder="Выберите группу"
                />
            </div>
            <div class="p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-0 p-py-0">
                <div v-if="dialogMode === 'CREATE'" class="p-fluid p-grid p-formgrid maxWidth p-mx-0">
                    <div class="p-field p-col-6 p-md-6 p-pr-0 p-pl-0 p-pr8 errorPos customWidth">
                        <label class="p-text-normal">Количество колонок*</label>
                        <InputNumber
                            v-model="formData.columnCount"
                            type="text"
                            :max="100"
                            :class="v$.formData.columnCount.$invalid && submitted ? 'p-error' : ''"
                        />
                        <small
                            v-show="submitted"
                            v-for="error in showErrors(v$.formData.columnCount)"
                            :key="error.$uid"
                            class="p-error errorPosition"
                        >
                            {{ error.$message }}
                        </small>
                    </div>

                    <div class="p-field p-col-6 p-md-6 p-pr-0 p-pl-0 errorPos customWidth">
                        <label class="p-text-normal">Количество строк*</label>
                        <InputNumber
                            v-model="formData.rowCount"
                            type="text"
                            :max="100"
                            :class="v$.formData.rowCount.$invalid && submitted ? 'p-error' : ''"
                        />
                        <small
                            v-show="submitted"
                            v-for="error in showErrors(v$.formData.rowCount)"
                            :key="error.$uid"
                            class="p-error errorPosition"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="p-col-12 p-lg-12 p-pt-0 p-px-0 p-mb-lg-0 p-d-flex p-flex-column">
                    <label class="p-text-normal" for="sort">Сортировка</label>
                    <InputNumber id="sort" v-model="formData.sort" type="text"/>
                </div>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button" type="button" @click="saveWorksheet">
                        <span class="p-button-label">{{ buttonLabel }}</span>
                    </Button>
                    <Button class="p-button p-button-outlined" @click="closeDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { WORKSHEET_DIALOG_MODES } from '@/constants/forms';
import { useVuelidate } from '@vuelidate/core';
import Worksheet from '@/models/Worksheet';
import { minLength, minValue, maxLength, required } from '@vuelidate/validators';
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'editWorksheetDialog',

    props: {
        showEditWorksheetDialog: {
            type: Boolean,
            require: true
        },

        currentWorksheet: {
            type: Object,
            require: true
        },

        currentGroup: {
            type: String
        },

        groups: {
            type: Array
        },

        currentDialogMode: {
            type: String
        }
    },

    data() {
        return {
            showDialog: this.showEditWorksheetDialog,
            formData: new Worksheet(),
            submitted: false,
            dialogMode: WORKSHEET_DIALOG_MODES.CREATE.NAME,
        };
    },

    mixins: [ requestToastHandler ],

    emits: [ 'saveWorksheet', 'closeWorksheetDialog' ],

    setup: () => ({ v$: useVuelidate() }),

    validations() {
        return {
            formData: {
                name: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    minLength: { ...minLength(3), $message: 'Значение не должно быть короче 3 символов' }
                },

                shortName: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    minLength: { ...minLength(3), $message: 'Значение не должно быть короче 3 символов' },
                    maxLength: { ...maxLength(31), $message: 'Максимальная длина - 31 символ' }
                },

                rowCount: {
                    minValue: { ...minValue(1), $message: `Значение не должно быть меньше 1` },
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                },

                columnCount: {
                    minValue: { ...minValue(1), $message: `Значение не должно быть меньше 1` },
                    required: { ...required, $message: 'Поле обязательно к заполнению' }
                }
            }
        };
    },

    methods: {
        saveWorksheet() {
            this.submitted = true;
            if (!this.v$.formData.name.$invalid && !this.v$.formData.shortName.$invalid) {
                this.showDialog = false;
                this.submitted = false;
                this.$emit('saveWorksheet', this.formData, this.dialogMode);
            }
        },

        closeDialog() {
            this.submitted = false;
            this.showDialog = false;
            this.$emit('closeWorksheetDialog');
        },

        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        }
    },

    computed: {
        dialogTitle() {
            return WORKSHEET_DIALOG_MODES[this.dialogMode].TITLE
        },

        buttonLabel() {
            return WORKSHEET_DIALOG_MODES[this.dialogMode].BUTTON
        }
    },

    watch: {
        showEditWorksheetDialog: {
            handler(to) {
                if (to) {
                    this.showDialog = this.showEditWorksheetDialog;
                    this.dialogMode = this.currentDialogMode;
                    this.formData = new Worksheet(this.currentWorksheet, this.currentGroup);
                }
            }
        }
    }
}
// 233
</script>

<style lang="scss" scoped>
.customWidth {
    width: 208px !important;
}
.p-pr8 {
    margin-right: 16px;
}

::v-deep .p-dropdown .p-inputwrapper,
    .p-dialog .p-inputtext {
    height: 34px;
    font-size: 14px;
    color: #495058;
}

::v-deep {
    .p-dialog-content {
        overflow-y: unset;
    }
    .p-dropdown-panel {
        width: 100%;
    }
}
</style>
