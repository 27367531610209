import { render, staticRenderFns } from "./columnRowBehavior.vue?vue&type=template&id=4a0f7218&scoped=true&"
import script from "./columnRowBehavior.vue?vue&type=script&lang=js&"
export * from "./columnRowBehavior.vue?vue&type=script&lang=js&"
import style0 from "./columnRowBehavior.vue?vue&type=style&index=0&id=4a0f7218&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a0f7218",
  null
  
)

export default component.exports