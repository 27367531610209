<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            position='center'
            :modal='true'
            :closeOnEscape='true'
            :closable='true'
            style='width: 500px'
            class='customDialogSettings'
            @update:visible="closeDialog"
        >
            <template #header>
                <h3 class='p-mb-0'>{{ column === -1 ? 'Поведение строки' : 'Поведение колонки' }}</h3>
            </template>
            <div class='p-col-12 p-lg-12 p-mb-lg-0 p-py-0 footerRadius p-px-0 p-py-0'>
                <div class="p-mt-2">
                    <div class="p-field-radiobutton">
                        <RadioButton id="viewAlways" name="view" value="viewAlways" v-model="archBehaviorVisible" />
                        <label for="viewAlways">Видна всегда</label>
                    </div>
                    <div class="p-field-radiobutton p-d-flex p-ai-center">
                        <RadioButton id="viewPeriod" name="view" value="visible_during_period" v-model="archBehaviorVisible" />
                        <label for="viewPeriod">Видна за период</label>
                        <Dropdown
                            v-model="period"
                            :options="periods"
                            placeholder="Выберите период"
                            option-value="code"
                            option-label="name"
                            :disabled="archBehaviorVisible !== 'visible_during_period'"
                            class="customDropDown p-column-filter"
                            filter
                            @click.stop
                        />
                    </div>
                    <div class="p-field-checkbox">
                        <Checkbox id="editOnly" v-model="editOnly" :binary="true" />
                        <label for="editOnly" class="p-my-0 p-mr-0">Редактируется сотрудником ЦМС</label>
                        <i
                            style='color: var(--primary-color); cursor:pointer;'
                            class='p-ml-1 pi pi-question-circle'
                            title='с правом formVerificationRules'
                        />
                    </div>
                    <div class="p-d-flex p-jc-start archButtons p-pl-0">
                        <Button
                            class="p-button p-mr-3"
                            type="button"
                            :disabled="archBehaviorVisible === 'visible_during_period' && !period"
                            @click="behaviorChange"
                        >
                            <span class="p-button-label">Применить</span>
                        </Button>
                        <Button class="p-button p-button-outlined" @click="closeDialog">
                            <span class="p-button-label black">Отменить</span>
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
export default {
    name: 'columnRowBehavior',

    emits: [ 'behaviorChange', 'closeBehaviorDialog' ],

    props: {
        allTemplatePeriods: {
            type: Array,
        },
        visibilityLimitedRules: {
            required: false,
            type: Array,
        },
        selectedPeriod: {
            type: String,
        },
        editableByVerifier: {
            type: Boolean,
        },
        column: {
            required: false,
            type: Number,
        },
        row: {
            required: false,
            type: Number,
        },
        showBehaviorDialog: {
            required: true,
            type: Boolean,
        }
    },

    data() {
        return {
            showDialog: this.showBehaviorDialog,
            archBehaviorVisible: this.selectedPeriod ? 'visible_during_period' : 'viewAlways',
            period: this.selectedPeriod,
            editOnly: false,
            color: null
        };
    },
    computed: {
        periods() {
            return this.allTemplatePeriods
                .map((period) => {
                    return {
                        name: period.attributes.name,
                        code: period.id,
                        activeFromTimeStamp: this.generateTimestamp(period.attributes.activeFrom)
                    };
                })
                .sort((a, b) => (a.activeFromTimeStamp < b.activeFromTimeStamp ? 1 : -1));
        }
    },
    methods: {
        behaviorChange() {
            if (this.archBehaviorVisible === 'viewAlways') {
                this.period = null;
            }
            this.showDialog = false;
            this.$emit('behaviorChange', {
                rule: this.archBehaviorVisible,
                period: this.period,
                editOnly: this.editOnly
            });
        },
        closeDialog() {
            this.showDialog = false;
            this.period = null;
            this.editOnly = false;
            this.archBehaviorVisible = 'viewAlways';
            this.$emit('closeBehaviorDialog');
        },
        generateTimestamp(dateValue) {
            let date = new Date(dateValue);
            return +date;
        },
    },

    watch: {
        showBehaviorDialog: {
            handler(to) {
                if (to) {
                    this.period = this.selectedPeriod;
                    this.editOnly = this.editableByVerifier;
                    this.archBehaviorVisible = this.selectedPeriod ? 'visible_during_period' : 'viewAlways';
                    this.showDialog = this.showBehaviorDialog;
                    this.showDialog = this.showBehaviorDialog;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .p-field-radiobutton {
    label {
        cursor: pointer;
    }
}

.archButtons {
    padding: 24px 0;
}

::v-deep .customDropDown {
    padding: 0 !important;
    margin-left: 12px;
    margin-bottom: 0 !important;
    width: 200px !important;

    .p-dropdown-items-wrapper {
        max-height: 350px !important;

        .p-dropdown-item {
            div {
                white-space: normal !important;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

::v-deep .customDialogSettings {
    width: 800px !important;

    .p-dialog-content {
        overflow-y: visible;
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;

        label {
            margin-bottom: 0;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #495057;
        }
    }

    .p-dialog-footer {
        padding: 0 14px 20px;
    }
}

::v-deep .p-colorpicker {
    margin-right: 8px;
}
</style>
