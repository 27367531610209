export const haveDifference = (array1, array2) => {
    if (array1.length !== array2.length) return true
    let difference = false
    array1.map((row, i) => {
        if (row.length !== array2[i].length) difference = true

    })
    if (difference) return difference
    for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array1[i].length; j++) {
            if (array1[i][j].layers.length !== array2[i][j].layers.length) difference = true
            // if (difference) console.log('array1[i][j].layers.length', array1[i][j].layers.length, array2[i][j].layers.length);

            // if (!difference && array1[i][j].layers[0].layersDescr !== array2[i][j].layers[0].layersDescr) difference = true
            // if (difference) console.log('array1[i][j].layers[0].layersDescr', i, j, array1[i][j].layers[0].layersDescr, array2[i][j].layers[0].layersDescr);
            if (difference) break
        }
        if (difference) break

    }

    return difference
}

export const deepArrayCopy = (array) => {

    return array.map(rowArray => {
        return rowArray.map(cell => {

            const ancell = {
                id: cell.id,
                head: false,
                value: ``,
                readOnly: false,
                className: [],
                layers: cell.layers.map(layer => JSON.parse(JSON.stringify(layer))
                ),
                // attributes: {
                //     ...cell.attributes
                // }

            }

            return ancell
        })

    })

}

export const isRightMouseClick = (event) => {
    if (event.which === 3) {
        event.preventDefault()
        event.stopImmediatePropagation()
        return true
    }
    return false
}

export const getSelectionRange = () => {
    const selection = window.getSelection(),
        range = selection.getRangeAt(0)
    return { selection, range }
}

export const getFirstLevelNodeIdx = (node, parentNode) => {
    if (parentNode.isEqualNode(node)) return null
    const childNodes = Array.from(parentNode.childNodes)
    const idx = childNodes.indexOf(node)
    if (~idx) {
        return idx;
    }
    return getFirstLevelNodeIdx(node.parentNode, parentNode)
}

export const getStartCursorPositionIntoParent = (parentNode) => {
    const { selection, range } = getSelectionRange()
    const { focusNode } = selection
    const { startOffset } = range
    const parentChildren = parentNode.childNodes
    if (focusNode.isEqualNode(parentNode)) {
        return parentChildren[startOffset] ? startOffset : startOffset - 1
    }
    return getFirstLevelNodeIdx(focusNode, parentNode)
}
