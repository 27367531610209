<template>
    <div>
        <Accordion :multiple="true" :activeIndex="active" @tab-open="toggleAccordion($event)" ref="accordion">
            <AccordionTab v-for="group in groups" :key="group.id" :id="group.id" :header="group.header" :style="{ backgroundColor: group.color }">
                <template #header>
                    <button class="p-panel-header-icon p-link" @click.stop="showGroupMenu($event, group)">
                        <span class="pi pi-ellipsis-h"></span>
                    </button>

                    <Menu
                        id="config_menu"
                        :groupId="group.id"
                        :ref="`menu${ group.id }`"
                        :appendTo="`${ group.id }`"
                        :model="createGropupMenuitems()"
                        :popup="true"
                    />
                </template>
                <draggable v-model="group.lists" group="people" @start="drag = true" @end="drag = false" @change="dragWorksheet(group.id, $event)">
                    <div v-for="(list, index) in group.lists" :key="list.id" :class="[index % 2 !== 0 ? 'greyBg' : '', 'userContainer', 'p-d-flex', 'p-jc-between', 'p-ai-center']" @click="listSelect(list.id, list.name)">
                        <span
                            :class="{
                                bold: currentWorcksheetId === list.id,
                            }"
                            >{{ list.name }}</span
                        >
                        <button class="p-panel-header-icon p-link p-mr-0" @click.stop="showListMenu($event, list.id, `listMenu${list.id}`, list, group.id)">
                            <span class="pi pi-ellipsis-h"></span>
                        </button>

                        <Menu id="list_menu" class="redLastListElement" :ref="`listMenu${list.id}`" :model="listMenuItems" :popup="true" />
                    </div>
                </draggable>
            </AccordionTab>
        </Accordion>
        <editWorksheetDialog
            :showEditWorksheetDialog="showWorksheetDialog"
            :currentWorksheet="currentWorksheet"
            :currentDialogMode="currentDialogMode"
            :currentGroup="currentGroupId"
            :groups="groups"
            @saveWorksheet="saveWorksheet"
            @closeWorksheetDialog="closeWorksheetDialog"
        />
        <Dialog :visible.sync="deleteListShow" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 400px" class="delDialog">
            <template #header>
                <h3 class="p-mb-0">Удалить лист</h3>
            </template>
            <div class="p-dialog-content p-ai-center p-d-flex p-px-0">
                <!-- <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i> -->
                <span class="contentMargin">Подтвердите, пожалуйста, что вы удаляете лист {{ currentListTitle }}. Восстановить его не получится. </span>
            </div>

            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-button-danger" @click="deleteList(true)">
                        <span class="p-button-label">Удалить</span>
                    </Button>
                    <Button class="p-button p-button-outlined p-button-secondary" type="button" @click="hideDeletePopUp">
                        <span class="p-button-label">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { required, minLength, minValue } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import draggable from 'vuedraggable';
import editWorksheetDialog from '@/components/constructor/dialogs/editWorksheetDialog';

export default {
    name: 'lists',

    props: {
        groups: {
            type: Array,
            require: true,
        },
        currentWorcksheetId: {
            type: String,
        },
        canInsert: {
            type: Boolean,
            default: false
        }
    },

    components: {
        draggable,
        editWorksheetDialog
    },

    emits: ['createWorksheet', 'editWorksheet', 'deleteList', 'dragWorksheet', 'listSelect', 'hideGroupMenu', 'saveWorksheet', 'validateLayer'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            active: 0,
            addListShow: false,
            deleteListShow: false,
            showWorksheetDialog: false,
            listTitle: '',
            listSort: null,
            columnCount: null,
            rowCount: null,
            newlistTitle: '',
            currentListTitle: '',
            currentListSort: '',
            curlistSort: 0,
            collapsed: false,
            selectedList: null,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: ($event) => {
                        $event.originalEvent.stopPropagation();
                        this.openWorksheetDialog('EDIT');
                    },
                },
                {
                    label: 'Удалить лист',
                    icon: 'pi pi-trash',
                    command: ($event) => {
                        $event.originalEvent.stopPropagation();
                        this.showDeleteWorksheetDialog();
                    },
                },
            ],
            currentGroup: null,
            currentGroupId: null,
            currentListId: null,
            currentWorksheet: null,
            currentDialogMode: 'CREATE',
            submitted: false,
        };
    },
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символов` },
        });
        return {
            listTitle: standart(3),
            newlistTitle: standart(5),
            rowCount: {
                minValue: { ...minValue(1), $message: `Значение не должно быть меньше 1` },
                required: { ...required, $message: 'Поле обязательно к заполнению' },
            },
            columnCount: { minValue: { ...minValue(1), $message: `Значение не должно быть меньше 1` }, required: { ...required, $message: 'Поле обязательно к заполнению' } },
        };
    },
    methods: {
        openNoIdGroup() {
            this.$refs.accordion.$children &&
                this.$refs.accordion.$children.map((children) => {
                    if (children.d_active === false) children.onTabClick();
                });
        },
        listSelect(listId = null, listTitle = null) {
            this.$emit('listSelect', { listId: listId || this.currentListId, listTitle: listTitle || this.currentListTitle });
            this.currentListId = null;
            this.currentListTitle = null;
        },

        dragWorksheet(groupId, { added = null, removed = null }) {
            const worksheetId = removed ? removed.element.id : added?.element.id;

            if (added && groupId !== 'noId') {
                this.$emit('dragWorksheet', { worksheetId, groupId });
            }

            if (added && groupId === 'noId') {
                this.$emit('dragWorksheet', { worksheetId });
            }
        },

        showGroupMenu(event, group) {
            this.$emit('hideGroupMenu');

            Object.keys(this.$refs).map((key) => {
                this.$refs[`${key}`][0] && this.$refs[`${key}`][0].hide(event);
            });

            this.$refs[`menu${group.id}`][0].toggle(event);
            this.currentGroup = group;
            this.currentGroupId = group.id;
        },
        showListMenu(event, listId, index, list, groupId) {
            this.$emit('hideGroupMenu');
            Object.keys(this.$refs).map((key) => {
                this.$refs[`${key}`][0] && this.$refs[`${key}`][0].hide(event);
            });
            this.$refs[index][0].toggle(event);
            this.currentWorksheet = list;
            this.currentListId = listId;
            this.currentListTitle = list.name;
            this.currentListSort = list.sort;
            this.currentGroupId = groupId;
        },
        toggleAccordion(event) {
            this.currentGroupId = event.tab.$attrs.id;
        },
        addNewList() {
            this.submitted = true;
            if (!this.v$.listTitle.$invalid && !this.v$.rowCount.$invalid && !this.v$.columnCount.$invalid) {
                this.$emit('addNewList', {
                    groupId: this.currentGroupId,
                    listTitle: this.listTitle,
                    columnCount: this.columnCount,
                    rowCount: this.rowCount,
                    sort: this.listSort
                });

                this.closeAddListInGroup();
            }
        },
        closeAddListInGroup() {
            this.submitted = false;
            this.listTitle = '';
            this.listSort = null;
            this.columnCount = null;
            this.rowCount = null;
            this.addListShow = false;
        },

        openWorksheetDialog(mode) {
            this.currentDialogMode = mode;
            if (mode === 'CREATE') {
                this.currentWorksheet = null;
            }
            if (this.canInsert) {
                this.$emit('validateLayer');
            }
            this.showWorksheetDialog = true;
        },

        saveWorksheet(worksheet, mode) {
            if (mode === 'CREATE') {
                this.$emit('createWorksheet', worksheet, true);
            } else {
                if (worksheet.groupId) {
                    this.$emit('editWorksheet', {
                        id: worksheet.id,
                        attributes: {
                            type: 'form-template-worksheet',
                            id: worksheet.id,
                            attributes: {
                                name: worksheet.name,
                                shortName: worksheet.shortName,
                                code: worksheet.code,
                                sort: worksheet.sort
                            },
                        },
                        relationships: {
                            group: {
                                data: {
                                    type: 'form-group',
                                    id: worksheet.groupId
                                }
                            }
                        }
                    });
                } else {
                    this.$emit('editWorksheet', {
                        id: worksheet.id,
                        attributes: {
                            type: 'form-template-worksheet',
                            id: worksheet.id,
                            attributes: {
                                name: worksheet.name,
                                shortName: worksheet.shortName,
                                code: worksheet.code,
                                sort: worksheet.sort
                            },
                        }
                    });
                }
            }
            this.closeWorksheetDialog();
        },
        closeWorksheetDialog() {
            this.newlistTitle = '';
            this.currentListTitle = '';
            this.curlistSort = 0;
            this.currentListSort = 0;
            this.showWorksheetDialog = false;
        },
        showDeleteWorksheetDialog() {
            this.deleteListShow = true;
        },
        hideDeletePopUp() {
            this.deleteListShow = false;
            this.currentGroupId = null;
        },
        deleteList() {
            this.$emit('deleteList', this.currentGroupId, this.currentListId);
            this.hideDeletePopUp();
        },
        save() {
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Data Saved', life: 3000 });
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        createGropupMenuitems() {
            return [
                {
                    label: 'Добавить лист',
                    icon: 'pi pi-file',
                    command: () => {
                        this.openWorksheetDialog('CREATE');
                    }
                }
            ];
        }
    },
    watch: {
        groups: {
            handler: function () {
                this.openNoIdGroup();
            },
            deep: true,
        },
    },

    mounted() {
        this.openNoIdGroup();
    },
    activated() {
        this.openNoIdGroup();
    },
};
// 307
</script>

<style lang="scss" scoped>
.bold {
    font-weight: 700 !important;
}
.overflow {
    overflow: auto;
}
.greyBg {
    background: #f5f5f5;
}

::v-deep .listBox {
    .p-listbox-list {
        padding-top: 0px;
        li {
            padding: 0;
        }
    }
}
::v-deep .redLastListElement {
    li:last-child {
        span {
            color: red !important;
        }
    }
}
::v-deep .p-dialog {
    width: 480px !important;
    .p-dialog-header {
        padding: 24px;
        .p-dialog-header-icon:focus {
            box-shadow: none;
        }
        h3 {
            font-size: 18px;
            color: #272727;
        }
    }
    .p-dialog-content {
        padding: 0 24px;

        label {
            font-size: 13px;
            line-height: 16px;
            color: #272727;
            margin-bottom: 4px;
        }
        .p-field {
            margin-bottom: 0;
        }
        .errorPos {
            position: relative;
        }
        .errorPosition {
            position: absolute;
            left: 0;
            bottom: 4px;
        }
    }

    .p-inputtext {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
    }
    .p-dialog-footer {
        padding: 0 24px 24px 24px;
        button {
            margin: 0 12px 0 0;
        }
    }
    .customWidth {
        width: 208px !important;
    }
    .p-pr8 {
        margin-right: 16px;
    }

    .maxWidth {
        max-width: 100%;
        flex-wrap: nowrap !important;
        justify-content: space-between;
    }
}
::v-deep .delDialog {
    width: 480px !important;
    box-shadow: 0px 2px 8px rgba(82, 82, 82, 0.16);
    border-radius: 2px;
    .p-dialog-content.p-ai-center.p-d-flex {
        padding-left: 0px;
        padding-right: 1rem;
    }
    .contentMargin {
        margin-bottom: 16px;
    }
}
::v-deep .p-accordion {
    .p-accordion-header .p-accordion-header-link {
        padding: 6px 35px 6px 32px;
        background: transparent;
        color: #272727;
        line-height: 125%;
        font-weight: 500;
        font-size: 13px;
        min-height: 34px;
        border-left: none;
        border-right: none;
        border-radius: 0 !important;
        .p-accordion-toggle-icon {
            // margin-right: 11px !important;
            font-size: 12px;
            position: absolute;
            left: 15px;
            top: 11px;
        }
        .p-panel-header-icon {
            position: absolute;
            right: 8px;
            top: 7px;
        }
    }
    .p-accordion-header {
        .p-panel-header-icon {
            visibility: hidden;
        }
    }
    .p-accordion-header:hover {
        .p-panel-header-icon {
            visibility: visible;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: rgba(135, 148, 163, 0.25);
        }
    }
    // .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    //     display: none;
    // }
    .p-accordion-header-text {
        width: 90%;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
    }
    .p-accordion-content {
        padding: 0;
        min-height: 30px;
        border: none;
        .userContainer {
            cursor: pointer;
        }
    }
    .p-accordion-tab-active {
        .p-accordion-header .p-accordion-header-link {
            background: transparent;
        }
    }
}
::v-deep .userContainer {
    padding: 8px 10px 8px 10px !important;
    height: 32px;
    font-size: 13px;
    line-height: 16px;

    color: #272727;
    .p-panel-header-icon {
        visibility: hidden;
        width: 24px;
        height: 24px;
    }
    span {
        max-width: 190px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.userContainer:hover {
    background: #e0e0e0;
    .p-panel-header-icon {
        visibility: visible;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: rgba(135, 148, 163, 0.25);
    }
}
::v-deep .nulableHeight {
    .p-accordion-header {
        display: none;
    }
}
</style>
