<template>
    <div class="menu p-col-3 p-px-0 p-py-0">
        <div class="p-col-12 p-px-0 p-py-0 p-d-flex p-flex-column" style="min-height: 100%">
            <div class="rightPanelMenu">
                <span class="p-buttonset">
                    <Button
                        v-for="button in allButtons"
                        :key="button.code"
                        :icon="`icon ${button.icon}`"
                        :class="[selectedMode.code === `${button.code}` ? 'active-section' : '', 'p-button', 'p-component', 'p-button-outlined', 'p-button-black', 'custom-button', 'customFontSize']"
                        @click="changeComponent(button)"
                    />
                </span>
                <span class="p-buttonset">
                    <Button icon="pi pi-ellipsis-h" v-show="this.selectedMode.code === 'lists'" @click="showGroupMenu" class="p-button-black custom-close custom-menu" />
                    <Button icon="pi pi-times" @click="hideRightMenu" class="p-button-black custom-close" />
                </span>
            </div>
            <Menu id="config_menu" ref="groupMenu" :model="groupItems" :popup="true" />

            <keep-alive>
                <component
                    :is="currentTabComponent"
                    :groups="groups"
                    :selectedForm="selectedForm"
                    :worksheetHistory="worksheetHistory"
                    :currentLayerType="currentLayerType"
                    :templateYear="templateYear"
                    :formDistributioninterval="formDistributioninterval"
                    :currentWorcksheetId="currentWorcksheetId"
                    :canInsert='canInsert'
                    :currentVersion="currentVersion"
                    @createWorksheet="createWorksheet"
                    @editWorksheet="editWorksheet"
                    @dragWorksheet="dragWorksheet"
                    @deleteList="deleteList"
                    @formulaVarintSelect="formulaVarintSelect"
                    @listSelect="listSelect"
                    @hideGroupMenu="hideGroupMenu"
                    @restore="restore"
                    @validateLayer="validateLayer"
                    @unlock-cursor='$emit("unlock-cursor", $event)'
                    @insert-cells-from-form='$emit("insert-cells-from-form", $event)'
                    @insert-get-by-tag='$emit("insert-get-by-tag", $event)'
                ></component>
            </keep-alive>
            <editWorksheetDialog
                :showEditWorksheetDialog="showWorksheetDialog"
                :currentWorksheet="currentWorksheet"
                :currentDialogMode="currentDialogMode"
                :groups="groups"
                @saveWorksheet="saveWorksheet"
                @closeWorksheetDialog="closeWorksheetDialog"
            />
        </div>
    </div>
</template>

<script>
import history from '@/components/constructor/history.vue';
import lists from '@/components/constructor/lists.vue';
import formulaBuilder from '@/components/constructor/formulaBuilder.vue';
import editWorksheetDialog from '@/components/constructor/dialogs/editWorksheetDialog';

import { required, minLength, minValue, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'rightMenu',

    components: {
        history,
        lists,
        formulaBuilder,
        editWorksheetDialog
    },

    props: {
        selectedMode: {
            require: true,
            type: Object,
        },
        groups: {
            require: true,
            type: Array,
        },
        selectedForm: {
            require: true,
            type: Object,
        },
        worksheetHistory: {
            require: true,
            type: Array,
        },
        currentLayerType: {
            type: String,
        },
        templateYear: {
            type: Number,
        },
        formDistributioninterval: {
            type: String,
        },
        currentWorcksheetId: {
            type: String,
        },
        canInsert: {
            type: Boolean,
            default: false
        },
        currentVersion: {
            type: String
        }
    },
    emits: ['hideRightMenu', 'changeComponent', 'createWorksheet', 'editWorksheet', 'dragWorksheet', 'deleteWorkSheet', 'listSelect', 'restore', 'validateLayer'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            addListShow: false,
            listTitle: '',
            listSort: null,
            columnCount: null,
            rowCount: null,
            allButtons: [
                { label: 'История', icon: 'custom-history-icon', code: 'history' },
                { label: 'Листы', icon: 'custom-list-icon', code: 'lists' },
                { label: 'Формулы', icon: 'custom-formula-icon', code: 'formulaBuilder' },
            ],
            groupItems: [
                {
                    label: 'Добавить лист',
                    icon: 'pi pi-file',
                    command: () => {
                        this.openWorksheetDialog('CREATE');
                    },
                }
            ],
            submitted: false,
            showWorksheetDialog: false,
            currentWorksheet: null,
            currentDialogMode: 'CREATE',
        };
    },
    validations() {
        const standart = (minLen = 5) => ({
            required: { ...required, $message: 'Поле обязательно к заполнению' },
            minLength: { ...minLength(minLen), $message: `Значение не должно быть короче ${minLen} символов` },
        });
        return {
            groupTitle: standart(5),
            listTitle: {
                ...standart(3),
                maxLength: {
                    ...maxLength(31),
                    $message: 'Значение не должно быть длиннее 31 символа'
                }
            },
            newlistTitle: standart(5),
            rowCount: {
                minValue: { ...minValue(1), $message: `Значение не должно быть меньше 1` },
                required: { ...required, $message: 'Поле обязательно к заполнению' },
            },
            columnCount: {
                minValue: { ...minValue(1), $message: `Значение не должно быть меньше 1` },
                required: { ...required, $message: 'Поле обязательно к заполнению' }
            },
        };
    },
    methods: {
        openWorksheetDialog(mode) {
            this.currentDialogMode = mode;
            if (mode === 'CREATE') {
                this.currentWorksheet = null;
            }
            if (this.canInsert) {
                this.$emit('validateLayer');
            }
            this.showWorksheetDialog = true;
        },
        createWorksheet(worksheet) {
            this.$emit('createWorksheet', worksheet, true);
        },
        saveWorksheet(worksheet) {
            this.$emit('createWorksheet', worksheet);
            this.closeWorksheetDialog();
        },
        closeWorksheetDialog() {
            this.listTitle = '';
            this.listSort = null;
            this.columnCount = null;
            this.rowCount = null;
            this.showWorksheetDialog = false;
        },
        hideRightMenu() {
            this.$emit('hideRightMenu');
        },
        changeComponent(button) {
            this.$emit('changeComponent', button);
        },
        formulaVarintSelect(formulaVarint) {
            this.$emit('formulaVarintSelect', formulaVarint);
        },
        editWorkSheetGroup(workSheetGroup) {
            this.$emit('editWorkSheetGroup', workSheetGroup);
        },
        restore(data) {
            this.$emit('restore', data);
        },
        validateLayer() {
            this.$emit('validateLayer');
        },
        showGroupMenu(event) {
            this.$refs.groupMenu.toggle(event);
        },
        hideGroupMenu() {
            this.$refs.groupMenu.hide();
        },
        editWorksheet(worksheetData) {
            this.$emit('editWorksheet', worksheetData);
        },
        dragWorksheet({ worksheetId, groupId }) {
            this.$emit('dragWorksheet', { worksheetId, groupId });
        },
        deleteList(groupId, listId) {
            // let curentGroupIndex = this.groups.findIndex((group) => group.id == currentGroupId);
            // let newListsInGroup = this.groups[curentGroupIndex].lists.filter((list) => list.listId !== listId);
            // this.groups[curentGroupIndex].lists = newListsInGroup;
            // this.$set(this.groups[curentGroupIndex].lists, 'lists', newListsInGroup);
            this.$emit('deleteWorkSheet', listId);
        },

        listRename(currentGroupId, listId, name) {
            let curentGroupIndex = this.groups.findIndex((group) => group.id == currentGroupId);

            this.groups[curentGroupIndex].lists = this.groups[curentGroupIndex].lists.map((list) => {
                if (list.listId === listId) {
                    return {
                        ...list,
                        name,
                    };
                }
                return list;
            });
        },
        listSelect(data) {
            this.$emit('listSelect', data);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        }
    },

    computed: {
        currentTabComponent() {
            return this.selectedMode.code;
        },
    }
};
// 246
</script>

<style lang="scss" scoped>
.menu {
    width: 255px;
    background: #ffffff;
    color: black;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    animation: all 1s;
    max-height: 100vh;
    overflow-y: auto;
}

.rightPanelMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 38px;
    background: #f5f5f5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 100;
}
::v-deep .p-buttonset {
    display: flex;
    align-items: center;
    .pi-replay:before,
    .pi-list:before,
    .pi-pencil:before {
        font-size: 18px;
        color: #495058;
    }
    .p-button.p-button-outlined:enabled:hover {
        background: #e0e0e0 !important;
        color: #272727 !important;
        border: 0 !important;
    }
}

.p-button-black {
    color: black;
}
.p-buttonset .custom-button {
    border-radius: 0;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid #c4c4c4;
    width: 38px;
    height: 38px;
}

.custom-close {
    border-radius: 0;
    background-color: transparent;
    border: none;
    width: 38px;
    height: 38px;
}
.custom-close:hover {
    background: #e0e0e0;
    color: #495058;
}
.custom-menu {
    color: black;
    width: 24px;
    height: 24px;
    border-radius: 50% !important;
    border-color: #328036;
}
.custom-menu:hover {
    border-radius: 50% !important;
    background: rgba(135, 148, 163, 0.25);
}
.active-section {
    background: #e0e0e0;
}
.country-item {
    display: flex;
    img {
        width: 17px;
        margin-right: 0.5rem;
    }
}
::v-deep .p-panel {
    .p-panel-header {
        padding: 0.5rem 0 0.5rem 0.5rem;
        .p-panel-title {
            font-weight: 500;
        }
    }

    .p-panel-content {
        padding: 0;
    }
    .p-panel-icons {
        min-width: 60px;
    }
    .p-link {
        margin-right: 0 !important;
        border-radius: 26px;
        .p-panel-header-icon {
            .pi {
                font-size: 1.3rem !important;
            }
        }
    }
    .userContainer:hover {
        .p-link {
            background: rgba(73, 80, 88, 0.25);
            padding: 2px;
        }
    }
    .p-link:hover {
        background: rgba(73, 80, 88, 0.25);
    }
    .p-panel .p-panel-header .p-panel-header-icon:focus {
        box-shadow: none;
    }
    .p-panel-header-icon {
        box-shadow: none;
    }
    .p-panel-header-icon:hover {
        border-radius: 50%;
        background: rgba(73, 80, 88, 0.25);
    }
}

::v-deep .p-dialog {
    width: 480px !important;

    .p-dialog-header {
        padding: 24px;
        .p-dialog-header-icon:focus {
            box-shadow: none;
        }
        h3 {
            font-size: 18px;
            color: #272727;
        }
    }
    .p-dialog-content {
        padding: 0 24px;
        label {
            font-size: 13px;
            line-height: 16px;
            color: #272727;
            margin-bottom: 4px;
        }
        .p-field {
            margin-bottom: 0;
        }
        .errorPos {
            position: relative;
        }
        .errorPosition {
            position: absolute;
            left: 0;
            bottom: 4px;
        }
    }

    .p-inputtext {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 16px;
    }
    .p-dialog-footer {
        padding: 0 24px 24px 24px;
        button {
            margin: 0 12px 0 0;
        }
    }
    .p-pr8 {
        padding-right: 8px;
    }
    .p-pl8 {
        padding-left: 8px;
    }
}
::v-deep .customFontSize {
    .custom-history-icon:before {
        font-size: 19px !important;
    }
    .custom-list-icon:before {
        font-size: 17px !important;
    }
    .custom-formula-icon:before {
        font-size: 18px !important;
    }
}
::v-deep .p-menu-list {
    .p-menuitem-icon {
        font-size: 16px;
    }
}
.customWidth {
    width: 208px !important;
}
.p-pr8 {
    margin-right: 16px;
}
</style>
